import React, { useState } from "react";
import { Row, Col, Checkbox, Form, Input, Divider, Spin } from "antd";
import "./index.scss";
import { Navigate } from "react-router-dom";
import { login } from "../../../redux/actions/authentication";
import { useDispatch, useSelector } from "react-redux";
import GoogleLogin from "../../../components/SocialAuthentication/Google";
import GithubLogin from "../../../components/SocialAuthentication/Github";
import logo from "../../../assets/logo.png";
import { ValidateEmail } from "../../../shared/functions";
import CustomButton from "../../../shared/Components/Button";

function Login() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  // const [response, setResponse] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    // setResponse("");
    setIsSuccess(false);
    if (email !== "" && password !== "") {
      await dispatch(login(email, password))
        .then((res) => {
          // setResponse(res.data);
          setIsSuccess(true);
        })
        .catch((error) => {
          setLoading(false);
          setIsSuccess(false);
          // setResponse(error);
        });
    } else {
      setLoading(false);
      // setResponse("");
    }
  };

  const notActionable = email === "" || password === "";

  return (
    <div className="area">
      <ul className="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <Row
        className="login-container"
        // style={{ width: "100%", height: "100vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        // justify="space-around"
        // align="middle"
      >
        <Col
          span={24}
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            textAlign: "center",
            marginTop: "20vh",
          }}
        >
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
          >
            <div className="flex justify-center">
              <img alt="" className="logo" src={logo} />
            </div>
            <Form.Item
              name="email"
              hasFeedback
              validateStatus={
                !ValidateEmail(email) && email !== "" ? "error" : ""
              }
              help={
                !ValidateEmail(email) && email !== ""
                  ? "Please provide a valid email"
                  : ""
              }
              rules={[
                {
                  required: true,
                  message: "Please input your mail!",
                },
              ]}
            >
              <Input
                placeholder="Email"
                size="large"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input
                type="password"
                placeholder="Password"
                size="large"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <a
                className="login-form-forgot"
                href="/forgot-password"
                style={{ color: "#5a03d5" }}
              >
                Forgot password?
              </a>
              <Form.Item
                name="remember"
                valuePropName="checked"
                style={{ color: "#5a03d5", marginLeft: "-10vw" }}
              >
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
            </Form.Item>

            <Form.Item
              style={{ textAlign: "center", marginBottom: 10 }}
              className="checkbox"
            >
              <CustomButton
                isPrimary
                buttonText={loading ? <Spin /> : "Login"}
                onClick={handleLogin}
                width={"28vw"}
                disabled={notActionable}
                height={"35px"}
              />
              {/* <button
                onClick={handleLogin}
                disabled={notActionable}
                htmlType="submit"
                className={
                  notActionable
                    ? "login-form-button btn-disabled"
                    : "login-form-button"
                }
              >
                {loading ? <Spin /> : "Login"}
              </button> */}
              <div>
                <span style={{ color: "#5a03d5", fontSize: 16 }}>
                  Don't have an account?
                  <a
                    style={{ fontSize: 16, marginLeft: 5, color: "#5a03d5" }}
                    href="/register"
                  >
                    Register here.
                  </a>
                </span>
              </div>
            </Form.Item>
            {/* <Divider style={{ margin: '10px 0px' }} /> */}
            <Divider>
              <span className="loginTitle">Login With</span>
            </Divider>
            <Row>
              <Col md={11}>
                <GoogleLogin btnText={"Login with Google"} />
              </Col>
              <Col md={2} />
              <Col md={11}>
                <GithubLogin btnText={"Login with Github"} />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
