import { useSelector } from "react-redux";
import "./index.scss";
import styles from "./styles.module.scss";
import CommentBox from "../../CanvasLayout/RightSider/CommentList/CommentBox";
import { useState } from "react";
import SingleComment from "../SingleComment";

const CommentNode = (props) => {
  let { data, activeComment } = props;
  const { openComment } = useSelector((state) => state?.comment);

  return (
    <div
      className={
        "alpha" +
        (activeComment ? " activeComment" : "") +
        (openComment?.comment_id === data?.comment_id ? " open" : "")
      }
    >
      <SingleComment comment={data} />
    </div>
  );
};

export default CommentNode;
