import {
  ADD_SHAPES_TO_CHART_SUCCESS,
  ADD_UPDATE_CHART_ACCESS_FAILURE,
  ADD_UPDATE_CHART_ACCESS_SUCCESS,
  ADD_UPDATE_CHART_FAILURE,
  ADD_UPDATE_CHART_SUCCESS,
  GET_ALL_SHAPES_FAILURE,
  GET_ALL_SHAPES_SUCCESS,
  GET_CHART_DETAILS_FAILURE,
  GET_CHART_DETAILS_SUCCESS,
  REDO_CLEAR,
  REDO_SUCCESS,
  RESET_CHART_UPDATED,
  SET_ACTIVE_SHAPES_SUCCESS,
  SET_REDO_ITEMS,
  SET_SAVING,
  SET_UNDO_ITEMS,
  TOGGLE_SHAPE_POPUP,
  UNDO_CLEAR,
  UNDO_SUCCESS,
} from "../constants/flowchart";
import {
  GET_CHART_ACCESS_FAILURE,
  GET_CHART_ACCESS_SUCCESS,
} from "../constants/workflow";
import flowchartService from "../services/flowchart";
import { logout } from "./authentication";

export const toggleShapePopup = (shape) => (dispatch) => {
  let payload = {
    popupShown: shape,
  };

  return dispatch({
    type: TOGGLE_SHAPE_POPUP,
    payload,
  });
};

export const addUndoItems = (payload) => (dispatch) => {
  return dispatch({
    type: SET_UNDO_ITEMS,
    payload,
  });
};

export const addRedoItems = (payload) => (dispatch) => {
  return dispatch({
    type: SET_REDO_ITEMS,
    payload,
  });
};

export const undoItemsDone = () => (dispatch) => {
  return dispatch({
    type: UNDO_SUCCESS,
  });
};

export const redoItemsDone = () => (dispatch) => {
  return dispatch({
    type: REDO_SUCCESS,
  });
};

export const clearUndo = () => (dispatch) => {
  return dispatch({
    type: UNDO_CLEAR,
  });
};

export const clearRedo = () => (dispatch) => {
  return dispatch({
    type: REDO_CLEAR,
  });
};

export const getShapes = () => async (dispatch) => {
  try {
    let data = await flowchartService.getAllShapes();
    return dispatch({
      type: GET_ALL_SHAPES_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return dispatch(logout());
    }
    dispatch({
      type: GET_ALL_SHAPES_FAILURE,
    });
    return Promise.reject();
  }
};

export const resetChartUpdated = () => ({
  type: RESET_CHART_UPDATED,
});

export const addUpdateChart = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SAVING,
      payload: true,
    });
    let payload = await flowchartService.addUpdateChart(data);
    // console.log("services chart response: ", payload);
    return dispatch({
      type: ADD_UPDATE_CHART_SUCCESS,
      payload,
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return dispatch(logout());
    }
    return dispatch({
      type: ADD_UPDATE_CHART_FAILURE,
      payload: error,
    });
  }
};

export const updateChartImage = (chartId, image) => async () => {
  return await flowchartService.updateChartImage(chartId, image);
};

export const getChartDetails = (id, pageNum) => async (dispatch) => {
  dispatch({
    type: GET_CHART_DETAILS_SUCCESS,
    payload: null,
  });

  try {
    let payload = await flowchartService.getChartDetails(id, pageNum);
    return dispatch({
      type: GET_CHART_DETAILS_SUCCESS,
      payload,
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return dispatch(logout());
    }
    dispatch({
      type: GET_CHART_DETAILS_FAILURE,
    });
    return Promise.reject();
  }
};

export const setActiveShape = (shape) => (dispatch) => {
  let payload = {
    activeShape: shape,
  };
  return dispatch({
    type: SET_ACTIVE_SHAPES_SUCCESS,
    payload,
  });
};

export const addShapeToChart = (payload) => (dispatch) => {
  return dispatch({
    type: ADD_SHAPES_TO_CHART_SUCCESS,
    payload,
  });
};

export const addUpdateChartAccess = (data) => async (dispatch) => {
  try {
    let payload = await flowchartService.addUpdateChartAccess(data);
    return dispatch({
      type: ADD_UPDATE_CHART_ACCESS_SUCCESS,
      payload,
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return dispatch(logout());
    }
    return dispatch({
      type: ADD_UPDATE_CHART_ACCESS_FAILURE,
      payload: error,
    });
  }
};

export const getChartAccess = (chartId) => async (dispatch) => {
  try {
    let payload = await flowchartService.getChartAccess(chartId);
    return dispatch({
      type: GET_CHART_ACCESS_SUCCESS,
      payload,
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return dispatch(logout());
    }
    return dispatch({
      type: GET_CHART_ACCESS_FAILURE,
      payload: error,
    });
  }
};
