import React, { useState } from 'react';
import DLayout from '../../../components/Dashboard/Layout';
import './index.scss';

function Upgrade() {
  const [activePage, setActivePage] = useState("DASHBOARD");
  return (
    <DLayout activePage={activePage} setActivePage={setActivePage}>
      <div>Upgrade empty screen</div>
    </DLayout>
  )
}

export default Upgrade