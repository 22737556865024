import React, { useState } from "react";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import { Row, Col, Card, Badge } from "antd";
import { PlusOutlined, ClusterOutlined } from "@ant-design/icons";
import { ImStack } from "react-icons/im";
import { Link } from "react-router-dom";
import NewBoardPopup from "../../../containers/Dashboard/Flowcharts/NewBoardPopup";
import Header from "./Header";

const items1 = ["1", "2", "3"].map((key) => ({
  key,
  label: `nav ${key}`,
}));

function DLayout({
  children,
  onSearch,
  searchData,
  activePage,
  setActivePage,
  hasSearch = true,
}) {
  const [showNewPopup, setShowNewPopup] = useState(false);
  return (
    <div
      className="flex flex-col"
      style={{
        height: "100vh",
      }}
    >
      <Header
        items1={items1}
        onSearch={onSearch}
        searchData={searchData}
        hasSearch={hasSearch}
      />
      <main className="grow h-full">
        <div className="flex h-full">
          <Sidebar
            colorBgContainer={"#fff"}
            activePage={activePage}
            setActivePage={setActivePage}
          />
          <div className="grow flex flex-col h-full">
            <Row gutter={16}>
              <Col span={24}>
                <div className="optionsCard">
                  <div className="card-group">
                    <Card
                      hoverable
                      className="new-container"
                      onClick={() => setShowNewPopup(true)}
                    >
                      <Row>
                        <Col span={8}></Col>
                        <Col
                          span={8}
                          className="plusIcon flex justify-center align-middle"
                        >
                          <PlusOutlined style={{ fontWeight: "bold" }} />
                        </Col>
                        <Col span={8}></Col>
                        <Col span={24} className="card-text">
                          New Blank Project
                        </Col>
                      </Row>
                    </Card>

                    <Badge.Ribbon
                      text="Beta"
                      color="purple"
                      style={{ marginTop: "-12px" }}
                    >
                      <Link to={"/autodesigner"}>
                        <Card hoverable className="autodesigner-container">
                          <Row>
                            <Col span={8}></Col>
                            <Col
                              span={8}
                              className="cardIcon flex justify-center align-middle"
                            >
                              <ClusterOutlined />
                            </Col>
                            <Col span={8}></Col>
                            <Col span={24} className="card-text">
                              Generate with Autodesigner
                            </Col>
                          </Row>
                        </Card>
                      </Link>
                    </Badge.Ribbon>

                    <Link to={"/templates"}>
                      <Card hoverable className="template-container">
                        <Row>
                          <Col span={8}></Col>
                          <Col
                            span={8}
                            className="cardIcon flex justify-center align-middle"
                          >
                            <ImStack />
                          </Col>
                          <Col span={8}></Col>
                          <Col span={24} className="card-text">
                            Start from template
                          </Col>
                        </Row>
                      </Card>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="grow h-full" style={{ overflow: "auto" }}>
              {children}
            </div>

            {showNewPopup ? (
              <NewBoardPopup closePopup={() => setShowNewPopup(false)} />
            ) : null}
            {/* <Footer /> */}
          </div>
        </div>
      </main>
    </div>
  );
}

export default DLayout;
