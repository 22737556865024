import ApiExecute from ".";

const addUpdateComment = async (data) => {
  return await ApiExecute("add-update-comment", "POST", { data });
};

const getComments = async (pageId) => {
  return await ApiExecute(`get-comments/${pageId}`);
};

const commentService = {
  addUpdateComment,
  getComments,
};

export default commentService;
