import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";
import notificationServices from "../../redux/services/notification";
import { Dropdown, Space } from "antd";
import { FaRegBell } from "react-icons/fa";
import style from "./style.module.scss";
import { Link } from "react-router-dom";

const Notification = () => {
  const [notifications, setNotification] = useState([]);
  const [menu, setMenu] = useState([
    {
      key: 1,
      label: () => <div>Loading</div>,
    },
  ]);

  useEffect(() => {
    async function fetchNotifications() {
      try {
        let response = await notificationServices.getNotifications();
        setNotification(response);
      } catch (error) {}
    }

    let socket = io();

    socket.on("notifications", function (rows) {
      setNotification(rows);
    });

    fetchNotifications();

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    let menuArr = [],
      key;
    for (const noti of notifications) {
      key++;
      menuArr.push({
        key,
        label: (
          <Link
            to={"/canvas/" + noti.chart_id}
            className={`${style.notification} ${
              noti.is_opened ? "" : style.highlight
            }`}
          >
            <div>
              <img src={noti?.chart_image} alt="" />
            </div>
            <div>
              <div>
                <strong>{noti.notification_header}</strong>
              </div>
              <div>{noti.notification_body}</div>
            </div>
          </Link>
        ),
      });
    }
    setMenu(menuArr);
  }, [notifications]);

  return (
    <Dropdown menu={{ items: menu }}>
      <div className={style.notiIcon}>
        <Space>
          <FaRegBell />
        </Space>
      </div>
    </Dropdown>
  );
};

export default Notification;
