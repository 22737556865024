import ApiExecute from ".";

const getCharts = async () => {
  return await ApiExecute("get-charts");
};

const getTemplates = async () => {
  return await ApiExecute("get-templates/?product=FC");
};

const starUnstarChart = async (data) => {
  return await ApiExecute("star-unstar-chart", "POST", { data });
};

const dashboardService = {
  getCharts,
  getTemplates,
  starUnstarChart,
};

export default dashboardService;
