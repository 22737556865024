import { PROMPT_POPUP_OPEN } from "../constants/flowchart";
import {
  ADD_UPDATE_WORKFLOWS_FAILURE,
  ADD_UPDATE_WORKFLOWS_SUCCESS,
  GET_WORKFLOWS_DETAILS_FAILURE,
  GET_WORKFLOWS_DETAILS_SUCCESS,
  REMOVE_LAST_ACTIVE_NODE,
  SET_ACTIVE_FLOW_SUCCESS,
  SET_ACTIVE_NODES,
  // SET_ACTIVE_NODES_SUCCESS,
  SET_CURRENT_WORKFLOW,
  SET_LANG_CONVERT,
  SET_SPEECH,
  SET_SPEECH_OPTIONS,
} from "../constants/workflow";

const initialState = {
  activeNodes: [],
  activeFlow: false,
  workflows: [],
  updatedWorkflowData: null,
  promptOpen: false,
  speech: null,
  currentWorkflow: null,
  speechOptions: {
    volume: 0.7,
    lang: "en-US",
    tolang: "en",
    rate: 1,
    pitch: 1,
    voice: "Google UK English Female",
  },
  langConvert: {
    from: { code: "en", name: "English", codeWithCountry: "en" },
    to: { code: "en", name: "English", codeWithCountry: "en" },
  },
};

const WorkflowReducers = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_ACTIVE_FLOW_SUCCESS:
      console.log("active flow payload: ", payload);
      return {
        ...state,
        activeFlow: payload,
      };

    // case SET_ACTIVE_NODES_SUCCESS:
    //   return {
    //     ...state,
    //     activeNodes: payload,
    //   };

    case GET_WORKFLOWS_DETAILS_SUCCESS:
      return {
        ...state,
        workflows: payload,
      };

    case GET_WORKFLOWS_DETAILS_FAILURE:
      return {
        ...state,
      };

    case ADD_UPDATE_WORKFLOWS_SUCCESS:
      return {
        ...state,
        updatedWorkflowData: payload,
      };

    case ADD_UPDATE_WORKFLOWS_FAILURE:
      return {
        ...state,
        updatedWorkflowData: payload,
      };

    case PROMPT_POPUP_OPEN:
      return {
        ...state,
        promptOpen: payload,
      };

    case SET_SPEECH:
      return {
        ...state,
        speech: payload,
      };

    case SET_CURRENT_WORKFLOW:
      return {
        ...state,
        currentWorkflow: payload,
      };

    case SET_SPEECH_OPTIONS:
      return {
        ...state,
        speechOptions: {
          ...state.speechOptions,
          [payload.key]: payload.data,
        },
      };

    case SET_LANG_CONVERT:
      return {
        ...state,
        langConvert: {
          ...state.langConvert,
          [payload.key]: payload.data,
        },
      };

    case SET_ACTIVE_NODES:
      return {
        ...state,
        activeNodes: payload === 0 ? [] : [...state.activeNodes, payload],
      };

    case REMOVE_LAST_ACTIVE_NODE:
      let removedArr = state?.activeNodes?.slice(0, -1);
      console.log("removed arr:", removedArr);
      return {
        ...state,
        activeNodes: removedArr,
      };

    default:
      return state;
  }
};

export default WorkflowReducers;
