import "./index.scss";
import { memo, useEffect, useState } from "react";
import {
  BsFillPlayFill,
  BsPauseFill,
  BsSkipBackwardFill,
  BsSkipForwardFill,
} from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { languages } from "../languages";
import {
  removeLastActiveNode,
  setLangConvert,
} from "../../../../redux/actions/workflow";
import { useReactFlow } from "reactflow";

const WorkflowAction = ({
  duration,
  playing,
  setPlaying,
  spent,
  playAudioList,
}) => {
  const dispatch = useDispatch();
  // const [spent, setSpent] = useState(0);
  const ReactFlow = useReactFlow();

  const nodes = ReactFlow.getNodes();

  const activeNodes = useSelector((state) => state?.workflow?.activeNodes);
  const speechOptions = useSelector((state) => state?.workflow?.speechOptions);
  const Speech = useSelector((state) => state?.workflow?.speech);
  const langConvert = useSelector((state) => state?.workflow?.langConvert);

  const [volume, setVolume] = useState(speechOptions?.volume);
  const [activeNode, setActiveNode] = useState(null);
  const currentWorkflow = useSelector(
    (state) => state.workflow?.currentWorkflow
  );

  useEffect(() => {
    if (activeNodes.length) {
      let Node = nodes.filter(
        (node) => node.id === activeNodes[activeNodes.length - 1]
      );
      // console.log("active node:", Node);
      setActiveNode(Node[0]);
    }
  }, [activeNodes, nodes]);

  useEffect(() => {
    if (Speech) Speech?.setVolume(volume);
  }, [volume, Speech]);

  const togglePlaying = () => {
    setPlaying(!playing);
  };

  const handleBackward = () => {
    setPlaying(true);
    // console.log("active nodes for back: ", activeNodes);
    playAudioList([...activeNodes].length - 2, false);
    dispatch(removeLastActiveNode());
  };

  const handleForward = () => {
    setPlaying(true);
    playAudioList([...activeNodes].length);
  };

  return (
    <div className="workflow-action">
      <div>
        {spent} / {duration} seconds
      </div>
      {/* <div>Speaking: {playing?.toString()}</div> */}
      <div className="progress-bar">
        <div
          className="progress"
          style={{ width: `${(spent / duration) * 100}%` }}
        ></div>
      </div>
      <div className="d-flex">
        {/* <Button
          onClick={() => {
            if (Speech?.speaking()) {
              setPlayLabel("Pause");
              Speech.pause();
            } else {
              setPlayLabel("Play");
              Speech.resume();
            }
          }}
        >
          {playLabel}
        </Button> */}
        <button
          type="button"
          className="play-btn"
          disabled={activeNodes.length < 2}
          onClick={activeNodes.length > 1 ? handleBackward : null}
        >
          <BsSkipBackwardFill />
        </button>
        <button className="play-btn" onClick={togglePlaying}>
          {playing ? (
            <BsPauseFill className="play-icon" size={24} />
          ) : (
            <BsFillPlayFill className="play-icon" size={24} />
          )}
        </button>
        <button
          type="button"
          className="play-btn"
          disabled={activeNodes.length === currentWorkflow.workflow.length}
          onClick={
            activeNodes.length < currentWorkflow.workflow.length
              ? handleForward
              : null
          }
        >
          <BsSkipForwardFill />
        </button>
        <div style={{ marginLeft: "auto" }}>
          <input
            type="range"
            step={0.1}
            min={0}
            max={1}
            value={volume}
            onChange={(e) => {
              setVolume(e.target.value);
            }}
          />
        </div>
        <select>
          <option value="Google UK English Female">Female</option>
          <option value="Google UK English Male">Male</option>
        </select>
        {/* <select
          value={langConvert.from}
          onChange={(e) =>
            dispatch(
              setLangConvert({
                key: "from",
                data: e.target.value,
              })
            )
          }
        >
          {languages.map((opt, index) => (
            <option key={index} value={JSON.stringify(opt)}>
              {opt.name}
            </option>
          ))}
        </select> */}
        <select
          value={JSON.stringify(langConvert.to)}
          onChange={(e) =>
            // setLangConvert((lang) => ({ ...lang, to: e.target.value }))
            dispatch(
              setLangConvert({
                key: "to",
                data: JSON.parse(e.target.value),
              })
            )
          }
        >
          {languages.map((opt, index) => (
            <option key={index} value={JSON.stringify(opt)}>
              {opt.name}
            </option>
          ))}
        </select>
      </div>

      {activeNode ? (
        <div style={{ marginTop: 20, marginLeft: 10 }}>
          <h4>{activeNode?.data?.label}</h4>
          <p
            dangerouslySetInnerHTML={{ __html: activeNode?.data?.description }}
          ></p>
        </div>
      ) : null}
    </div>
  );
};

export default memo(WorkflowAction);
