import React, { useEffect, useState } from "react";
import { Row, Col, Collapse } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getShapes } from "../../../../redux/actions/flowchart";
import "./index.scss";
import { getCharts } from "../../../../redux/actions/dashboard";
import { BiPlusMedical } from "react-icons/bi";
import {
  setArrowType,
  setBgColor,
  setBorderColor,
  setBorderWidth,
  setBorderstyle,
} from "../../../../redux/actions/node";
import UploadNewShape from "./UploadNewShape";
const { Panel } = Collapse;

function LeftSidebar() {
  const dispatch = useDispatch();
  let shapes = useSelector((state) => state?.flowchart?.shapes?.data);
  const charts = useSelector((state) => state?.chart?.charts?.data);

  const [templates, setTemplates] = useState([]);
  const [popoverVisible, setPopoverVisible] = useState(true);
  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });
  const [popoverData, setPopoverData] = useState(null);
  const [popType, setPopType] = useState("");

  const [uploadShape, setUploadShape] = useState(false);

  useEffect(() => {
    if (!shapes) {
      dispatch(getShapes());
      dispatch(getCharts());
    }
  }, [shapes, charts, dispatch]);

  useEffect(() => {
    if (charts) {
      if (charts?.length > 0) {
        let templateData = charts?.filter((_) => _?.is_template);
        setTemplates(templateData);
      }
    }
  }, [charts]);

  const onDragStart = (event, shape, type = "shape") => {
    dispatch(setBgColor(null));
    dispatch(setBorderColor(null));
    dispatch(setBorderstyle(null));
    dispatch(setBorderWidth(null));
    dispatch(setArrowType(null));

    event.dataTransfer.setData(
      "application/reactflow",
      JSON.stringify({ type, shape })
    );
    event.dataTransfer.effectAllowed = "move";
  };

  const showPopover = (event, shape, type) => {
    const top = event.clientY + window.scrollY - 200;
    const left = event.clientX + 10;
    setPopoverPosition({ top, left });
    setPopoverData(shape);
    setPopoverVisible(true);
    setPopType(type);
  };

  const hidePopover = () => {
    setPopoverVisible(false);
  };

  const content = (data) => {
    return (
      <div
        key={data?.shape_id}
        className="w-auto h-auto  max-w-xs flex flex-col justify-end place-content-center"
      >
        {popType === "shapes" ? (
          <>
            <div>
              <img
                src={data?.shape_src}
                alt={data?.shape_name || "Shape"}
                className="px-3 py-3"
                style={{
                  minWidth: 130,
                  maxWidth: 130,
                  minHeight: 130,
                  maxHeight: 130,
                }}
              />
            </div>
            <div className="border-t border-gray-500" />
            <div className="text-center py-2 px-3">
              <span className="font-bold text-gray-500 text-md">
                {data?.shape_name}
              </span>
            </div>
          </>
        ) : (
          <>
            <img
              src={data?.image}
              alt={data?.shape_name || "Shape"}
              style={{
                minWidth: 300,
                maxWidth: 300,
                minHeight: 150,
                maxHeight: 150,
              }}
            />
            <div className="border-t border-gray-500" />
            <div className="text-center py-2 px-3">
              <span className="font-bold text-gray-500 text-md">
                {data?.chart_name ?? "Blank Diagram"}
              </span>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div
      className="h-full canvasLeftSidebar"
      style={{ position: "relative", display: "flex", flexDirection: "column" }}
    >
      <div
        style={{
          width: "16rem",
          flexGrow: 1,
          overflow: "auto",
          height: "100%",
          minHeight: 0,
          backgroundColor: "#d9d9d9",
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Collapse
          defaultActiveKey={[
            "Flow Chart",
            "Standard",
            "sg",
            "My shapes and images",
          ]}
          ghost
        >
          {shapes &&
            Object.keys(shapes)?.map((heading) => (
              <Panel
                header={heading}
                className={`m-3 ${heading.replace(" ", "")}`}
                key={heading}
                style={{ backgroundColor: "#fff", borderRadius: "12px" }}
              >
                <div className="shape-row relative">
                  {shapes[heading]?.map((shape, idx) => {
                    return (
                      <div className="shape-col" key={idx}>
                        <img
                          className="sidebarImg"
                          src={shape?.shape_src}
                          draggable
                          onDragStart={(e) => onDragStart(e, shape)}
                          alt={shape?.shape_name || "Shape"}
                          onMouseEnter={(e) => showPopover(e, shape, "shapes")}
                          onMouseLeave={hidePopover}
                        />
                      </div>
                    );
                  })}
                </div>
              </Panel>
            ))}
          <Panel
            header="Shape Groups"
            key="sg"
            className="m-3 ShapeGroups"
            style={{ backgroundColor: "#fff", borderRadius: "12px" }}
          >
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
              }}
              style={{
                minHeight: "20vh",
                maxHeight: "20vh",
                overflow: "hidden",
                overflowY: "scroll",
              }}
            >
              {templates &&
                templates?.length > 0 &&
                templates?.map((data, index) => {
                  return (
                    <Col span={11} key={index}>
                      <div className="templateContainer">
                        <span
                          draggable
                          onDragStart={(e) => onDragStart(e, data, "template")}
                          onMouseEnter={(e) =>
                            showPopover(e, data, "shapeGroup")
                          }
                          onMouseLeave={hidePopover}
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            minHeight: 90,
                          }}
                        >
                          <img
                            src={data?.image}
                            alt={data?.shape_name || data?.chart_name}
                            style={{
                              width: "100%",
                              height: 100,
                              objectFit: "contain",
                            }}
                          />
                        </span>
                      </div>
                    </Col>
                  );
                })}
            </Row>
          </Panel>
        </Collapse>
      </div>
      <div
        style={{
          padding: "10px 0",
        }}
      >
        <button
          className="shape-button btn xl:mb-0 rounded-md px-10 h-10 mx-3  addMoreShapes"
          style={{
            display: "flex",
            gap: 10,
          }}
          onClick={() => setUploadShape(true)}
        >
          <div>
            <BiPlusMedical />
          </div>
          Upload Shapes
        </button>
      </div>
      <div
        className="shape-popover bg-white z-999 shadow-md rounded-md"
        style={{
          top: `${popoverPosition.top}px`,
          left: `15rem`,
          display: popoverVisible ? "block" : "none",
          position: "absolute",
        }}
      >
        {popoverData ? content(popoverData) : null}
      </div>
      {uploadShape ? <UploadNewShape setUploadShape={setUploadShape} /> : null}
    </div>
  );
}

export default LeftSidebar;
