import React, { useState } from "react";
import "./index.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { AiFillHome } from "react-icons/ai";
// import { CgTemplate } from "react-icons/cg";
// import { ClusterOutlined } from "@ant-design/icons";
import { Links } from "./links";
import { FaAngleRight } from "react-icons/fa";

function Sidebar() {
  const location = useLocation();
  // const [navToggle, setNavToggle] = useState(null);

  // const handleNavDropdown = (event, key) => {
  //   event.preventDefault();

  //   setNavToggle((exists) => (exists !== key ? key : null));
  // };

  const isActive = (nav) => {
    if (
      decodeURI(location.pathname) === decodeURI(nav.target) ||
      nav?.childrens?.filter(
        (nChild) => decodeURI(nChild.target) === decodeURI(location.pathname)
      ).length
    ) {
      return true;
    }

    return false;
  };

  return (
    <div className="left-sidebar">
      <div
        className="h-full grow"
        style={{
          overflow: "auto",
        }}
      >
        <ul className="sidebarContainer">
          {Links?.map((item, key) => (
            <li key={key} className={isActive(item) ? "active" : ""}>
              <Link to={item?.target}>
                {item?.icon}
                <span>{item?.label}</span>
                {item?.childrens?.length ? (
                  <FaAngleRight
                    className="nav-arrow"
                    style={{
                      marginLeft: "auto",
                    }}
                  />
                ) : null}
              </Link>

              {item?.childrens?.length && isActive(item) ? (
                <ul>
                  {item?.childrens?.map((item2, key2) => (
                    <li key={key2} className={isActive(item2) ? "active" : ""}>
                      <Link
                        to={item2?.target ?? "#"}
                        onClick={(e) =>
                          !item2?.target ? e.preventDefault() : true
                        }
                      >
                        {item2?.icon}
                        <span>{item2?.label}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              ) : null}
            </li>
          ))}
        </ul>
      </div>
      <div className="upgradeCard-container">
        <div className="centered-image">
          <img
            style={{ width: 60, height: 60 }}
            src="https://cdn-icons-png.flaticon.com/256/6776/6776167.png"
            alt="flat icon"
          />
        </div>
        <div>
          <div className="upgradeContainer">
            <p className="premiumTxt">Go Premium</p>
            <p className="upgradeTxt">
              Unlock exclusive features with our premium plan
            </p>
            <div className="flex justify-around">
              <Link
                to={"/upgrade"}
                className="upgradeBtn btn rounded-md px-8 h-10"
              >
                Upgrade Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
