// import axios from "axios";
// import { API_URL } from "../../shared/functions";
import ApiExecute from ".";

const register = async (name, email, password) => {
  // try {
    return await ApiExecute("register", "POST", {
      data: {
        name,
        email,
        password,
      },
      is_guest: true,
    });

    // localStorage.setItem("user", JSON.stringify(response?.data));
    // localStorage.setItem("token", response?.accessToken);
  // } catch (error) {
  //   console.warn("Error: ", error);
  // }
};

const login = async (login, password) => {
  // const response = await axios.post(API_URL + "login", {
  //   login,
  //   password,
  // });

  // try {
  return await ApiExecute("login", "POST", {
    data: {
      login,
      password,
    },
    is_guest: true,
  });

  //   if (response?.data) {
  //     localStorage.setItem("user", JSON.stringify(response?.data));
  //     localStorage.setItem("token", response?.accessToken);
  //   }
  //   return { status: true, data: response };
  // } catch (error) {
  //   console.warn("Error:", error);
  //   return { status: false, error };
  // }
};

const socialLogin = async (id, email, name, image, type) => {
  return await ApiExecute("social-login", "POST", {
    data: {
      id,
      image,
      name,
      email,
      type,
    },
    is_guest: true,
  });
};

const forgotPassword = async (email) => {
  await ApiExecute("send-reset-password-link", "POST", {
    data: {
      email,
    },
    is_guest: true,
  });
};

const recoverPassword = async (data) => {
  await ApiExecute("reset-password", "POST", {
    data,
    is_guest: true,
  });
};

const logout = () => {
  localStorage.removeItem("user");
  localStorage.clear();
};

const AuthenticationService = {
  register,
  login,
  socialLogin,
  logout,
  forgotPassword,
  recoverPassword,
};

export default AuthenticationService;
