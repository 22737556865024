import React from "react";
import useSocket from "../../redux/utils/useSocket";

const CanvasCursors = () => {
  const { clients } = useSocket();

  //   if (!clients?.length) return null;

  return (
    <div id="pointers">
      {clients?.map((u, i) => (
        <div
          className="pointer"
          key={i}
          style={{
            left: u?.x ?? 0,
            top: u?.y ?? 0,
          }}
        >
          <div>
            <svg x="0px" y="0px" width="16" height="16" viewBox="0 0 512 512">
              <g>
                <path
                  className="st0"
                  fill={u?.cursor_color}
                  d="M106.66,8.16c-25.2-19.61-61.93-1.64-61.93,30.29v435.11c0,36.38,45.91,52.34,68.47,23.8l107.21-135.59
                c8.01-10.13,20.2-16.03,33.11-16.03h175.29c36.54,0,52.4-46.23,23.56-68.66L106.66,8.16z"
                />
              </g>
            </svg>
          </div>
          <div
            style={{
              backgroundColor: u?.cursor_color,
            }}
            className="mouse-label"
          >
            {u?.user_name}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CanvasCursors;
