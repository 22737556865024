import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addRedoItems,
  addUndoItems,
  clearRedo,
  redoItemsDone,
  setActiveShape,
  toggleShapePopup,
  undoItemsDone,
} from "../../../redux/actions/flowchart";
import ReactFlow, {
  Background,
  BackgroundVariant,
  Controls,
  // MiniMap,
  Panel,
  SelectionMode,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  useEdgesState,
  useNodesState,
  useOnSelectionChange,
  useReactFlow,
} from "reactflow";
import "reactflow/dist/style.css";
import Shapes from "./Shapes";
import { v4 as uuidv4 } from "uuid";
import SvgPopup from "./Shapes/ShapeInfoPopup";
import ShapeMenu from "./Shapes/ShapeMenu";
import ConnectorShapePopup from "./ConnectorShapePopup";
import { Button, message } from "antd";
import { toBlob } from "html-to-image";
import Speech from "speak-tts"; // es6
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import WorkflowSavePopup from "./WorkflowSavePopup";
import WorkflowAction from "./WorkflowAction";
import {
  setArrowType,
  setBgColor,
  setBorderColor,
  setBorderWidth,
  setBorderstyle,
} from "../../../redux/actions/node";
import axios from "axios";
import "./index.scss";
import {
  getComments,
  openComment,
  setActiveComment,
} from "../../../redux/actions/comment";
import CommentSavePopup from "./CommentSavePopup";
import CommentNode from "./CommentNode";
import {
  setActiveNodes,
  setCurrentWorkflow,
  setSpeech,
  setSpeechOptions,
  workflowStatusUpdate,
} from "../../../redux/actions/workflow";
import SvgText from "./SvgText";
import { SET_COPYING } from "../../../redux/constants/flowchart";
import CopyLoading from "./CopyLoading";
import ShapeGroupSavePopup from "./ShapeGroupSavePopup";
import useFlowchart from "../../../redux/utils/useFlowchart";
import CustomButton from "../../../shared/Components/Button";
import SvgImage from "./SvgImage";

const panOnDrag = [1, 2];

const SvgComponent = () => {
  // let user = JSON.parse(window.localStorage.getItem("user"));

  const chart = useSelector((state) => state?.flowchart?.chartDetails);
  const copying = useSelector((state) => state?.flowchart?.copying);

  const reactFlowWrapper = useRef(null);
  const { addUpdate } = useFlowchart();

  const fillColor = useSelector((state) => state?.node?.fillColor);
  const lineColor = useSelector((state) => state?.node?.lineColor);
  const borderWidth = useSelector((state) => state?.node?.borderWidth);
  const borderStyle = useSelector((state) => state?.node?.borderStyle);
  const arrowType = useSelector((state) => state?.node?.arrowType);
  const activeComment = useSelector((state) => state?.comment?.activeComment);
  const comments = useSelector((state) => state?.comment?.comments);
  // const promptOpen = useSelector((state) => state?.workflow?.promptOpen);
  const activeFlow = useSelector((state) => state?.workflow?.activeFlow);

  const undoItems = useSelector((state) => state?.flowchart?.undoItems);
  const redoItems = useSelector((state) => state?.flowchart?.redoItems);

  const [spent, setSpent] = useState(0);
  const [playing, setPlaying] = useState(false);
  // const [prompt, setPrompt] = useState("");
  // const [promptLoading, setPromptLoading] = useState(false);

  // Selection
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [selectedEdges, setSelectedEdges] = useState([]);

  const [oldNodes, setOldNodes] = useState([]);
  const [oldEdges, setOldEdges] = useState([]);

  useOnSelectionChange({
    onChange: ({ nodes: selNodes, edges: selEdges }) => {
      setSelectedNodes(selNodes);
      setSelectedEdges(selEdges);

      setOldNodes(nodes);
      setOldEdges(edges);
    },
  });

  const setActiveFlow = (status) => {
    dispatch(workflowStatusUpdate(status));
  };

  const activeNodes = useSelector((state) => state?.workflow?.activeNodes);
  const [modelVisible, setModelVisible] = useState(false);
  const currentWorkflow = useSelector(
    (state) => state.workflow?.currentWorkflow
  );

  const [showOptions, setShowOptions] = useState(null);
  const [mainShowOptions, setMainShowOptions] = useState(null);
  const [copyNode, setCopyNode] = useState(null);
  const [showShapePopup, setShowShapePopup] = useState(null);
  const [selectionXY, setSelectionXY] = useState(null);

  const langConvert = useSelector((state) => state?.workflow?.langConvert);

  const [speechText, setSpeechText] = useState({
    original: "",
    translated: "",
    duration: 0,
  });

  const speechOptions = useSelector((state) => state?.workflow?.speechOptions);
  const speech = useSelector((state) => state?.workflow?.speech);

  let shapesData = chart?.chart?.nodes;
  const connectorsData = chart?.chart?.edges;

  if (shapesData?.length) {
    shapesData = shapesData
      .filter((nds) => nds.type !== "Comment")
      .map((node) => {
        if (node.selected) node.selected = false;
        return node;
      });
  }

  const connectingNodeId = useRef(null);

  const [nodes, setNodes, onNodesChange] = useNodesState(shapesData || []);
  const [edges, setEdges, onEdgesChange] = useEdgesState(connectorsData || []);
  const [commentPopupOpen, setCommentPopupOpen] = useState(false);

  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  const nodeTypes = useMemo(
    () => ({
      Shapes: (props) => <Shapes {...props} />,
      SvgImage: (props) => <SvgImage {...props} />,
      SvgText: (props) => <SvgText {...props} updateLabel={updateLabel} />,
      Comment: (props) => (
        <CommentNode activeComment={activeComment} {...props} />
      ),
    }),
    [activeFlow, activeNodes, activeComment, setNodes]
  );

  const strip_html_tags = (str) => {
    if (str === null || str === "") return false;
    else str = str?.toString();
    return str?.replace(/<[^>]*>/g, "");
  };

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [edges]
  );

  const dispatch = useDispatch();

  const reactflow = useReactFlow();

  const { project } = useReactFlow();

  const popupShown = useSelector(
    (state) => state?.flowchart?.popupShown?.popupShown
  );

  useEffect(() => {
    if (chart?.pages_id) dispatch(getComments(chart?.pages_id));
  }, [chart?.pages_id]);

  useEffect(() => {
    if (currentWorkflow) {
      setActiveFlow(true);
      dispatch(setActiveNodes(0));

      let Node,
        Text,
        TextArr = [],
        wordCount = 0;

      currentWorkflow?.workflow?.forEach((wflow) => {
        Node = nodes.filter((node) => node.id === wflow);
        Node = Node[0];

        // console.log("current Node:", wflow, Node);

        Text = Node?.data?.label;

        if (Node?.data?.description && Node?.data?.description.length) {
          Text += ".\n" + strip_html_tags(Node?.data?.description) + ".";
        }

        TextArr.push(Text);

        wordCount += Text?.trim().split(" ").length;
      });

      // console.log("word count:", wordCount, TextArr);

      setSpeechText({
        original: TextArr,
        translated: TextArr,
        duration: Math.ceil((wordCount / 60) * 60),
      });
    }
  }, [currentWorkflow]);

  useEffect(() => {
    if (speech) {
      if (playing) {
        speech.resume();
      } else {
        speech.pause();
      }
    }
  }, [playing, speech]);

  useEffect(() => {
    const countDown = setInterval(() => {
      // console.log("countdown", playing, speechText?.duration, spent);
      if (speechText?.duration && spent < speechText?.duration && playing) {
        setSpent((sp) =>
          sp < speechText.duration ? sp + 1 : speechText.duration
        );
      }
    }, 1000);

    return () => clearInterval(countDown);
  }, [playing]);

  useEffect(() => {
    if (speechText.translated.length) {
      // console.log("speech text translated: ", speechText.translated);
      setSpent(0);
      setPlaying(true);
      playAudioList(0);
    }
  }, [speechText.translated]);

  useEffect(() => {
    let commentNodes = [],
      id;

    if (comments.length) {
      comments.forEach((comment) => {
        id = uuidv4();

        commentNodes.push({
          id,
          position: comment.position,
          type: "Comment",
          draggable: false,
          selectable: false,
          connectable: false,
          data: {
            ...comment,
            label: comment?.user_name,
          },
        });
      });
    }

    setNodes((nds) =>
      nds.filter((ndss) => ndss.type !== "Comment").concat(commentNodes)
    );
  }, [comments, chart?.pages_id]);

  useEffect(() => {
    async function translate(text) {
      const params = new URLSearchParams();
      params.append("q", text);
      params.append("source", langConvert?.from?.code);
      params.append("target", langConvert?.to?.code);
      params.append("format", "text");
      // params.append("api_key", "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx");

      return await axios.post("https://libretranslate.de/translate", params, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
    }

    async function translateLang() {
      dispatch(
        setSpeechOptions({
          data: langConvert?.to?.codeWithCountry,
          key: "lang",
        })
      );

      var translated = [],
        text;

      for (const index in speechText?.original) {
        text = speechText?.original[index];

        try {
          var response = await translate(text);
          console.log("translate resp:", response);
          translated[index] = response.data.translatedText;
        } catch (error) {
          console.warn("error sppech:", error);
        }
      }

      setSpeechText((text) => ({
        ...text,
        translated,
      }));
      // console.log("lang convert: ", translated, speechOptions);
    }

    if (currentWorkflow && speechText?.original) {
      translateLang();
    }
  }, [langConvert?.to, currentWorkflow]);

  useEffect(() => {
    let nodes = reactflow.getNodes();
    let newNodes = [...nodes];

    if (newNodes?.length && (fillColor || lineColor)) {
      newNodes.forEach((node, index) => {
        if (node.selected) {
          if (fillColor) newNodes[index].data.fill = fillColor;
          if (lineColor) newNodes[index].data.lineColor = lineColor;
          if (borderWidth) newNodes[index].data.borderWidth = borderWidth;
          if (borderStyle) newNodes[index].data.borderStyle = borderStyle;
        }
      });

      setNodes(newNodes);
      addUpdate();
    }
  }, [lineColor, fillColor, borderWidth, borderStyle]);

  useEffect(() => {
    let edges = reactflow.getEdges();
    let newEdges = [...edges];

    if (newEdges?.length && arrowType) {
      newEdges.forEach((edg, index) => {
        if (edg.selected) {
          if (arrowType) newEdges[index].markerEnd = arrowType;
          if (borderStyle)
            newEdges[index].style = {
              ...newEdges[index].style,
              strokeDasharray: borderStyle,
            };
        }
      });

      setEdges(newEdges);
    }
  }, [arrowType, borderStyle]);

  const unDoChanges = () => {
    if (undoItems?.length) {
      let { nodes, edges } = undoItems[undoItems.length - 1];

      reactflow.setNodes(nodes);
      reactflow.setEdges(edges);

      let newNodes = reactflow.getNodes(),
        newEdges = reactflow.getEdges();

      dispatch(addRedoItems({ nodes: newNodes, edges: newEdges }));

      setTimeout(() => {
        dispatch(undoItemsDone());
      }, 100);
    }
  };

  const reDoChanges = () => {
    if (redoItems?.length) {
      let { nodes, edges } = redoItems[redoItems.length - 1];

      reactflow.setNodes(nodes);
      reactflow.setEdges(edges);

      let newNodes = reactflow.getNodes(),
        newEdges = reactflow.getEdges();

      dispatch(addUndoItems({ nodes: newNodes, edges: newEdges }));

      setTimeout(() => {
        dispatch(redoItemsDone());
      }, 100);
    }
  };

  useEffect(() => {
    let lastNode = null,
      allEdges = [...edges],
      newEdges = [];

    if (activeNodes?.length) {
      lastNode = activeNodes[activeNodes.length - 1];
    }

    allEdges.forEach((edg) => {
      edg.animated = edg.source === lastNode;
      edg.style = {
        stroke: edg.source === lastNode ? "blue" : "#000",
        strokeWidth: 3,
      };
      newEdges.push(edg);
    });

    setEdges(newEdges);
  }, [activeNodes]);

  useEffect(() => {
    if (!activeFlow) {
      if (speech) speech?.cancel();
      setSpent(0);
      dispatch(setCurrentWorkflow(null));
    }
  }, [activeFlow]);

  const handleUndo = () => {
    dispatch(addUndoItems({ nodes, edges }));
    dispatch(clearRedo());
  };

  const deleteAction = (event) => {
    // return false;
    handleUndo();

    setTimeout(() => {
      addUpdate();
    }, 1000);
  };

  const onNodeDragStart = () => {
    handleUndo();
  };

  const onNodeDragStop = () => {
    setTimeout(() => {
      addUpdate();
    }, 100);
  };

  const playAudioList = async (index = 0, addNode = true) => {
    if (speech) speech.cancel();

    if (index <= currentWorkflow?.workflow?.length) {
      const speech = new Speech();

      // if (speech.hasBrowserSupport()) {
      //   // returns a boolean
      //   message.error("Browser doesn't support text to speech.");
      // } else {
      dispatch(setSpeech(speech));

      await speech.init(speechOptions);
      if (addNode) dispatch(setActiveNodes(currentWorkflow?.workflow[index]));

      speech
        .speak({
          text: speechText?.translated[index],
          queue: false,
          listeners: {
            onstart: () => {},
            onend: () => {},
            onresume: () => {},
            onboundary: () => {},
          },
        })
        .then(() => {
          index++;
          playAudioList(index);
        })
        .catch((e) => {
          console.error("An error occurred: ", e);
        });
      // }
    }
  };

  const addNodeWithEdge = (newNode, newEdge) => {
    setNodes((nds) => nds.concat(newNode));
    setEdges((edg) => edg.concat(newEdge));

    setShowShapePopup(null);
  };

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.setData("application/reactflow", null);
    event.dataTransfer.dropEffect = "move";
  }, []);

  const cloneChart = (position, { nodes: selNodes, edges: selEdges }) => {
    let { x, y } = position;

    let minX = 0,
      // maxX = 0,
      minY = 0,
      // maxY = 0,
      posXArr = [],
      posYArr = [];

    selNodes.forEach((shp) => {
      if (shp?.type === "Shapes") {
        posXArr.push(parseFloat(shp?.position?.x));
        posYArr.push(parseFloat(shp?.position?.y));
      }
    });

    minX = Math.min(...posXArr);
    // maxX = Math.max(...posXArr);
    minY = Math.min(...posYArr);
    // maxY = Math.max(...posYArr);

    let nodesArr = [];

    let newX,
      newY,
      id,
      connEdges = [];

    let pos = reactFlowInstance.project({ x, y });

    selNodes.forEach((shp) => {
      if (shp?.type === "Shapes") {
        id = uuidv4();

        newX = shp?.position?.x + pos.x - minX;
        newY = shp?.position?.y + pos.y - minY;

        let data = shp?.data;
        // data.shape_id = id;

        // connEdges
        let findEdges = selEdges.filter(
          (edgs) => edgs.source === shp.id || edgs.target === shp.id
        );

        findEdges.forEach((fEdg) => {
          connEdges.push({
            id: fEdg.id,
            oldSource: fEdg.source,
            newSource: fEdg.source === shp.id ? id : null,
            oldTarget: fEdg.target,
            newTarget: fEdg.target === shp.id ? id : null,
            fEdg,
          });
        });

        connEdges.forEach((conEdg, index) => {
          if (conEdg.oldTarget === shp.id && !conEdg.newTarget) {
            connEdges[index].newTarget = id;
          }
          if (conEdg.oldSource === shp.id && !conEdg.newSource) {
            connEdges[index].newSource = id;
          }
        });

        nodesArr.push({
          id,
          position: { x: newX, y: newY },
          type: shp?.type, //"Shapes",
          data,
          selected: true,
        });
      }
    });

    let edgesArr = [];
    connEdges
      .filter((edg) => edg.newTarget && edg.newSource)
      .forEach((edg) => {
        var newEdge = { ...edg.fEdg };
        newEdge.id = uuidv4();
        newEdge.source = edg.newSource;
        newEdge.target = edg.newTarget;
        newEdge.selected = true;
        edgesArr.push(newEdge);
      });

    setNodes((nds) => nds.concat(nodesArr));
    setEdges((edg) => edg.concat(edgesArr));

    setTimeout(() => {
      addUpdate();
    }, 100);
  };

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      dispatch(addUndoItems({ nodes, edges }));

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();

      let copiedItem = event.dataTransfer.getData("application/reactflow");

      if (copiedItem) {
        copiedItem = JSON.parse(copiedItem);
      }

      let activeShape = copiedItem?.shape;

      if (copiedItem?.type === "shape") {
        // Individual Shape

        // check if the dropped element is valid
        if (typeof activeShape === "undefined" || !activeShape) {
          return;
        }

        const image = new Image();

        image.onload = () => {
          let { naturalWidth: width, naturalHeight: height } = image;

          const position = reactFlowInstance.project({
            x: event.clientX - reactFlowBounds.left,
            y: event.clientY - reactFlowBounds.top,
          });
          let id = uuidv4();

          let type = "Shapes";
          if (activeShape?.shape_name === "Text") {
            type = "SvgText";
          }
          if (activeShape?.category_name === "My shapes and images") {
            type = "SvgImage";
            width = 100;
            height = 100;
          }

          const newNode = {
            id,
            type,
            position,
            data: {
              shape_id: id,
              label: activeShape.shape_name,
              shapeName: activeShape?.shape_name,
              id: activeShape.shape_id,
              height,
              width,
              color: activeShape.fill || "#ffffff",
              imageSrc: activeShape.shape_src,
              strokeWidth: activeShape.stroke_width || 1,
              strokeColor: activeShape.stroke || "#000000",
              categoryName: activeShape?.category_name,
            },
          };

          setNodes((nds) =>
            activeShape?.category_name === "Standard"
              ? [newNode].concat(nds)
              : nds.concat(newNode)
          );

          setTimeout(() => {
            addUpdate();
          }, 1000);
        };

        image.src = activeShape.shape_src;
      } else {
        // Shapes Group
        let x = event.clientX - reactFlowBounds.left,
          y = event.clientY - reactFlowBounds.top;

        setNodes((nodes) =>
          nodes.map((nds) => {
            nds.selected = false;
            return nds;
          })
        );

        cloneChart({ x, y }, activeShape?.chart);
        dispatch(setActiveShape(null));
      }

      // }
    },
    [reactFlowInstance, setNodes, setEdges]
  );

  const updateLabel = (id, label) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === id) {
          node.data = {
            ...node.data,
            label,
          };
        }
        return node;
      })
    );

    addUpdate();
  };

  const updateNodeData = (values, id) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === id) {
          // alert(JSON.stringify(values));
          node.data = { ...node.data, ...values };
        }

        return node;
      })
    );

    addUpdate();
    dispatch(toggleShapePopup(null));
  };

  const showOptionsFn = (event, node) => {
    event.preventDefault();
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    let { clientX: x, clientY: y } = event;
    x -= reactFlowBounds.left;
    y -= reactFlowBounds.top;
    setShowOptions({ node, pos: { x, y } });
    setMainShowOptions(null);

    setSelectionXY({ ...selectionXY, x, y });
  };

  const showCanvasOptions = (event, node) => {
    event.preventDefault();
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    let { clientX: x, clientY: y } = event;
    x -= reactFlowBounds.left;
    y -= reactFlowBounds.top;
    setMainShowOptions({ node, pos: { x, y } });
    setShowOptions(null);
  };

  const onConnectStart = useCallback((_, props) => {
    connectingNodeId.current = props;
  }, []);

  const onConnectEnd = useCallback(
    (event) => {
      const targetIsPane = event.target.classList.contains("react-flow__pane");

      if (targetIsPane) {
        const { top, left } = reactFlowWrapper.current.getBoundingClientRect();

        let pos = {
          x: event.clientX - left,
          y: event.clientY - top,
        };

        setShowShapePopup(pos);
      }
    },
    [project]
  );

  const saveAsImage = () => {
    setNodes(selectedNodes);
    setEdges(selectedEdges);

    dispatch({
      type: SET_COPYING,
      payload: true,
    });

    let viewport;

    setTimeout(async () => {
      viewport = reactFlowInstance.getViewport();

      reactFlowInstance.fitView();

      let png = await toBlob(document.querySelector(".react-flow"), {
        filter: (node) => {
          if (
            node?.classList?.contains("react-flow__minimap") ||
            node?.classList?.contains("react-flow__controls") ||
            node?.classList?.contains("react-flow__panel") ||
            node?.classList?.contains("react-flow__background") ||
            node?.classList?.contains("react-flow__nodesselection") ||
            node?.classList?.contains("react-flow__resize-control")
          ) {
            return false;
          }

          return true;
        },
      });

      navigator.clipboard.write([
        new ClipboardItem({
          "image/png": png,
        }),
      ]);

      setTimeout(() => {
        setNodes(oldNodes);
        setEdges(oldEdges);

        setTimeout(() => {
          // reactFlowInstance.fitView();
          reactFlowInstance.setViewport(viewport);

          dispatch({
            type: SET_COPYING,
            payload: false,
          });

          message.success("Image copied.");
        }, 100);
      }, 100);
    }, 100);
  };

  const copyShape = (node) => {
    if (!activeComment) {
      setCopyNode(node);
      sessionStorage.setItem("copy_node", JSON.stringify(node));
      setShowOptions(null);
    }
  };

  const deleteShape = (node) => {
    if (!Array.isArray(node)) {
      setNodes((nds) => nds.filter((nd) => nd.id !== node?.id));
    } else {
      let nodes = [];
      node.forEach((n) => {
        nodes.push(n.id);
      });

      setNodes((nds) => nds.filter((nd) => !nodes.includes(nd.id)));
    }

    setShowOptions(null);
    addUpdate();
  };

  const unselectAll = () => {
    setNodes((nodes) => nodes.map((n) => ({ ...n, selected: false })));
    setEdges((edges) => edges.map((e) => ({ ...e, selected: false })));
  };

  const handlePaste = async (type = "click", event = null) => {
    if (!activeComment) {
      unselectAll();

      if (sessionStorage.getItem("copy_node")) {
        let shape = JSON.parse(sessionStorage.getItem("copy_node"));
        const reactFlowBounds =
          reactFlowWrapper.current.getBoundingClientRect();

        let position = {
          x: shape?.position?.x + 100,
          y: shape?.position?.y + 100,
        };

        if (type === "click" && event.clientX && event.clientY) {
          let { clientX: x, clientY: y } = event;

          x -= reactFlowBounds.left;
          y -= reactFlowBounds.top;

          reactFlowInstance.project({ x, y });
        }

        if (!Array.isArray(shape)) {
          let id = uuidv4();

          let data = shape?.data;
          // data.shape_id = id;

          const newNode = {
            id,
            position,
            type: shape?.type, //"Shapes",
            data,
            selected: true,
          };

          setNodes((nds) => nds.concat(newNode));
        } else if (selectionXY?.nodes?.length) {
          const { nodes: selNodes, x: selX, y: selY } = selectionXY;

          if (selNodes?.length) {
            let nodesArr = [];

            let newX,
              newY,
              id,
              connEdges = [];

            if (type === "click") {
              let { clientX: x, clientY: y } = event;

              x -= reactFlowBounds.left;
              y -= reactFlowBounds.top;
              var pos = reactFlowInstance.project({ x, y });
            }

            var selPos = reactFlowInstance.project({
              x: selX,
              y: selY,
            });

            selNodes.forEach((shp) => {
              id = uuidv4();

              newX =
                type === "click"
                  ? shp?.position?.x + pos.x - selPos.x
                  : shp?.position?.x + 100;
              newY =
                type === "click"
                  ? shp?.position?.y + pos.y - selPos.y
                  : shp?.position?.y;

              let data = shp?.data;
              // data.shape_id = id;

              // connEdges
              let findEdges = edges.filter(
                (edgs) => edgs.source === shp.id || edgs.target === shp.id
              );

              findEdges.forEach((fEdg) => {
                connEdges.push({
                  id: fEdg.id,
                  oldSource: fEdg.source,
                  newSource: fEdg.source === shp.id ? id : null,
                  oldTarget: fEdg.target,
                  newTarget: fEdg.target === shp.id ? id : null,
                  fEdg,
                });
              });

              connEdges.forEach((conEdg, index) => {
                if (conEdg.oldTarget === shp.id && !conEdg.newTarget) {
                  connEdges[index].newTarget = id;
                }
                if (conEdg.oldSource === shp.id && !conEdg.newSource) {
                  connEdges[index].newSource = id;
                }
              });

              nodesArr.push({
                id,
                position: { x: newX, y: newY },
                type: shp?.type, // "Shapes",
                data,
                selected: true,
              });
            });

            let edgesArr = [];
            connEdges
              .filter((edg) => edg.newTarget && edg.newSource)
              .forEach((edg) => {
                var newEdge = { ...edg.fEdg };
                newEdge.id = uuidv4();
                newEdge.source = edg.newSource;
                newEdge.target = edg.newTarget;
                newEdge.selected = true;
                edgesArr.push(newEdge);
              });

            setNodes((nds) => nds.concat(nodesArr));
            setEdges((edg) => edg.concat(edgesArr));
          }
        }

        setMainShowOptions(null);
        setCopyNode(null);

        addUpdate();
      }
    }
  };

  const addShapeWithEdge = (shape, pos) => {
    let { x, y } = pos,
      { nodeId, handleId } = connectingNodeId.current,
      handleArr = handleId.split("-"),
      targetHandle = null;

    let id = uuidv4();

    const image = new Image();
    image.onload = () => {
      const { naturalWidth: width, naturalHeight: height } = image;

      switch (handleArr[1]) {
        case "top":
          // y -= height;
          // x -= width / 2;
          targetHandle = `${shape.shape_id}-bottom`;
          break;

        case "bottom":
          // y += height;
          // x -= width / 2;
          targetHandle = `${shape.shape_id}-top`;
          break;

        case "left":
          targetHandle = `${shape.shape_id}-right`;
          // x -= width;
          // y -= height / 2;
          break;

        case "right":
          targetHandle = `${shape.shape_id}-left`;
          // x += width;
          y -= height / 2;
          break;

        default:
          break;
      }

      const newNode = {
        id,
        position: reactFlowInstance.project({ x, y }),
        type: "Shapes",
        data: {
          shape_id: id,
          label: shape.shape_name,
          shapeName: shape?.shape_name,
          id: shape.shape_id,
          height,
          width,
          color: shape.fill || "#ffffff",
          imageSrc: shape.shape_src,
          strokeWidth: shape.stroke_width || 1,
          strokeColor: shape.stroke || "#000000",
          categoryName: shape?.category_name,
        },
      };

      let edgeId = uuidv4();

      const newEdge = {
        id: edgeId,
        type: "smoothstep",
        style: {
          stroke: "#000",
          strokeWidth: 3,
        },
        source: nodeId,
        target: id,
        selected: false,
        markerEnd: {
          type: "arrowclosed",
          color: "#000",
        },
        sourceHandle: handleId,
        targetHandle,
      };

      addNodeWithEdge(newNode, newEdge);
    };
    image.src = shape?.shape_src;
  };

  const onSelect = (node) => {
    if (node?.data) {
      let { data } = node;

      dispatch(setBgColor(data?.fill ?? null));
      dispatch(setBorderColor(data?.stroke ?? null));
      dispatch(setBorderstyle(data?.borderStyle ?? null));
      dispatch(setBorderWidth(data?.borderWidth ?? null));
      dispatch(setArrowType(null));
    }
  };

  const activateNodes = (_, node) => {
    onSelect(node);

    if (activeFlow && !currentWorkflow) {
      let flag = false;
      if (activeNodes?.length) {
        let connected = edges.filter(
          (edgs) => edgs.source === activeNodes[activeNodes.length - 1]
        );

        if (connected.length) {
          connected.forEach((conn) => {
            if (conn.target === node.id) flag = true;
          });
        }
        if (!flag) {
          alert("Warning! Please select node in flow.");
          return false;
        }
      }

      if (!activeNodes.includes(node.id)) {
        dispatch(setActiveNodes(node.id));
      }
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      // event.preventDefault();

      function doCopy() {
        var reactflow2 = reactflow?.toObject();
        let selNodes = reactflow2?.nodes.filter(
          (nds) => nds?.selected === true
        );

        if (Array.isArray(selNodes)) {
          if (selNodes.length === 1) copyShape(selNodes[0]);
          else copyShape(selNodes);
          message.success(selNodes.length > 1 ? "Nodes copied" : "Node copied");
        }
      }

      const code = event.which || event.keyCode;

      let charCode = String.fromCharCode(code).toLowerCase();

      if (event.ctrlKey || event.metaKey) {
        switch (charCode) {
          case "c":
            doCopy();
            break;

          case "v":
            // pasteKeyShape();
            handlePaste("key");
            break;

          case "z":
            unDoChanges();
            break;

          case "y":
            reDoChanges();
            break;
        }
      }

      if (
        (event.ctrlKey && charCode === "y") ||
        (event.metaKey && event.shiftKey && charCode === "z")
      ) {
        reDoChanges();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [handlePaste, unDoChanges, reDoChanges]);

  return (
    <div
      style={{
        position: "relative",
        height: "100%",
        border: activeFlow ? 0 : "2px solid #ccc",
        backgroundColor: activeFlow ? "#4b4b4b" : "#fff",
        cursor: activeComment ? "url('./comment.cur'), auto" : "default",
      }}
      id="canvas-area"
      ref={reactFlowWrapper}
      className={activeComment ? "active-comment" : null}
    >
      {copying ? <CopyLoading /> : null}
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        nodesDraggable={
          !activeFlow && !activeComment && chart?.access_type === "EDIT"
        }
        elementsSelectable={
          !activeFlow && !activeComment && chart?.access_type === "EDIT"
        }
        nodesConnectable={
          !activeFlow && !activeComment && chart?.access_type === "EDIT"
        }
        nodeOrigin={[0, 0]}
        snapToGrid={true}
        snapGrid={[25, 25]}
        minZoom={0.2}
        maxZoom={4}
        onNodeDragStart={onNodeDragStart}
        onNodeDragStop={onNodeDragStop}
        onNodesDelete={deleteAction}
        onEdgesDelete={deleteAction}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onInit={setReactFlowInstance}
        onDrop={
          !activeFlow && !activeComment && chart?.access_type === "EDIT"
            ? onDrop
            : null
        }
        onDragOver={onDragOver}
        onConnectStart={onConnectStart}
        onConnectEnd={onConnectEnd}
        onSelect={onSelect}
        defaultViewport={
          chart?.chart?.viewport
            ? chart.chart.viewport
            : { zoom: 0.5, x: 0, y: 0 }
        }
        panOnScroll={!activeFlow}
        onZoomIn={() => addUpdate()}
        onZoomOut={() => addUpdate()}
        selectionOnDrag={
          !activeFlow && !activeComment && chart?.access_type === "EDIT"
        }
        panOnDrag={panOnDrag}
        selectionMode={SelectionMode.Partial}
        connectionLineType={"smoothstep"}
        connectionMode="loose"
        defaultEdgeOptions={{
          type: "smoothstep",
          style: {
            stroke: "#000",
            strokeWidth: 3,
            cursor: activeComment ? "cell" : "default",
          },
          markerEnd: {
            type: "arrowclosed",
            color: "#000",
          },
          updatable: true,
        }}
        connectionLineStyle={{
          stroke: "#000",
        }}
        // onSelectionEnd={onSelectionEnd}
        onNodeDoubleClick={(_, node) => {
          setNodes((nodes) =>
            nodes.map((n) => {
              if (n.id === node.id) n.selected = true;
              else n.selected = false;
              return n;
            })
          );

          return !activeFlow &&
            !activeComment &&
            node?.data?.categoryName !== "Standard"
            ? dispatch(toggleShapePopup(node))
            : null;
        }}
        onNodeContextMenu={showOptionsFn}
        onNodeClick={activateNodes}
        onPaneContextMenu={showCanvasOptions}
        onSelectionContextMenu={showOptionsFn}
        onSelectionChange={({ nodes, edges }) => {
          if (nodes.length) setSelectionXY({ ...selectionXY, nodes, edges });
        }}
        onClick={(event) => {
          setMainShowOptions(null);
          setShowOptions(null);

          if (!event.target.closest(".commentContainer")) {
            if (!commentPopupOpen) {
              if (activeComment) {
                const reactFlowBounds =
                  reactFlowWrapper.current.getBoundingClientRect();

                const position = reactFlowInstance.project({
                  x: event.clientX - reactFlowBounds.left,
                  y: event.clientY - reactFlowBounds.top,
                });

                setCommentPopupOpen({
                  position,
                  screen: {
                    x: event.clientX - reactFlowBounds.left,
                    y: event.clientY - reactFlowBounds.top,
                  },
                });
              }
            } else {
              setCommentPopupOpen(null);
            }
          }
        }}
        onPaneClick={() => {
          dispatch(openComment(null));
          setShowShapePopup(null);
        }}
        fitView={activeFlow}
      >
        {activeComment ? (
          <>
            <Panel position="top-left">
              <CustomButton
                isPrimary={false}
                buttonText={"Close"}
                hasIcon={<CloseOutlined />}
                onClick={() => dispatch(setActiveComment(false))}
              />
              {commentPopupOpen ? (
                <CommentSavePopup
                  modelVisible={commentPopupOpen}
                  setModelVisible={setCommentPopupOpen}
                  pages_id={chart?.pages_id}
                />
              ) : null}
            </Panel>
          </>
        ) : null}
        {/* <MiniMap pannable zoomable={false} /> */}
        <Controls
          showInteractive={false}
          onZoomIn={() => addUpdate()}
          onZoomOut={() => addUpdate()}
          onFitView={() => addUpdate()}
          position={currentWorkflow ? "right top" : "bottom left"}
        />
        {!activeFlow ? (
          <>
            <Background
              id="1"
              gap={10}
              color="#d9d9d9"
              variant={BackgroundVariant.Lines}
            />
            <Background
              id="2"
              gap={100}
              offset={1}
              color="#ccc"
              variant={BackgroundVariant.Lines}
            />

            {popupShown ? (
              <SvgPopup shapeData={popupShown} onChange={updateNodeData} />
            ) : null}

            {showOptions ? (
              <ShapeMenu
                shapeData={showOptions}
                copyShape={copyShape}
                deleteShape={deleteShape}
                saveAsImage={saveAsImage}
                type="node"
              />
            ) : null}

            <ShapeGroupSavePopup
              selectedNodes={selectedNodes}
              selectedEdges={selectedEdges}
              oldNodes={oldNodes}
              oldEdges={oldEdges}
            />

            {mainShowOptions ? (
              <ShapeMenu
                shapeData={mainShowOptions}
                copyNode={copyNode}
                pasteShape={(event) => handlePaste("click", event)}
                type="canvas"
              />
            ) : null}

            {showShapePopup ? (
              <ConnectorShapePopup
                pos={showShapePopup}
                addShapeWithEdge={addShapeWithEdge}
              />
            ) : null}
          </>
        ) : (
          <>
            {activeNodes?.length && !currentWorkflow ? (
              <Panel position="bottom-center">
                <Button
                  type={"primary"}
                  className="primary-btn"
                  onClick={() => setModelVisible(true)}
                  style={{ marginRight: 10 }}
                >
                  <CheckOutlined /> Save Workflow
                </Button>
              </Panel>
            ) : null}
            <Panel position="top-left">
              <Button
                onClick={() =>
                  activeFlow
                    ? setActiveFlow(false)
                    : dispatch(setActiveComment(false))
                }
                className="secondary-btn"
              >
                <CloseOutlined /> Close
              </Button>

              {modelVisible ? (
                <WorkflowSavePopup
                  modelVisible={modelVisible}
                  setModelVisible={setModelVisible}
                  activeNodes={activeNodes}
                  pages_id={chart?.pages_id}
                  setActiveFlow={setActiveFlow}
                />
              ) : null}
            </Panel>

            {currentWorkflow ? (
              <Panel position="bottom-center workflow-actions">
                <WorkflowAction
                  activeNodes={activeNodes}
                  duration={speechText?.duration}
                  playing={playing}
                  setPlaying={setPlaying}
                  spent={spent}
                  setSpent={setSpent}
                  playAudioList={playAudioList}
                />
              </Panel>
            ) : null}
          </>
        )}
      </ReactFlow>
    </div>
  );
};

export default SvgComponent;
