import React, { memo, useEffect, useRef, useState } from "react";
import {
  Handle,
  NodeResizer,
  Position,
  useReactFlow,
  useUpdateNodeInternals,
} from "reactflow";
import "./index.scss";
import { useSelector } from "react-redux";
import { drag } from "d3-drag";
import { select } from "d3-selection";
// import { CheckSquareOutlined } from "@ant-design/icons";
import { BsCheck2Square } from "react-icons/bs";
import { RiDragMove2Fill } from "react-icons/ri";
import useFlowchart from "../../../../redux/utils/useFlowchart";

function Shapes(props) {
  const { id, data, selected } = props;
  const { addUpdate } = useFlowchart();

  const reactFlowInstance = useReactFlow();

  // console.log("props : ", props);
  const [svg, setSvg] = useState(null);
  const [hovered, setHovered] = useState(false);
  const rotateControlRef = useRef(null);

  const circleSize = 35;

  const [rotation, setRotation] = useState(data?.rotate ?? 0);
  const updateNodeInternals = useUpdateNodeInternals();

  const activeNodes = useSelector((state) => state?.workflow?.activeNodes);
  const activeFlow = useSelector((state) => state?.workflow?.activeFlow);
  const fillColor = useSelector((state) => state?.node?.fillColor);
  const lineColor = useSelector((state) => state?.node?.lineColor);
  const borderWidth = useSelector((state) => state?.node?.borderWidth);
  const borderStyle = useSelector((state) => state?.node?.borderStyle);

  useEffect(() => {
    if (!rotateControlRef.current) {
      return;
    } else {
      const selection = select(rotateControlRef.current);
      const dragHandler = drag().on("drag", (evt) => {
        const dx = evt.x - 100;
        const dy = evt.y - 100;
        const rad = Math.atan2(dx, dy);
        const deg = rad * (180 / Math.PI);
        setRotation(180 - deg);
        updateNodeInternals(id);
      });

      selection.call(dragHandler);
    }
  }, [id, updateNodeInternals]);

  useEffect(() => {
    if (svg) {
      setSvg((svgCode) => {
        if (svgCode) {
          svgCode = svgCode.replace(
            "<svg",
            `<svg width="100%" height="100%" id="svg_${data.id}"`
          );

          let fillColor = data?.categoryName === "Flow Chart" ? "#fff" : "#000",
            lineColor = "#000",
            strokeWidth = 2,
            dashArray = "0,0";

          if (data?.fill) fillColor = data.fill;
          if (data?.lineColor) lineColor = data.lineColor;
          if (data?.borderWidth) strokeWidth = data.borderWidth;
          if (data?.borderStyle) dashArray = data.borderStyle;

          if (activeFlow)
            fillColor = activeNodes.includes(id) ? fillColor : "#555";

          svgCode = svgCode.replace(
            /fill=\"\#[A-Fa-f0-9]{3,6}\"/,
            `fill="${fillColor}"`
          );

          svgCode = svgCode.replace(
            /stroke=\"\#[A-Fa-f0-9]{3,6}\"/,
            `stroke="${lineColor}" stroke-width="${strokeWidth}" stroke-dasharray="${dashArray}"`
          );
        }
        return svgCode;
      });
    }
  }, [
    // dataUpdated,
    activeFlow,
    lineColor,
    fillColor,
    borderWidth,
    borderStyle,
    data.fill,
    data.lineColor,
    data.borderWidth,
    data.borderStyle,
  ]);

  useEffect(() => {
    if (data?.imageSrc) {
      fetch(data.imageSrc)
        .then((response) => response.text())
        .then((svgCode) => {
          // `svgCode` contains the SVG code from the image source
          svgCode = svgCode.replace(
            "<svg",
            `<svg width="100%" height="100%" preserveAspectRatio="none" id="svg_${data.id}"`
          );

          let fillColor = data?.categoryName === "Flow Chart" ? "#fff" : "#000",
            lineColor = "#000",
            strokeWidth = 2,
            dashArray = "0,0";

          if (data?.fill) fillColor = data.fill;
          if (data?.lineColor) lineColor = data.lineColor;
          if (data?.borderWidth) strokeWidth = data.borderWidth;
          if (data?.borderStyle) dashArray = data.borderStyle;

          if (activeFlow)
            fillColor = activeNodes.includes(id) ? fillColor : "#555";

          svgCode = svgCode.replace(
            /fill=\"\#[A-Fa-f0-9]{3,6}\"/,
            `fill="${fillColor}"`
          );

          svgCode = svgCode.replace(
            /stroke=\"\#[A-Fa-f0-9]{3,6}\"/,
            `stroke="${lineColor}" stroke-width="${strokeWidth}" stroke-dasharray="${dashArray}"`
          );

          setSvg(svgCode);
        });
    }
  }, []);

  const updateRotation = (id, rotate) => {
    reactFlowInstance.setNodes((nds) =>
      nds.map((node) => {
        if (node.id === id) {
          node.data = {
            ...node.data,
            rotate,
          };
        }
        return node;
      })
    );

    setTimeout(() => {
      addUpdate();
    }, 1000);
  };

  useEffect(() => {
    if (rotation) updateRotation(id, rotation);
  }, [rotation]);

  return (
    <div
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className="canvas-shape"
      style={{
        transform: `rotate(${rotation}deg)`,
        width: "100%",
        height: "100%",
        minWidth: data?.width * 2,
        minHeight: data?.height * 2,
        position: "relative",
      }}
    >
      <NodeResizer
        nodeId={data?.id}
        isVisible={selected}
        keepAspectRatio={false}
        handleStyle={{
          width: 20,
          height: 20,
          background: "#fff",
          borderColor: "blue",
          borderWidth: 2,
          borderRadius: 5,
        }}
        lineStyle={{
          borderWidth: 2,
        }}
        onResizeEnd={() => addUpdate()}
      />
      <div
        ref={rotateControlRef}
        className={`nodrag rotateHandle ${!selected ? "hidden" : ""}`}
      />
      {data?.categoryName === "Flow Chart" ? (
        <>
          <div style={{ opacity: hovered ? 1 : 0 }}>
            <Handle
              id={`${data.id}-top`}
              position={Position.Top}
              type="source"
              style={{
                width: circleSize,
                height: circleSize,
                backgroundColor: "#fff",
                borderColor: "#000",
                marginTop: `-${(circleSize - 20) / 2}px`,
              }}
            />
            <Handle
              id={`${data.id}-bottom`}
              position={Position.Bottom}
              type="source"
              style={{
                width: circleSize,
                height: circleSize,
                backgroundColor: "#fff",
                borderColor: "#000",
                marginBottom: `-${(circleSize - 20) / 2}px`,
              }}
            />
            <Handle
              id={`${data.id}-left`}
              position={Position.Left}
              type="source"
              style={{
                width: circleSize,
                height: circleSize,
                backgroundColor: "#fff",
                borderColor: "#000",

                marginLeft: `-${(circleSize - 20) / 2}px`,
              }}
            />
            <Handle
              id={`${data.id}-right`}
              position={Position.Right}
              type="source"
              style={{
                width: circleSize,
                height: circleSize,
                backgroundColor: "#fff",
                borderColor: "#000",
                marginRight: `-${(circleSize - 20) / 2}px`,
              }}
            />
          </div>
          <label className="centered" htmlFor="text">
            {data?.label.substr(0, 60)}
            {data.label.length > 60 ? "..." : null}
          </label>
        </>
      ) : null}
      {svg ? (
        <div>
          {data?.categoryName !== "Flow Chart" ? (
            <div
              className="dragHandle"
              style={{
                // marginTop: `-${data.height}px`,
                // marginLeft: `-${data.width}px`,
                fontSize: 30,
              }}
            >
              {selected ? <RiDragMove2Fill /> : <BsCheck2Square />}
            </div>
          ) : null}
          <div
            dangerouslySetInnerHTML={{ __html: svg }}
            className={data?.categoryName !== "Flow Chart" ? "nodrag" : ""}
          ></div>
        </div>
      ) : null}
    </div>
  );
}

export default memo(Shapes);
