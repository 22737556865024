import React, { useRef, useState } from "react";
import CommentBox from "../../CanvasLayout/RightSider/CommentList/CommentBox";
import { Avatar } from "antd";
import styles from "./style.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { BiSend } from "react-icons/bi";
import {
  addUpdateComment,
  getComments,
} from "../../../../redux/actions/comment";
import EmojiPicker from "emoji-picker-react";
import { FaRegSmile } from "react-icons/fa";

/**
 *
 * @param {*} param0
 * @returns
 *
 * for emojis
 * https://www.npmjs.com/package/emoji-picker-react
 */

const SingleComment = ({ comment }) => {
  const chart = useSelector((state) => state?.flowchart?.chartDetails);
  // let user = JSON.parse(window.localStorage.getItem("user"));
  const dispatch = useDispatch();

  const [emojiOpen, setEmojiOpen] = useState(false);
  const [replyTextOpen, setReplyTextOpen] = useState(null);
  const [reply, setReply] = useState("");
  const replyRef = useRef();

  const replyToggle = (comment) => {
    setReplyTextOpen((isOpen) => (isOpen ? null : comment));
  };

  const handleComment = (replyText) => {
    setReply(replyText);
  };

  const saveReply = () => {
    if (reply && replyTextOpen) {
      let comment = {
        ...replyTextOpen,
        parent_id: replyTextOpen?.comment_id,
        comment_id: null,
        comment: reply,
      };

      dispatch(addUpdateComment(comment)).then(() => {
        setReply("");
        setReplyTextOpen(null);
        dispatch(getComments(chart?.pages_id));
      });
    }
  };
  return (
    <>
      <CommentBox comment={comment} />
      <div className="reply-container">
        <div
          className={
            styles.replyContainer +
            " " +
            (comment?.reply?.length ? styles.hasReplies : "")
          }
        >
          <button
            type="button"
            onClick={() => replyToggle(comment)}
            className={styles.replyBtn}
          >
            Reply
          </button>
        </div>
        {replyTextOpen?.comment_id === comment?.comment_id ? (
          <div className={`${styles.commentContainer}`}>
            {/* <Avatar
              style={{
                backgroundColor: "#5a03d5",
                color: "#fff",
                fontWeight: "bold",
              }}
              size={24}
            >
              {user?.name[0].toUpperCase()}
            </Avatar> */}
            <div
              style={{
                marginLeft: 10,
              }}
              onClick={() => setEmojiOpen((open) => !open)}
            >
              <FaRegSmile />
            </div>
            <div className={styles.commentInputGroup}>
              <input
                placeholder="Reply to comment"
                onInput={(e) => handleComment(e.target.value)}
                value={reply}
                ref={replyRef}
              />
              <button type="button" onClick={() => saveReply()}>
                <BiSend />
              </button>
            </div>
          </div>
        ) : null}
        {emojiOpen ? (
          <EmojiPicker
            onEmojiClick={(emojiData) => {
              replyRef.current.value += emojiData?.emoji;
              // setCommentText(replyRef.current.value);
              setReply(replyRef.current.value);
            }}
          />
        ) : null}

        {comment?.reply?.length ? (
          <div className={styles.replyLists}>
            {comment?.reply?.map((rep) => (
              <CommentBox comment={rep} />
            ))}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default SingleComment;
