import React, { useState } from "react";
import { ClusterOutlined } from "@ant-design/icons";
import "./index.scss";
import { FaAngleRight, FaPlayCircle, FaRegCommentDots } from "react-icons/fa";
import { Col, Row, Tooltip } from "antd";
import Workflow from "./Workflow";
import CommentList from "./CommentList";
import AutoDesigner from "./AutoDesigner";

function RightSidebar() {
  const [isContainerOpen, setIsContainerOpen] = useState(null);
  const handleContainerOpen = (type) => {
    setIsContainerOpen(type);
  };

  return (
    <>
      <div className="rightSidebar-container">
        <div className="iconContainer">
          <Row className="WorflowBtn">
            <Col span={24} className="action-cols">
              <Tooltip title="Workflow">
                <button
                  className={`action-btns ${
                    isContainerOpen === "workflow" ? "active" : ""
                  }`}
                  onClick={() => handleContainerOpen("workflow")}
                >
                  <FaPlayCircle className="action-icons" />
                </button>
              </Tooltip>
            </Col>
          </Row>
          <Row className="CommentBtn">
            <Col span={24} className="action-cols">
              <Tooltip title="Comments">
                <button
                  className={`action-btns ${
                    isContainerOpen === "comments" ? "active" : ""
                  }`}
                  onClick={() => handleContainerOpen("comments")}
                >
                  <FaRegCommentDots className="action-icons" />
                </button>
              </Tooltip>
            </Col>
          </Row>
          <Row className="AutoDesignerBtn">
            <Col span={24} className="action-cols">
              <Tooltip title="Auto Designer">
                <button
                  className={`action-btns ${
                    isContainerOpen === "auto-designer" ? "active" : ""
                  }`}
                  onClick={() => handleContainerOpen("auto-designer")}
                >
                  <ClusterOutlined className="action-icons" />
                </button>
              </Tooltip>
            </Col>
          </Row>
        </div>

        {isContainerOpen && (
          <div
            className={`workflowContainer ${
              isContainerOpen ? "animateContainer" : ""
            }`}
          >
            {isContainerOpen === "workflow" ? (
              <Workflow closeContainer={() => setIsContainerOpen(null)} />
            ) : null}

            {isContainerOpen === "comments" ? <CommentList /> : null}

            {isContainerOpen === "auto-designer" ? <AutoDesigner /> : null}

            <div className="action-cols bottom-action">
              <Tooltip title="Close panel">
                <button
                  className={`close-btn`}
                  onClick={() => setIsContainerOpen(null)}
                >
                  <FaAngleRight class="closePanel" />
                </button>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default RightSidebar;
