import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import ApiExecute from "../../redux/services";
import logo from "../../assets/logo.png";

// import "bootstrap/scss/bootstrap.scss";
import style from "./style.module.scss";
import { Toast } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LOGIN_SUCCESS } from "../../redux/constants/authentication";
import Notification from "../../components/Notification";
import HeaderProfile from "../../components/HeaderProfile/HeaderProfile";
import { Card } from "antd";
import * as Yup from "yup";

const SurveyInfo = () => {
  const { user, token } = useSelector((state) => state.auth);

  // console.log("user", user, token);

  const dispatch = useDispatch();
  const formikRef = useRef();
  const [surveyOptions, setSurveyOptions] = useState({});

  const validationSchema = Yup.object().shape({
    app_usage: Yup.string().required(),
    job_title: Yup.string().required(),
    industry: Yup.array().required(),
  });

  const handleCheck = (e, field) => {
    let checks = [];

    let inputs = document.querySelectorAll(
      `[name="${e.currentTarget.name}"]:checked`
    );
    for (const input of inputs) {
      checks.push(input.value);
    }
    if (e.currentTarget.type === "radio") checks = checks.toString();
    formikRef.current.setFieldValue(field, checks);
  };

  useEffect(() => {
    async function fetchOptions() {
      try {
        let apiResponse = await ApiExecute("get-survey-options");
        setSurveyOptions(apiResponse);
      } catch (error) {}
    }

    fetchOptions();
  }, []);

  return (
    <div className={style.surveyInfo}>
      <header>
        <div className="container mx-auto">
          <div className={style.surveyHeader}>
            <a href="/" className={style.logo}>
              <img
                alt="Workflowsim"
                className="h-[3rem] w-[20rem] xl:ml-5"
                src={logo}
              />
            </a>
            {/* </Space> */}

            <Notification />
            <HeaderProfile />
          </div>
        </div>
      </header>
      <main>
        <div className="container mx-auto py-8">
          <Card>
            <h1 className="text-center">
              Before using WorkflowSim, help us tailor your experience
            </h1>
            <Formik
              initialValues={{
                app_usage: "",
                job_title: "",
                other_title: "",
                industry: [],
                other_industry: "",
                use_cases: [],
                discovered_through: "",
              }}
              validationSchema={validationSchema}
              innerRef={formikRef}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  let data = { ...values };

                  if (
                    values.job_title === "other" &&
                    data?.other_title?.length
                  ) {
                    data.job_title = "other:" + data?.other_title;
                  }
                  if (
                    values.industry.includes("other") &&
                    data?.other_industry?.length
                  ) {
                    data.industry = data.industry.filter(
                      (ind) => ind !== "other"
                    );
                    data.industry.push("other:" + data?.other_industry);
                  }
                  data.industry = data?.industry?.toString();
                  data.use_cases = data?.use_cases?.toString();

                  // alert(JSON.stringify(data));
                  let apiResponse = await ApiExecute(
                    "update-survey-info",
                    "POST",
                    {
                      data,
                    }
                  );
                  setSubmitting(false);

                  let userData = { ...user, ...data };
                  // let storageUser = JSON.parse(localStorage.getItem("user"));
                  // storageUser.data = userData;
                  localStorage.setItem("user", JSON.stringify(userData));

                  dispatch({
                    type: LOGIN_SUCCESS,
                    payload: {
                      user: { data: userData, accessToken: token },
                    },
                  });
                } catch (error) {
                  Toast.alert("Message: ", error);
                }
              }}
            >
              {({ values, handleBlur, handleChange, isSubmitting }) => (
                <Form>
                  <div className={style.formGroup}>
                    <label htmlFor="app_usage" className={style.formLabel}>
                      What are you using this app for?
                    </label>

                    <div className={style.customCheckGroup}>
                      {surveyOptions?.app_usage?.map((appUsage, i) => (
                        <label key={i} className={style.customCheck}>
                          <input
                            type="radio"
                            name="appUsage"
                            value={appUsage}
                            onClick={(e) => handleCheck(e, "app_usage")}
                          />
                          <div>{appUsage}</div>
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className={style.formGroup}>
                    <label htmlFor="job_title" className={style.formLabel}>
                      What best represents your job title?
                    </label>
                    <div className={style.customCheckGroup}>
                      {surveyOptions?.job_title?.map((title, i) => (
                        <label key={i} className={style.customCheck}>
                          <input
                            type="radio"
                            name="title"
                            value={title}
                            onClick={(e) => handleCheck(e, "job_title")}
                          />
                          <div>{title}</div>
                        </label>
                      ))}
                      <label className={style.customCheck}>
                        <input
                          type="radio"
                          name="title"
                          value={"other"}
                          onClick={(e) => handleCheck(e, "job_title")}
                        />
                        <div>Other (please specify)</div>
                      </label>
                    </div>
                  </div>

                  {values?.job_title === "other" ? (
                    <div className={style.formGroup}>
                      <input
                        type="text"
                        name="other_title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Enter job title"
                      />
                    </div>
                  ) : null}
                  <div className={style.formGroup}>
                    <label htmlFor="" className={style.formLabel}>
                      Which industry do you belong to?
                      <small>(Select all that apply)</small>
                    </label>
                    <div className={style.customCheckGroup}>
                      {surveyOptions?.industry?.map((industry, i) => (
                        <label key={i} className={style.customCheck}>
                          <input
                            type="checkbox"
                            name="industry[]"
                            value={industry}
                            onClick={(e) => handleCheck(e, "industry")}
                          />
                          <div>{industry}</div>
                        </label>
                      ))}
                      <label className={style.customCheck}>
                        <input
                          type="checkbox"
                          name="industry[]"
                          value={"other"}
                          onClick={(e) => handleCheck(e, "industry")}
                        />
                        <div>Other (please specify)</div>
                      </label>
                    </div>
                  </div>
                  {values?.industry.includes("other") ? (
                    <div className={style.formGroup}>
                      <input
                        type="text"
                        name="other_industry"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Name of other industry"
                      />
                    </div>
                  ) : null}
                  <div className={style.formGroup}>
                    <label htmlFor="" className={style.formLabel}>
                      What are your primary use cases for creating diagrams?
                      <small>(Select all that apply)</small>
                    </label>
                    <div className={style.customCheckGroup}>
                      {surveyOptions?.use_cases?.map((use_cases, i) => (
                        <label key={i} className={style.customCheck}>
                          <input
                            type="checkbox"
                            name="use_cases[]"
                            value={use_cases}
                            onClick={(e) => handleCheck(e, "use_cases")}
                          />
                          <div>{use_cases}</div>
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className={style.formGroup}>
                    <label htmlFor="" className={style.formLabel}>
                      Where did you hear about WorkflowSim?
                    </label>
                    <div className={style.customCheckGroup}>
                      {surveyOptions?.discovered_through?.map(
                        (discovered_through, i) => (
                          <label key={i} className={style.customCheck2}>
                            <input
                              type="radio"
                              name="discovered_through"
                              value={discovered_through}
                              onClick={(e) =>
                                handleCheck(e, "discovered_through")
                              }
                            />
                            <div>{discovered_through}</div>
                          </label>
                        )
                      )}
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      className="btn primary-btn px-5"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Please wait..." : "Submit"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Card>
        </div>
      </main>
    </div>
  );
};

export default SurveyInfo;
