import React, { useEffect, useState } from "react";
import { Row, Col, Card, Badge } from "antd";
import { PlusOutlined, ClusterOutlined } from "@ant-design/icons";
import { ImStack } from "react-icons/im";
import FlowchartCard from "../../../components/Dashboard/FlowchartCard";
import "./index.scss";
import { Link, useParams } from "react-router-dom";
import NewBoardPopup from "./NewBoardPopup";
import Lottie from "react-lottie";
import anim from "./animationNoData.json";
import ApiExecute from "../../../redux/services";
import InfiniteScroll from "react-infinite-scroller";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: anim,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function FlowCharts() {
  const params = useParams();
  const [charts, setCharts] = useState([]);
  const [showNewPopup, setShowNewPopup] = useState(false);

  const [category, setCategory] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const limit = 8;

  useEffect(() => {
    setCategory(params?.category ?? "RECENT");
  }, [params?.category]);

  async function fetchCharts(pageNum = 1) {
    try {
      let apiResponse = await ApiExecute(
        `get-charts-category?page_num=${pageNum}&category=${category}&limit=${limit}`
      );

      let totalPage = Math.ceil(apiResponse?.count / limit);
      // console.log("page number: ", pageNum, totalPage);

      if (totalPage > pageNum) setHasMore(true);
      else setHasMore(false);

      // alert(typeof pageNum);

      if (pageNum === 1) setCharts(apiResponse?.charts);
      else setCharts((charts) => [...charts, ...apiResponse?.charts]);
    } catch (error) {
      console.error("Error: ", error);
    }
  }

  useEffect(() => {
    if (!category) return;

    fetchCharts();
  }, [category]);

  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <div className="optionsCard">
            <div className="card-group">
              <Card
                hoverable
                className="new-container"
                onClick={() => setShowNewPopup(true)}
              >
                <Row>
                  <Col span={8}></Col>
                  <Col
                    span={8}
                    className="plusIcon flex justify-center align-middle"
                  >
                    <PlusOutlined style={{ fontWeight: "bold" }} />
                  </Col>
                  <Col span={8}></Col>
                  <Col span={24} className="card-text">
                    New Blank Project
                  </Col>
                </Row>
              </Card>

              <Badge.Ribbon
                text="Beta"
                color="purple"
                style={{ marginTop: "-12px" }}
              >
                <Link to={"/autodesigner"}>
                  <Card hoverable className="autodesigner-container">
                    <Row>
                      <Col span={8}></Col>
                      <Col
                        span={8}
                        className="cardIcon flex justify-center align-middle"
                      >
                        <ClusterOutlined />
                      </Col>
                      <Col span={8}></Col>
                      <Col span={24} className="card-text">
                        Generate with Autodesigner
                      </Col>
                    </Row>
                  </Card>
                </Link>
              </Badge.Ribbon>

              <Link to={"/templates"}>
                <Card hoverable className="template-container">
                  <Row>
                    <Col span={8}></Col>
                    <Col
                      span={8}
                      className="cardIcon flex justify-center align-middle"
                    >
                      <ImStack />
                    </Col>
                    <Col span={8}></Col>
                    <Col span={24} className="card-text">
                      Start from template
                    </Col>
                  </Row>
                </Card>
              </Link>
            </div>
          </div>
        </Col>
      </Row>

      <div className="grow h-full" style={{ overflow: "auto" }}>
        <InfiniteScroll
          pageStart={1}
          loadMore={fetchCharts}
          hasMore={hasMore}
          initialLoad={true}
          useWindow={false}
          loader={
            <div className="loader" key={0}>
              {/* Loading ... {hasMore.toString()} */}
            </div>
          }
        >
          <div className="row-container">
            <h2>{category ?? "Recent"}</h2>

            <div>
              {charts?.length ? (
                <div className="chart-row">
                  {charts?.map((cData, index) => (
                    <div className="chart-col" key={index}>
                      <FlowchartCard
                        cData={cData}
                        title={cData?.chart_name || "Blank Diagram"}
                        imagesrc={cData?.image}
                        productType={cData?.product}
                        username={cData?.name}
                        dateModified={cData?.date_modified}
                        id={cData?.chart_id}
                        isStarred={cData?.is_starred}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <Lottie options={defaultOptions} width={300} height={300} />
              )}
            </div>
          </div>
        </InfiniteScroll>
      </div>

      {showNewPopup ? (
        <NewBoardPopup closePopup={() => setShowNewPopup(false)} />
      ) : null}
    </>
  );
}

export default FlowCharts;
