import {
  SET_ARROW_TYPE,
  SET_BG_COLOR,
  SET_BORDER_COLOR,
  SET_BORDER_STYLE,
  SET_BORDER_WIDTH,
  SET_TEXT_EDITOR,
} from "../constants/node";

export const setBgColor = (color) => (dispach) => {
  return dispach({
    type: SET_BG_COLOR,
    payload: color,
  });
};

export const setBorderColor = (color) => (dispach) => {
  return dispach({
    type: SET_BORDER_COLOR,
    payload: color,
  });
};

export const setBorderWidth = (size) => (dispach) => {
  return dispach({
    type: SET_BORDER_WIDTH,
    payload: size,
  });
};

export const setBorderstyle = (style) => (dispach) => {
  return dispach({
    type: SET_BORDER_STYLE,
    payload: style,
  });
};

export const setArrowType = (type) => (dispach) => {
  return dispach({
    type: SET_ARROW_TYPE,
    payload: type,
  });
};

export const setTextEditor =
  (tag, params = {}) =>
  (dispatch) => {
    return dispatch({
      type: SET_TEXT_EDITOR,
      payload: {
        tagName: tag,
        fontSize: params?.fontSize,
      },
    });
  };

export const resetTextEditor = () => (dispatch) => {
  return dispatch({
    type: SET_TEXT_EDITOR,
    payload: {
      tagName: null,
      fontSize: null,
    },
  });
};
