import { memo, useEffect, useRef, useState } from "react";
import "./index.scss";
import { Button, Col, Popover, Row, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toggleShapePopup } from "../../../../../redux/actions/flowchart";
import flowchart from "../../../../../redux/services/flowchart";
import { Editor } from "@tinymce/tinymce-react";

import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

import { AudioOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";

const SvgPopup = ({ shapeData, onChange }) => {
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(false);
  const [dragging, setDragging] = useState(false);

  const [shapePopupOpen, setShapePopupOpen] = useState(false);

  let shapes = useSelector((state) => state?.flowchart?.shapes?.data);

  const [shape, setShape] = useState(
    shapes["Flow Chart"].filter(
      (s) => s?.shape_src === shapeData?.data?.imageSrc
    )[0]
  );

  const formRef = useRef();

  const {
    transcript,
    listening,
    resetTranscript,
    // browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  // const formik = useFormikContext();

  const startListening = () => {
    SpeechRecognition.startListening();
  };

  useEffect(() => {
    var formik = formRef.current;

    formik?.setFieldValue(
      "description",
      formik?.values?.description + " " + transcript
    );

    if (!listening) resetTranscript();
  }, [listening]);

  const [fileImages, setFileImages] = useState(shapeData?.data?.images || []);

  const closePopup = () => {
    dispatch(toggleShapePopup(null));
  };

  const addImage = async (event, setFieldValue) => {
    setProgress(true);
    const [file] = event.target.files;

    if (!file.name.match(/\.(jpg|jpeg|png|gif|webp)$/)) {
      message.error("Invalid file format, only images can be accepted.");
    } else {
      let imageRes = await flowchart.uploadFile(file);
      setProgress(false);

      console.log("file response:", imageRes);

      setFieldValue("images", [...fileImages, imageRes.url]);
      setFileImages((fileImages) => [...fileImages, imageRes.url]);
    }
  };

  const addFile = async (event, setFieldValue) => {
    const [file] = event.target.files;
    if (!file.name.match(/\.(jpg|jpeg|png|gif|webp|pdf)$/)) {
      event.target.value = null;
      message.error(
        "Invalid file format! only image or pdf file can be accepted."
      );
    } else {
      setProgress(true);
      let fileRes = await flowchart.uploadFile(file);
      setProgress(false);

      setFieldValue("pdf_file", fileRes?.url);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDrop = async (e, setFieldValue) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    const files = Array.from(e.dataTransfer.files);

    const [file] = files;
    if (!file.name.match(/\.(jpg|jpeg|png|gif|webp)$/)) {
      message.error("Invalid file format, only images can be accepted.");
    } else {
      setProgress(true);
      let imageRes = await flowchart.uploadFile(file);
      setProgress(false);

      setFieldValue("images", [...fileImages, imageRes.url]);
      setFileImages((fileImages) => [...fileImages, imageRes.url]);
    }
  };

  return (
    <div className="popup-container">
      <div className="popup-heading">
        <h3>Shape Attributes</h3>
        <Button
          type="ghost"
          icon={<CloseOutlined />}
          onClick={() => closePopup()}
        ></Button>
      </div>
      <div className="popup-body">
        {progress ? (
          <div className="progress-bar">
            <div className="loading-icon">
              <span className="progress_loading"></span>
            </div>
            <div>Uploading</div>
          </div>
        ) : null}
        <Formik
          initialValues={{
            label: shapeData?.data?.label || "",
            description: shapeData?.data?.description || "",
            images: shapeData?.data?.images || [],
            pdf_file: shapeData?.data?.pdf_file || null,
            shape_src: shape?.shape_src,
            id: shape?.shape_id,
          }}
          innerRef={formRef}
          validate={(values) => {
            const errors = {};
            if (!values.label) {
              errors.label = "Label is Required";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            onChange(values, shapeData?.id);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form>
              <div className="popup-form">
                <Row gutter={10}>
                  <Col sm={12}>
                    <div className="form-group">
                      <label htmlFor="label" className="form-label">
                        Name
                      </label>
                      <Field
                        as={TextArea}
                        placeholder="Enter Label"
                        name="label"
                        id="label"
                        label="Label"
                        className="antDInput"
                      />
                      <ErrorMessage
                        name="label"
                        component="div"
                        className="error"
                      />
                    </div>
                  </Col>
                  <Col sm={12}>
                    <div className="form-group">
                      <label className="form-label">Shape</label>
                      <Popover
                        trigger={"click"}
                        open={shapePopupOpen}
                        onOpenChange={(open) => setShapePopupOpen(open)}
                        content={() => (
                          <ul className="shapes-dropdown-list">
                            {shapes["Flow Chart"]?.map((s, i) => (
                              <li
                                onClick={() => {
                                  setFieldValue("imageSrc", s.shape_src);
                                  // setFieldValue("id", s.shape_id);
                                  setShape(s);
                                  setShapePopupOpen(false);
                                }}
                                key={i}
                              >
                                <img src={s.shape_src} className="shapeImage" />
                                {s.shape_name}
                              </li>
                            ))}
                          </ul>
                        )}
                      >
                        <div className="antDInput box-container shape-dropdown">
                          <img src={shape?.shape_src} className="shapeImage" />
                          {shape?.shape_name}
                        </div>
                      </Popover>
                    </div>
                  </Col>
                </Row>
                <div className="form-group">
                  <div style={{ marginBottom: 10, textAlign: "end" }}>
                    {listening ? "Listening" : "Click To Speak"}
                    <button
                      type="button"
                      onClick={
                        listening
                          ? SpeechRecognition.stopListening
                          : startListening
                      }
                      className={
                        listening ? "audio-btn listening" : "audio-btn"
                      }
                    >
                      <AudioOutlined />
                    </button>
                  </div>
                  <p
                    style={{
                      position: "absolute",
                      opacity: 0,
                      zIndex: "-1",
                      pointerEvents: "none",
                    }}
                  >
                    {transcript}
                  </p>

                  <Editor
                    // generated API key from tiny.cloud using indexsoftwaresolutions gmail id: https://www.tiny.cloud/my-account/domains/
                    apiKey="q42wysckqk206x1usbt91uvm6fnvus1i43x2h0p145ot282e"
                    // onInit={(evt, editor) => (editorRef.current = editor)}
                    value={values.description}
                    onEditorChange={(content) =>
                      setFieldValue("description", content)
                    }
                    init={{
                      height: 200,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      branding: false,
                    }}
                  />
                </div>
                <section className={`dropzone`}>
                  <label
                    onDragOver={handleDragOver}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDrop={(e) => handleDrop(e, setFieldValue)}
                  >
                    <input
                      type="file"
                      onChange={(e) => addImage(e, setFieldValue)}
                      accept="image/*"
                      style={{ display: "none" }}
                    />
                    <p className={dragging ? "dragging" : ""}>
                      Drag 'n' drop some images here, or click to select images
                      {values?.images?.length ? (
                        <div>
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {values?.images.map((img, key) => (
                              <a href={img?.Location} target="_blank">
                                <img
                                  src={img?.Location}
                                  alt="Shape Image"
                                  key={key}
                                />
                              </a>
                            ))}
                          </div>
                        </div>
                      ) : null}
                    </p>
                  </label>
                </section>

                <label className="form-group filePicker">
                  <div htmlFor="attachment" className="form-label">
                    Click here to upload pdf file as attachment
                    <br />
                    {values?.pdf_file ? (
                      <a href={values?.pdf_file?.Location} target="_blank">
                        Preview Attacement
                      </a>
                    ) : null}
                  </div>
                  <input
                    type="file"
                    accept="*/image,application/pdf"
                    id="attachment"
                    onChange={(e) => addFile(e, setFieldValue)}
                  />
                </label>
              </div>

              <div className="popup-footer">
                <Button
                  type="default"
                  htmlType="reset"
                  style={{
                    marginRight: 10,
                    backgroundColor: "#555",
                    color: "#fff",
                    borderColor: "#555",
                  }}
                  onClick={() => !isSubmitting && !progress && closePopup()}
                >
                  Cancel Changes
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={isSubmitting || progress}
                  style={{
                    backgroundColor: "#5804d1",
                  }}
                >
                  Save Changes
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default memo(SvgPopup);
