import React from "react";
import { googleClientId } from "../../../config";
import { LoginSocialGoogle } from "reactjs-social-login";
import { GoogleOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { logout, socialLogin } from "../../../redux/actions/authentication";
import "./index.scss";

function GoogleLogin() {
  //   let redirectUrl =
  //     !process.env.NODE_ENV || process.env.NODE_ENV === "development"
  //       ? "http://localhost:3000/"
  //       : "https://workflowsim.com/";

  const dispatch = useDispatch();

  const handleRedirect = async (provider, data) => {
    // console.log("google data: ", data);
    if (provider) {
      if (data) {
        dispatch(
          socialLogin(data.sub, data.email, data.name, data.picture, provider)
        );
      }
    }
  };

  const logOut = () => {
    dispatch(logout());
    window.localStorage.clear();
  };

  return (
    <LoginSocialGoogle
      client_id={googleClientId}
      onLogoutSuccess={logOut}
      onResolve={({ provider, data }) => handleRedirect(provider, data)}
      scope="openid profile email"
      onReject={(err) => {
        console.log(err);
      }}
    >
      <span className="google-login social-login">
        <GoogleOutlined style={{ fontSize: 22 }} />
      </span>
    </LoginSocialGoogle>
  );
}

export default GoogleLogin;
