import {
  ADD_UPDATE_COMMENT_FAILURE,
  ADD_UPDATE_COMMENT_SUCCESS,
  GET_COMMENT_DETAILS_FAILURE,
  GET_COMMENT_DETAILS_SUCCESS,
  SET_ACTIVE_COMMENT_SUCCESS,
  SET_OPEN_COMMENT,
} from "../constants/comment";
import commentService from "../services/comment";
import { logout } from "./authentication";

export const setActiveComment = (payload) => (dispatch) => {
  return dispatch({
    type: SET_ACTIVE_COMMENT_SUCCESS,
    payload,
  });
};

export const addUpdateComment = (data) => async (dispatch) => {
  try {
    let payload = await commentService.addUpdateComment(data);
    return dispatch({
      type: ADD_UPDATE_COMMENT_SUCCESS,
      payload,
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return dispatch(logout());
    }
    return dispatch({
      type: ADD_UPDATE_COMMENT_FAILURE,
      payload: error,
    });
  }
};

export const getComments = (pageId) => async (dispatch) => {
  try {
    let payload = await commentService.getComments(pageId);
    return dispatch({
      type: GET_COMMENT_DETAILS_SUCCESS,
      payload,
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return dispatch(logout());
    }
    dispatch({
      type: GET_COMMENT_DETAILS_FAILURE,
    });

    return Promise.reject();
  }
};

export const openComment = (payload) => (dispatch) => {
  return dispatch({
    type: SET_OPEN_COMMENT,
    payload,
  });
};
