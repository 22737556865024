import style from "./style.module.scss";

const WhiteboardLeftSidebar = ({ penColor, setPenColor, setMode, setSize }) => {
  const changeMode = (mode, size) => {
    setMode(mode);
    setSize(size);
  };

  return (
    <div className={style.whiteboardIcons}>
      <div className="whiteboard-btn-group">
        {/* <button className="btn btn-block" onClick={() => changeMode('sticky-note')}>
                        <i className='icon-sticky-note-o'></i><br />Sticky Note
                    </button> */}
        <ul>
          {/* <li>
            <i className="icon-sticky-note-o"></i> Sticky Note
            <div className={style.whiteboardIconsHover}>
              <div className="iconsDropdownBody">
                <button
                  type="button"
                  className="bgYellow"
                  //   onClick={() => addSticky("yellow")}
                >
                  <i className="icon-sticky-note-o"></i>
                </button>
                <button
                  type="button"
                  className="bgGreen"
                  //   onClick={() => addSticky("green")}
                >
                  <i className="icon-sticky-note-o"></i>
                </button>
                <button
                  type="button"
                  className="bgPink"
                  //   onClick={() => addSticky("pink")}
                >
                  <i className="icon-sticky-note-o"></i>
                </button>
                <button
                  type="button"
                  className="bgPerple"
                  //   onClick={() => addSticky("perple")}
                >
                  <i className="icon-sticky-note-o"></i>
                </button>
                <button
                  type="button"
                  className="bgBlue"
                  //   onClick={() => addSticky("blue")}
                >
                  <i className="icon-sticky-note-o"></i>
                </button>
              </div>
            </div>
          </li> */}
          <li>
            <i className="icon-pencil"></i> Pencil
            <div className={style.whiteboardIconsHover}>
              <div className="iconsDropdownBody">
                <button type="button" onClick={() => changeMode("pencil", 1)}>
                  <img src="/005-pencil-1.png" alt="Pencil Size 1" />
                </button>
                <button type="button" onClick={() => changeMode("pencil", 5)}>
                  <img src="/001-pencil.png" alt="Pencil Size 5" />
                </button>
                <button type="button" onClick={() => changeMode("pencil", 8)}>
                  <img src="/002-pen.png" alt="Pencil Size 8" />
                </button>
                <button type="button" onClick={() => changeMode("pencil", 10)}>
                  <img src="/003-highlighter.png" alt="Pencil Size 10" />
                </button>
                <button type="button" onClick={() => changeMode("pencil", 15)}>
                  <img src="/004-paint-brush.png" alt="Pencil Size Brush" />
                </button>
              </div>
            </div>
          </li>
          <li>
            <i className="icon-eraser"></i> Eraser
            <div className={style.whiteboardIconsHover}>
              <div className="iconsDropdownBody">
                <button type="button" onClick={() => changeMode("eraser", 5)}>
                  <i className="icon-circle" style={{ fontSize: 8 }}></i>
                </button>
                <button type="button" onClick={() => changeMode("eraser", 10)}>
                  <i className="icon-circle" style={{ fontSize: 10 }}></i>
                </button>
                <button type="button" onClick={() => changeMode("eraser", 15)}>
                  <i className="icon-circle" style={{ fontSize: 12 }}></i>
                </button>
                <button type="button" onClick={() => changeMode("eraser", 20)}>
                  <i className="icon-circle" style={{ fontSize: 14 }}></i>
                </button>
                <button type="button" onClick={() => changeMode("eraser", 25)}>
                  <i className="icon-circle" style={{ fontSize: 16 }}></i>
                </button>
              </div>
            </div>
          </li>
          <li>
            <i className="icon-square-o"></i> Shapes
            <div className={style.whiteboardIconsHover}>
              <div className="iconsDropdownBody">
                <button type="button" onClick={() => changeMode("rectangle")}>
                  <i className="icon-square-o"></i>
                </button>
                <button type="button" onClick={() => changeMode("circle")}>
                  <i className="icon-circle-o"></i>
                </button>
              </div>
            </div>
          </li>
        </ul>
        {/* <button className="btn btn-block" onClick={() => changeMode('eraser')}>
                        <i className='icon-eraser'></i> Eraser
                    </button> */}
        {/* <button className="btn btn-block" onClick={() => changeMode('rectangle')}>
                        <i className='icon-square-o'></i> Rectangle
                    </button>
                    <button className="btn btn-block" onClick={() => changeMode('circle')}>
                        <i className='icon-circle-o'></i> Circle
                    </button> */}
      </div>
      <div style={{ marginTop: 7 }}>
        <input
          type="color"
          value={penColor}
          onChange={(e) => setPenColor(e.target.value)}
          style={{ width: "100%", height: 40 }}
        />
      </div>
    </div>
  );
};

export default WhiteboardLeftSidebar;
