import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addUpdateWorkflows,
  getWorkflowDetails,
  setActiveNodes,
  setCurrentWorkflow,
  workflowStatusUpdate,
} from "../../../../../redux/actions/workflow";
import { BiPlusMedical } from "react-icons/bi";
import { BsFillPlayFill } from "react-icons/bs";
import { FiMoreVertical } from "react-icons/fi";
import { Dropdown, Menu } from "antd";
import styles from "./style.module.scss";

const Workflow = ({ closeContainer }) => {
  const chart = useSelector((state) => state?.flowchart?.chartDetails);
  const workflows = useSelector((state) => state?.workflow?.workflows);
  const dispatch = useDispatch();
  const [moreDetails, setMoreDetails] = useState(null);

  const handlePlayPause = async (workflow) => {
    closeContainer();
    dispatch(setCurrentWorkflow(workflow));
  };

  const deleteWorkflow = async (workflow) => {
    workflow.is_active = false;
    dispatch(addUpdateWorkflows(workflow));
  };

  useEffect(() => {
    dispatch(getWorkflowDetails(chart?.pages_id));
  }, [chart?.pages_id, dispatch]);

  const menu = (workflow) => {
    return (
      <Menu>
        <Menu.Item>Edit</Menu.Item>
        <Menu.Item onClick={() => deleteWorkflow(workflow)}>Delete</Menu.Item>
      </Menu>
    );
  };

  return (
    <div
      className="flex flex-col gap-2 h-full p-3"
      style={{
        gap: 10,
        justifyContent: "space-between",
        padding: 10,
      }}
    >
      <div className="grid">
        <button
          className="shape-button btn rounded-md h-10"
          onClick={() => {
            dispatch(setActiveNodes(0));
            dispatch(workflowStatusUpdate(true));
          }}
        >
          <BiPlusMedical /> Add Workflow
        </button>
      </div>

      <div
        className={`grow h-full`}
        style={{
          overflow: "auto",
        }}
      >
        {workflows?.length > 0 &&
          workflows?.map((workflow, id) => {
            return (
              <div className={styles.workflowCard} key={id} hoverable>
                <div>
                  <div className={styles.workflowItem}>
                    <div className="grow">
                      <div className="workflow-title">
                        {workflow?.workflow_name?.replace(/(.{20})..+/, "$1…")}
                      </div>
                    </div>
                    <div>
                      <button
                        className="play-btn"
                        onClick={() => handlePlayPause(workflow)}
                      >
                        <BsFillPlayFill className="play-icon" />
                      </button>
                    </div>
                    <div>
                      <Dropdown
                        overlay={menu(workflow)}
                        trigger={["click"]}
                        placement="bottom"
                      >
                        <button className="more-btn">
                          <FiMoreVertical className="more-icon" />
                        </button>
                      </Dropdown>
                    </div>
                  </div>

                  {workflow?.workflow_description?.trim()?.length ? (
                    <div className="workflow-desc">
                      {moreDetails !== workflow?.workflow_id
                        ? workflow?.workflow_description?.replace(
                            /(.{80})..+/,
                            "$1…"
                          )
                        : workflow?.workflow_description}
                    </div>
                  ) : null}

                  {workflow?.workflow_description?.length > 80 ? (
                    <div
                      style={{
                        textAlign: "right",
                      }}
                    >
                      <button
                        type="button"
                        className={styles.more_btn}
                        onClick={() =>
                          setMoreDetails((value) =>
                            value === workflow?.workflow_id
                              ? null
                              : workflow?.workflow_id
                          )
                        }
                      >
                        View{" "}
                        {moreDetails !== workflow?.workflow_id
                          ? "More"
                          : "Less"}
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Workflow;
