import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useReactFlow } from "reactflow";
import { addUpdateChart, updateChartImage } from "../actions/flowchart";
import { toJpeg } from "html-to-image";

export default function useFlowchart() {
  const chart = useSelector((state) => state?.flowchart?.chartDetails);
  const reactFlowInstance = useReactFlow();
  const dispatch = useDispatch();

  // let user = JSON.parse(window.localStorage.getItem("user"));

  const [chartImage, setChartImage] = useState(null);

  const saveChartImage = async () => {
    if (chartImage) {
      dispatch(
        updateChartImage({
          chart_id: chart?.chart_id,
          image: chartImage,
          created_user_id: chart?.created_user_id,
        })
      );
    }
  };

  useEffect(() => {
    if (chartImage) saveChartImage();
  }, [chartImage]);

  const createImage = async () => {
    let image = await toJpeg(document.querySelector(".react-flow"), {
      filter: (node) => {
        // we don't want to add the minimap and the controls to the image
        if (
          node?.classList?.contains("react-flow__minimap") ||
          node?.classList?.contains("react-flow__controls") ||
          node?.classList?.contains("react-flow__panel") ||
          node?.classList?.contains("react-flow__background")
        ) {
          return false;
        }

        return true;
      },
      canvasWidth: 150,
      canvasHeight: 100,
      quality: 0.6,
      backgroundColor: "#ebebeb",
    });

    setChartImage(image);

    return image;
  };

  const addUpdate = useCallback(() => {
    if (chart?.access_type === "EDIT" && reactFlowInstance) {
      let newChart = { ...chart },
        reactflow = reactFlowInstance?.toObject();

      if (reactflow?.nodes?.length) {
        newChart.chart = {
          nodes: reactflow?.nodes?.map(
            ({ id, data, width, height, position, type, style }) => ({
              id,
              data,
              width,
              height,
              position,
              style,
              type,
              selected: false,
            })
          ),
          edges: reactflow?.edges?.map((edgs) => ({
            ...edgs,
            selected: false,
          })),
          viewport: reactflow?.viewport,
        };

        // nodesChanged(newChart.chart);

        dispatch(addUpdateChart(newChart)).then(() => {
          createImage();
        });
      }
    }
  }, [reactFlowInstance]);

  return {
    addUpdate,
  };
}
