import React, { useState } from "react";
import "./index.scss";
import {
  Row,
  Col,
  Dropdown,
  Space,
  Avatar,
  Typography,
  message,
  Popover,
  Tooltip,
} from "antd";
import {
  CaretDownOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { BlockPicker } from "react-color";
import {
  addRedoItems,
  addUndoItems,
  addUpdateChart,
  redoItemsDone,
  undoItemsDone,
} from "../../../../redux/actions/flowchart";
import { IoArrowUndo, IoArrowRedo } from "react-icons/io5";
import {
  AiOutlineBgColors,
  AiFillEdit,
  AiOutlineBold,
  AiOutlineItalic,
  AiOutlineStrikethrough,
  AiOutlineUnderline,
  AiOutlineOrderedList,
  AiOutlineUnorderedList,
  AiOutlineAlignRight,
  AiOutlineAlignCenter,
  AiOutlineAlignLeft,
} from "react-icons/ai";
import { toJpeg, toPng } from "html-to-image";
import jsPDF from "jspdf";
import { getNumbersFromGivenRange } from "../../../../shared/functions";
import {
  setArrowType,
  setBgColor,
  setBorderColor,
  setBorderWidth,
  setBorderstyle,
} from "../../../../redux/actions/node";
import SharePopup from "./SharePopup";
import { useOnSelectionChange, useReactFlow } from "reactflow";
import CustomButton from "../../../../shared/Components/Button";
import { logout } from "../../../../redux/actions/authentication";
import styles from "./styles.module.scss";
import { getCharts } from "../../../../redux/actions/dashboard";
import useFlowchart from "../../../../redux/utils/useFlowchart";
import Notification from "../../../Notification";
const { Text } = Typography;

function CanvasHeader({ setRun }) {
  const { addUpdate } = useFlowchart();
  const chart = useSelector((state) => state?.flowchart?.chartDetails);
  const addUpdateFailed = useSelector(
    (state) => state?.flowchart?.addUpdateFailed
  );

  let user = JSON.parse(window.localStorage.getItem("user"));
  // const reactflow = useReactFlow();
  const reactflowInstance = useReactFlow();
  const [nodesSelected, setNodesSelected] = useState(false);
  const [edgesSelected, setEdgesSelected] = useState(false);
  const [textSelected, setTextSelected] = useState(false);

  useOnSelectionChange({
    onChange: ({ nodes, edges }) => {
      if (nodes.length === 1 && nodes[0]?.type === "SvgText") {
        setTextSelected(true);
        setNodesSelected(false);
        setEdgesSelected(false);
      } else {
        setTextSelected(false);
        setNodesSelected(nodes.length > 0);
        setEdgesSelected(edges.length > 0);
      }
    },
  });

  const dispatch = useDispatch();

  const navigateTo = useNavigate();

  const [chartName, setChartName] = useState(chart?.chart_name || "");
  const [showColorPicker, setShowColorPicker] = useState(null);

  const [sharePopupOpen, setSharePopupOpen] = useState(false);

  const strokeDasharray = [
    "0,0",
    "5,5",
    "5,7,15,7,15,7",
    "23,15",
    "5,8,2,8,15,8,2,8",
    "15,9,2,9,2,9",
    "10,8,25,8",
    "2,5",
    "2,15",
    "2,8,15,8,15,8",
    "5,20",
  ];

  const arrowTypes = [
    "arrow",
    "squareArrow",
    "squareFillArrow",
    "circleArrow",
    "circleFillArrow",
  ];

  const [borderStyleOpen, setBorderStyleOpen] = useState(false);
  const [arrowTypeOpen, setArrowTypeOpen] = useState(false);

  const fillColor = useSelector((state) => state?.node?.fillColor);
  // const lineColor = useSelector((state) => state?.node?.lineColor);
  const borderWidth = useSelector((state) => state?.node?.borderWidth);
  const borderStyle = useSelector((state) => state?.node?.borderStyle);
  const arrowType = useSelector((state) => state?.node?.arrowType);

  const undoItems = useSelector((state) => state?.flowchart?.undoItems);
  const redoItems = useSelector((state) => state?.flowchart?.redoItems);

  const saving = useSelector((state) => state?.flowchart?.saving);
  const copying = useSelector((state) => state?.flowchart?.copying);

  const setFillColor = (color) => {
    // socket.emit("fillColor", color);
    dispatch(setBgColor(color));
  };

  const setLineColor = (color) => {
    // socket.emit("borderColor", color);
    dispatch(setBorderColor(color));
  };

  const handleBorderStyle = (style) => {
    // socket.emit("borderStyle", style);
    dispatch(setBorderstyle(style));
    setBorderStyleOpen(false);
  };

  const handleArrowType = (type) => {
    // socket.emit("arrowType", type);
    dispatch(setArrowType(type));
    setArrowTypeOpen(false);
  };

  const logOut = () => {
    dispatch(logout());
  };

  const exportPDF = (event) => {
    event.preventDefault();

    reactflowInstance.fitView();

    toPng(document.querySelector(".react-flow"), {
      // width: 210 * 3,
      // height: 297 * 3,
      filter: (node) => {
        // we don't want to add the minimap and the controls to the image
        if (
          node?.classList?.contains("react-flow__minimap") ||
          node?.classList?.contains("react-flow__controls") ||
          node?.classList?.contains("react-flow__panel") ||
          node?.classList?.contains("react-flow__background")
        ) {
          return false;
        }

        return true;
      },
    }).then(downloadPDF);
  };

  const downloadPDF = (imgData) => {
    let fileName = `${chart?.chart_name || "Blank_Diagram"}_${
      chart?.page_name || "Page_1"
    }.pdf`;

    fileName = fileName.replace(/\s+/g, "_");

    const doc = new jsPDF({
      format: "a4",
      unit: "mm",
      orientation: "landscape",
    });
    doc.addImage(imgData, "PNG", 0, 0, 297, 210);
    doc.save(fileName);
  };

  const exportImage = (event) => {
    event.preventDefault();

    toPng(document.querySelector(".react-flow"), {
      filter: (node) => {
        // we don't want to add the minimap and the controls to the image
        if (
          node?.classList?.contains("react-flow__minimap") ||
          node?.classList?.contains("react-flow__controls") ||
          node?.classList?.contains("react-flow__panel") ||
          node?.classList?.contains("react-flow__background")
        ) {
          return false;
        }

        return true;
      },
    }).then(downloadImage);
  };

  const downloadImage = (dataUrl) => {
    const a = document.createElement("a");

    a.setAttribute("download", "reactflow.png");
    a.setAttribute("href", dataUrl);
    a.click();
  };

  const saveAsTemplate = async (event) => {
    event.preventDefault();

    reactflowInstance.fitView();

    let image = await toJpeg(document.querySelector(".react-flow"), {
      filter: (node) => {
        // we don't want to add the minimap and the controls to the image
        if (
          node?.classList?.contains("react-flow__minimap") ||
          node?.classList?.contains("react-flow__controls") ||
          node?.classList?.contains("react-flow__panel") ||
          node?.classList?.contains("react-flow__background")
        ) {
          return false;
        }

        return true;
      },
      canvasWidth: 150,
      canvasHeight: 100,
      quality: 0.6,
      backgroundColor: "#ebebeb",
    });

    let newChart = { ...chart };
    newChart.image = image;
    newChart.is_template = true;
    dispatch(addUpdateChart(newChart)).then(() => dispatch(getCharts()));

    message.success("Success! Chart has been marked as shape group.");
  };

  const createNewFlowchart = (event) => {
    event.preventDefault();
    dispatch(
      addUpdateChart({
        chart_id: null,
        product: "FC",
        is_template: false,
        is_starred: false,
        pages_id: null,
        chart: {},
        page_name: "Page 1",
        image: null,
      })
    ).then((chartUpdated) => {
      navigateTo("/canvas/" + chartUpdated?.payload?.data?.chart_id);
    });
  };

  const updateChartName = (newChartName) => {
    setChartName(newChartName);

    let newChart = { ...chart };
    newChart.chart_name = newChartName;

    dispatch(addUpdateChart(newChart));
  };

  const textEdit = (command, value = null) => {
    // dispatch(setTextEditor(command, params));
    document.execCommand(command, false, value);
  };

  const unDoChanges = () => {
    if (undoItems?.length) {
      let { nodes, edges } = undoItems[undoItems.length - 1];

      reactflowInstance.setNodes(nodes);
      reactflowInstance.setEdges(edges);

      let newNodes = reactflowInstance.getNodes(),
        newEdges = reactflowInstance.getEdges();

      dispatch(addRedoItems({ nodes: newNodes, edges: newEdges }));

      setTimeout(() => {
        dispatch(undoItemsDone());
      }, 100);
    }
  };

  const reDoChanges = () => {
    if (redoItems?.length) {
      let { nodes, edges } = redoItems[redoItems.length - 1];

      reactflowInstance.setNodes(nodes);
      reactflowInstance.setEdges(edges);

      let newNodes = reactflowInstance.getNodes(),
        newEdges = reactflowInstance.getEdges();

      dispatch(addUndoItems({ nodes: newNodes, edges: newEdges }));

      setTimeout(() => {
        dispatch(redoItemsDone());
      }, 100);
    }
  };

  let borderWidthRange = getNumbersFromGivenRange(15);

  const items = [
    {
      label: (
        <Row>
          <Col span={24}>
            <Text strong style={{ color: "#843dff" }}>
              Hi, {user?.name}
            </Text>
          </Col>
          <Col span={24}>
            <Text italic>{user?.email}</Text>
          </Col>
        </Row>
      ),
      key: "user",
    },
    {
      type: "divider",
    },
    {
      label: <a href="#">Account Settings</a>,
      key: "accSetting",
    },
    {
      type: "divider",
    },
    {
      label: (
        <button type="button" className="logoutBtn" onClick={logOut}>
          Logout
        </button>
      ),
      key: "logout",
    },
  ];

  return (
    <header
      style={{
        backgroundColor: "#d9d9d9",
        maxHeight: "125px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          paddingBlock: 10,
          paddingInline: 35,
          borderBottom: "1px solid #ccc",
        }}
      >
        <Link to={"/"} className="header-logo">
          <img src="/logo.png" alt="" />
        </Link>
        <div style={{ marginLeft: 10 }}>
          <div>
            <input
              value={chartName}
              placeholder={chartName || "Blank Diagram"}
              style={{
                backgroundColor: "transparent",
                outline: 0,
                boxShadow: 0,
                border: 0,
                paddingBlock: 3,
                height: "auto",
                color: "#4b05ad",
                fontWeight: "bold",
                fontSize: 15,
              }}
              onInput={(e) => updateChartName(e.target.value)}
            />
          </div>
          <ul className="menuBar">
            <li>
              <a href="javascript:;" style={{ color: "#5a03d5" }}>
                File <CaretDownOutlined style={{ color: "#5a03d5" }} />
              </a>
              <ul>
                <li>
                  <button
                    type="button"
                    className="btn-link"
                    onClick={createNewFlowchart}
                  >
                    Create New Flowchart
                  </button>
                </li>
                <li>
                  {/* <Link to={"/"}>Save as Template</Link> */}
                  <button
                    type="button"
                    className="btn-link"
                    onClick={saveAsTemplate}
                  >
                    Save as Shape Group
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="btn-link"
                    onClick={exportImage}
                  >
                    Download as Image
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="btn-link"
                    onClick={exportPDF}
                  >
                    Export as PDF
                  </button>
                </li>
              </ul>
            </li>
            <li>
              <a href="javascript:;" style={{ color: "#5a03d5" }}>
                Insert
              </a>
            </li>
            <li>
              <button
                type="button"
                className="btn-link"
                style={{ color: "#5a03d5" }}
                onClick={() => setRun(true)}
              >
                Help
              </button>
            </li>
            <li style={{ color: "#5a03d5" }}>
              {saving ? (
                <div className="warningText">
                  <ClockCircleOutlined /> Saving
                </div>
              ) : (
                <div>
                  <CheckCircleOutlined style={{ color: "#5a03d5" }} /> Saved
                </div>
              )}
            </li>
            {addUpdateFailed ? (
              <li>
                <CustomButton
                  isPrimary={true}
                  buttonText={"Save Chart"}
                  onClick={() => addUpdate()}
                />
              </li>
            ) : null}
            {copying ? (
              <li style={{ color: "#5a03d5" }}>
                <div className="warningText">
                  <ClockCircleOutlined /> Image Copying
                </div>
              </li>
            ) : null}
          </ul>
        </div>

        <div
          style={{
            marginLeft: "auto",
            marginRight: 30,
            alignSelf: "center",
          }}
        >
          <Notification />
        </div>

        <div
          style={{
            // marginLeft: "auto",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className="mr-6 ShareBtnContainer">
            <CustomButton
              isPrimary
              buttonText={"Share"}
              hasIcon={
                <ShareAltOutlined
                  style={{ fontWeight: 700, marginTop: "-30px" }}
                />
              }
              onClick={() => setSharePopupOpen(true)}
              customStyle={{ paddingLeft: "12px", paddingRight: "12px" }}
              buttonClaa
              disabled={chart?.created_user_id !== parseInt(user?.id)}
            />
          </div>
          <Dropdown
            menu={{
              items,
            }}
            trigger={["click"]}
            placement="bottom"
          >
            <a href="/" className="d-block" onClick={(e) => e.preventDefault()}>
              <Space>
                <Avatar
                  style={{
                    backgroundColor: "#5a03d5",
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                >
                  {user?.name[0].toUpperCase()}
                </Avatar>
                <span className="username-text">{user?.name}</span>
                <CaretDownOutlined style={{ color: "#5a03d5" }} />
              </Space>
            </a>
          </Dropdown>
        </div>
      </div>
      <div className="canvas-menu">
        {textSelected ? (
          <ul className={styles.menuBlock}>
            <li>
              <Tooltip title="Bold">
                <button
                  type="button"
                  className={`left-btns`}
                  onClick={() => textEdit("bold")}
                >
                  <AiOutlineBold />
                </button>
              </Tooltip>
              <Tooltip title="Italic">
                <button
                  type="button"
                  className={`left-btns`}
                  onClick={() => textEdit("italic")}
                >
                  <AiOutlineItalic />
                </button>
              </Tooltip>
              <Tooltip title="Strike Through">
                <button
                  type="button"
                  className={`left-btns`}
                  onClick={() => textEdit("strikeThrough")}
                >
                  <AiOutlineStrikethrough />
                </button>
              </Tooltip>
              <Tooltip title="Underline">
                <button
                  type="button"
                  className={`left-btns`}
                  onClick={() => textEdit("underline")}
                >
                  <AiOutlineUnderline />
                </button>
              </Tooltip>
            </li>
            <li>
              <Tooltip title="Align Left">
                <button
                  type="button"
                  className={`left-btns`}
                  onClick={() => textEdit("justifyLeft")}
                >
                  <AiOutlineAlignLeft />
                </button>
              </Tooltip>
              <Tooltip title="Align Center">
                <button
                  type="button"
                  className={`left-btns`}
                  onClick={() => textEdit("justifyCenter")}
                >
                  <AiOutlineAlignCenter />
                </button>
              </Tooltip>
              <Tooltip title="Align Right">
                <button
                  type="button"
                  className={`left-btns`}
                  onClick={() => textEdit("justifyRight")}
                >
                  <AiOutlineAlignRight />
                </button>
              </Tooltip>
            </li>
            <li>
              <Tooltip title="Unordered List">
                <button
                  type="button"
                  className={`left-btns`}
                  onClick={() => textEdit("insertunorderedlist")}
                >
                  <AiOutlineUnorderedList />
                </button>
              </Tooltip>
              <Tooltip title="Ordered List">
                <button
                  type="button"
                  className={`left-btns`}
                  onClick={() => textEdit("insertorderedlist")}
                >
                  <AiOutlineOrderedList />
                </button>
              </Tooltip>
            </li>
            <li>
              <Tooltip title="Font Size">
                <select onChange={(e) => textEdit("fontSize", e.target.value)}>
                  {[
                    8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48,
                    72,
                  ].map((num, index) => (
                    <option value={num} key={index}>
                      {num}pt
                    </option>
                  ))}
                </select>
              </Tooltip>
            </li>
          </ul>
        ) : (
          <ul className="menu-block">
            <li>
              <div className="undoRedo-container">
                <Tooltip title="Undo">
                  <button
                    type="button"
                    className={`left-btns${
                      !undoItems?.length ? " disabled" : ""
                    }`}
                    onClick={() => unDoChanges()}
                  >
                    <IoArrowUndo />
                  </button>
                </Tooltip>
                <Tooltip title="Redo">
                  <button
                    type="button"
                    className={`left-btns${
                      !redoItems?.length ? " disabled" : ""
                    }`}
                    onClick={() => reDoChanges()}
                  >
                    <IoArrowRedo />
                  </button>
                </Tooltip>
              </div>
            </li>
            <li>
              <div className="color-container">
                <Popover
                  trigger={"click"}
                  open={showColorPicker === "fill"}
                  onOpenChange={() => {
                    if (nodesSelected) {
                      showColorPicker === "fill"
                        ? setShowColorPicker(null)
                        : setShowColorPicker("fill");
                    }
                  }}
                  content={() => (
                    <div className="colorPickerPopup">
                      <BlockPicker
                        color={fillColor || "#fff"}
                        onChangeComplete={(color) => {
                          setFillColor(color.hex);
                          setShowColorPicker(null);
                        }}
                      />
                    </div>
                  )}
                >
                  <Tooltip title="Background Color">
                    <button
                      type="button"
                      className="right-btns"
                      disabled={!nodesSelected}
                      style={{ borderRight: "1px solid #e9e9e9" }}
                    >
                      <AiOutlineBgColors style={{ fontSize: 20 }} />
                    </button>
                  </Tooltip>
                </Popover>

                <Popover
                  trigger={"click"}
                  open={showColorPicker === "border"}
                  onOpenChange={() => {
                    if (nodesSelected) {
                      showColorPicker === "border"
                        ? setShowColorPicker(null)
                        : setShowColorPicker("border");
                    }
                  }}
                  content={() => (
                    <div className="colorPickerPopup">
                      <BlockPicker
                        color={fillColor || "#000"}
                        onChangeComplete={(color) => {
                          setLineColor(color.hex);
                          setShowColorPicker(null);
                        }}
                      />
                    </div>
                  )}
                >
                  <Tooltip title="Border Color">
                    <button
                      type="button"
                      className="right-btns"
                      disabled={!nodesSelected}
                    >
                      <AiFillEdit style={{ fontSize: 19 }} />
                    </button>
                  </Tooltip>
                </Popover>
              </div>
            </li>

            <li className="borderOptions-container">
              {/* Border style option */}
              <Popover
                trigger={"click"}
                open={borderStyleOpen}
                onOpenChange={(open) =>
                  nodesSelected ? setBorderStyleOpen(open) : null
                }
                content={() => (
                  <div className="custom-selectbox-dropdown">
                    {strokeDasharray.map((sdArr, i) => (
                      <div
                        className="svg-line-box"
                        key={i}
                        onClick={() => handleBorderStyle(sdArr)}
                      >
                        <svg viewBox="0 0 64 32" width="80" height="24">
                          <path
                            d="M1 16 L63 16"
                            stroke="#000"
                            strokeWidth="2"
                            strokeDasharray={sdArr}
                          ></path>
                        </svg>
                      </div>
                    ))}
                  </div>
                )}
              >
                <div
                  className={`box-container${
                    !nodesSelected ? " disabled" : ""
                  }`}
                >
                  <Tooltip title="Border Style">
                    <svg viewBox="0 0 64 32" width="80" height="24">
                      <path
                        d="M1 16 L63 16"
                        stroke="#000"
                        strokeWidth="2"
                        strokeDasharray={borderStyle || strokeDasharray[0]}
                      ></path>
                    </svg>
                  </Tooltip>
                </div>
              </Popover>

              {/* Arrow style option */}
              <Popover
                trigger={"click"}
                open={arrowTypeOpen}
                onOpenChange={(open) =>
                  edgesSelected ? setArrowTypeOpen(open) : null
                }
                content={() => (
                  <div className="custom-selectbox-dropdown">
                    {arrowTypes?.map((marker, i) => (
                      <div
                        className="svg-line-box"
                        key={i}
                        onClick={() => handleArrowType(marker)}
                      >
                        <svg viewBox="0 0 64 32" width="80" height="24">
                          <path
                            d="M1 16 L63 16"
                            stroke="#000"
                            strokeWidth="2"
                            markerEnd={`url(#${marker})`}
                          ></path>
                        </svg>
                      </div>
                    ))}
                  </div>
                )}
              >
                <div
                  className={`box-container${
                    !edgesSelected ? " disabled" : ""
                  }`}
                >
                  <Tooltip title="Arrow Style">
                    <svg viewBox="0 0 64 32" width="80" height="24">
                      <path
                        d="M1 16 L63 16"
                        stroke="#000"
                        strokeWidth="2"
                        markerEnd={`url(#${arrowType || "arrow"})`}
                      ></path>
                    </svg>
                  </Tooltip>
                </div>
              </Popover>

              {/* Border width range */}
              <Tooltip title="Border Width">
                <select
                  onChange={(e) => dispatch(setBorderWidth(e.target.value))}
                  value={borderWidth ?? borderWidthRange[0]}
                  disabled={!nodesSelected}
                >
                  {borderWidthRange?.map((val, i) => {
                    return (
                      <option key={i} value={val}>
                        {val + " px"}
                      </option>
                    );
                  })}
                </select>
              </Tooltip>
            </li>
          </ul>
        )}
      </div>
      {/* Markers Svg Start */}
      <svg
        viewBox="0 0 842 595"
        style={{ opacity: 0, position: "absolute", zIndex: "-1", height: 0 }}
      >
        <marker
          id="arrow"
          markerUnits="strokeWidth"
          viewBox="0 0 12 12"
          refX="9"
          refY="5"
          markerWidth="8"
          markerHeight="8"
          orient="auto"
        >
          <path d="M 0 0 L 10 5 L 0 10 z" fill="#000"></path>
        </marker>
        <marker
          id="squareArrow"
          markerUnits="strokeWidth"
          viewBox="0 0 12 12"
          refX="9"
          refY="5"
          markerWidth="8"
          markerHeight="8"
          orient="auto"
        >
          <rect
            x="1"
            y="1"
            width="10"
            height="10"
            fill="white"
            stroke="#000"
            strokeWidth="1"
          ></rect>
        </marker>
        <marker
          id="squareFillArrow"
          markerUnits="strokeWidth"
          viewBox="0 0 12 12"
          refX="9"
          refY="5"
          markerWidth="8"
          markerHeight="8"
          orient="auto"
        >
          <rect
            x="1"
            y="1"
            width="10"
            height="10"
            fill="#000"
            stroke="#000"
            strokeWidth="1"
          ></rect>
        </marker>
        <marker
          id="circleArrow"
          markerUnits="strokeWidth"
          viewBox="0 0 12 12"
          refX="9"
          refY="5"
          markerWidth="8"
          markerHeight="8"
          orient="auto"
        >
          <circle
            cx="6"
            cy="6"
            r="5"
            width="10"
            height="10"
            fill="white"
            stroke="#000"
            strokeWidth="1"
          ></circle>
        </marker>
        <marker
          id="circleFillArrow"
          markerUnits="strokeWidth"
          viewBox="0 0 12 12"
          refX="9"
          refY="5"
          markerWidth="8"
          markerHeight="8"
          orient="auto"
        >
          <circle
            cx="6"
            cy="6"
            r="5"
            width="10"
            height="10"
            fill="#000"
            stroke="#000"
            strokeWidth="1"
          ></circle>
        </marker>
      </svg>
      {/* Markers Svg End */}

      {sharePopupOpen ? (
        <SharePopup setSharePopupOpen={setSharePopupOpen} />
      ) : null}
    </header>
  );
}

export default CanvasHeader;
