import React, { useState } from "react";
import styles from "./style.module.scss";
import { MdDelete } from "react-icons/md";
import { getDateStatus } from "../../../../../shared/functions";
import { Avatar } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  addUpdateComment,
  getComments,
} from "../../../../../redux/actions/comment";

const CommentBox = ({ comment }) => {
  let user = JSON.parse(window.localStorage.getItem("user"));
  const [moreDetails, setMoreDetails] = useState(null);

  const chart = useSelector((state) => state?.flowchart?.chartDetails);
  const dispatch = useDispatch();

  const deleteComment = async (comment) => {
    comment.is_active = false;

    dispatch(addUpdateComment(comment)).then(() => {
      dispatch(getComments(chart?.pages_id));
    });
  };

  return (
    <div>
      <div className={styles.commentBox}>
        <Avatar
          style={{
            backgroundColor: "#bea6ff",
            color: "#5a03d5",
            fontWeight: "bold",
          }}
          size={30}
        >
          {comment?.user_name[0].toUpperCase()}
        </Avatar>
        <div
          className={"flex flex-1 items-start comment " + styles.commentTitle}
        >
          <div className="flex-1">
            <div className="comment-title">{comment?.user_name}</div>
            {moreDetails !== comment?.comment_id ? (
              <div className="comments">
                {comment?.comment
                  ?.replace(/<\/?[^>]+(>|$)/g, "")
                  ?.replace(/(.{22})..+/, "$1…")}
              </div>
            ) : (
              <div
                className="comments"
                dangerouslySetInnerHTML={{ __html: comment?.comment }}
              ></div>
            )}
            {comment?.comment?.replace(/<\/?[^>]+(>|$)/g, "")?.length > 22 ? (
              <div
                style={{
                  textAlign: "right",
                }}
              >
                <button
                  type="button"
                  className={styles.more_btn}
                  onClick={() =>
                    setMoreDetails((value) =>
                      value === comment?.comment_id ? null : comment?.comment_id
                    )
                  }
                >
                  View {moreDetails !== comment?.comment_id ? "More" : "Less"}
                </button>
              </div>
            ) : null}
          </div>
          <div className={"flex items-center " + styles.deleteBtn}>
            <div className={styles.commentDate}>
              {getDateStatus(comment?.date_added)}
            </div>
            {parseInt(comment?.user_id) === parseInt(user?.id) ? (
              <button
                className="dlt-btn"
                onClick={() => deleteComment(comment)}
              >
                <MdDelete className="dlt-icon" />
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentBox;
