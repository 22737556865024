import { combineReducers } from "redux";
// import a from "./auth";
import dashboardReducers from "./dashboard";
import flowchartReducers from "./flowchart";
import authenticationReducer from "./authentication";
import WorkflowReducers from "./workflow";
import nodeReducers from "./node";
import CommentReducers from "./comment";
import AutodesignerReducers from "./autodesigner";

const rootReducer = combineReducers({
  auth: authenticationReducer,
  chart: dashboardReducers,
  flowchart: flowchartReducers,
  workflow: WorkflowReducers,
  node: nodeReducers,
  comment: CommentReducers,
  autodesigner: AutodesignerReducers,
});

export default rootReducer;
