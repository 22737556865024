import React, { useState } from "react";
import { Card, Row, Col, message, Avatar, Dropdown, Space } from "antd";
import { StarOutlined, StarFilled, MoreOutlined } from "@ant-design/icons";
import "./index.scss";
import {
  //   convertedDate,
  getDateStatus,
  //   renderImage,
} from "../../../shared/functions";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getCharts, starUnstarChart } from "../../../redux/actions/dashboard";
import { RiFlowChart } from "react-icons/ri";
import { BsPencilSquare } from "react-icons/bs";
import { addUpdateChart } from "../../../redux/actions/flowchart";
const { Meta } = Card;

const FlowchartCard = ({
  cData,
  imagesrc,
  title,
  productType,
  username,
  dateModified,
  id,
  isStarred,
  isTemplate = false,
  setChartOpen = null,
}) => {
  const dispatch = useDispatch();
  //   let starSuccess = useSelector((state) => state?.chart?.starredData);

  const [loading, setLoading] = useState(false);
  const [conLoading, setConLoading] = useState(false);

  const markStar = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setLoading(true);
    let payload = {
      chart_id: id,
      is_starred: isStarred ? false : true,
    };
    dispatch(starUnstarChart(payload))
      .then(() => {
        dispatch(getCharts()).then(() => {
          setLoading(false);
        });
      })
      .catch(() => {
        setLoading(false);
        message.error("Something went wrong...");
      });
  };
  const handleOptions = (e) => {
    // alert(key);
    e.domEvent.preventDefault();
    e.domEvent.stopPropagation();

    setConLoading(true);

    let { key } = e;
    switch (key) {
      case "1":
        dispatch(
          addUpdateChart({
            ...cData,
            chart_id: null,
            pages_id: null,
            is_starred: false,
          })
        ).then(() => {
          dispatch(getCharts()).then(() => {
            setConLoading(false);
          });
        });
        break;

      case "2":
        dispatch(addUpdateChart({ ...cData, is_active: false })).then(() => {
          dispatch(getCharts()).then(() => {
            setConLoading(false);
          });
        });
        break;

      default:
        console.log("Incorrect option");
    }
  };

  const description = () => {
    return (
      <>
        {conLoading ? (
          <div style={{ textAlign: "center" }}>Loading</div>
        ) : (
          <>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span className="flowName">{title}</span>
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: "1",
                        label: "Duplicate",
                      },
                      {
                        key: "2",
                        label: "Delete",
                        danger: true,
                      },
                    ],
                    onClick: handleOptions,
                  }}
                >
                  <MoreOutlined />
                </Dropdown>
              </div>
              <div>
                <span className="dateModified">
                  Edited {getDateStatus(dateModified)} ago
                </span>
              </div>
            </div>
            {username ? (
              <div>
                <Avatar
                  style={{
                    backgroundColor: "#bea6ff",
                    color: "#5a03d5",
                    fontWeight: "bold",
                  }}
                  size={18}
                >
                  {username[0].toUpperCase()}
                </Avatar>
                {username}
              </div>
            ) : null}
          </>
        )}
      </>
    );
  };
  return (
    <>
      {isTemplate ? (
        <Card
          hoverable
          cover={
            <img
              src={`${imagesrc}?v=${new Date().toISOString()}`}
              alt={title}
              style={{
                width: "100%",
                minHeight: 150,
                maxHeight: 150,
                objectFit: "contain",
                background: "#ebebeb",
              }}
            />
          }
          bodyStyle={{
            padding: 0,
          }}
          style={{
            maxHeight: 150,
            minHeight: 150,
            padding: 0,
          }}
          className="templateCard"
          actions={[<button className="template-editBtn">{title}</button>]}
          onClick={() => setChartOpen(cData)}
        ></Card>
      ) : (
        <Link to={productType === "FC" ? `/canvas/${id}` : `/whiteboard/${id}`}>
          <Card
            hoverable
            className="flowchartCard"
            cover={
              <div style={{ position: "relative" }}>
                <img
                  src={`${imagesrc}?v=${new Date().toISOString()}`}
                  alt={title}
                  style={{
                    width: "100%",
                    height: 130,
                    objectFit: "contain",
                    background: "#ebebeb",
                  }}
                />
                <button
                  className={`starBtn${isStarred ? " filled" : ""}`}
                  onClick={markStar}
                >
                  {loading ? (
                    <div className="loadingSpinner"></div>
                  ) : isStarred ? (
                    <StarFilled
                      style={{ fontSize: 20, marginTop: 8, color: "#5a03d5" }}
                    />
                  ) : (
                    <StarOutlined
                      style={{ fontSize: 20, marginTop: 8, color: "#5a03d5" }}
                    />
                  )}
                </button>
              </div>
            }
          >
            <Meta description={description()} />
          </Card>
        </Link>
      )}
    </>
  );
};

export default FlowchartCard;
