import { Button, message } from "antd";
import style from "./style.module.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addUpdateChartAccess,
  getChartAccess,
} from "../../../../../redux/actions/flowchart";

const SharePopup = ({ setSharePopupOpen }) => {
  const dispatch = useDispatch();

  const chart = useSelector((state) => state?.flowchart?.chartDetails);

  const [form, setForm] = useState({
    email: "",
    permission: "READ",
  });

  const [accessList, setAccessList] = useState({});

  const [emailError, setEmailError] = useState("");

  const updateAccess = (access, access_type) => {
    dispatch(
      addUpdateChartAccess({
        chart_access_id: access.chart_access_id,
        email: access.email,
        chart_id: chart.chart_id,
        access_type,
        is_active: true,
      })
    ).then((response) => {
      let msg = response?.payload?.msg;

      fetchShareAccess();

      message.success(msg);
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const submitShareForm = () => {
    if (!validateEmail(form.email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    dispatch(
      addUpdateChartAccess({
        chart_access_id: null,
        email: form.email.toLowerCase(),
        chart_id: chart.chart_id,
        access_type: form.permission,
        is_active: true,
      })
    ).then((response) => {
      setForm({
        email: "",
        permission: "READ",
      });

      let msg = response?.payload?.msg;

      fetchShareAccess();

      message.success(msg);
    });
  };

  const fetchShareAccess = () => {
    dispatch(getChartAccess(chart?.chart_id)).then((response) => {
      setAccessList(response?.payload);
    });
  };

  useEffect(() => {
    fetchShareAccess();
  }, []);

  return (
    <>
      <div className={style.sharePopupBg}></div>
      <div className={style.sharePopup}>
        <div className={style.sharePopupHeader}>
          <h3>Share</h3>
          <button
            type="button"
            className={style.close}
            onClick={() => setSharePopupOpen(false)}
          >
            &times;
          </button>
        </div>
        <div className={style.sharePopupBody}>
          <div className={style.inputGroup}>
            <input
              type="email"
              placeholder="Enter Email Address"
              value={form.email}
              onChange={(e) => {
                setForm((form) => ({ ...form, email: e.target.value }));
                setEmailError(""); // Clear email error on input change
              }}
            />
            <select
              onChange={(e) =>
                setForm((form) => ({ ...form, permission: e.target.value }))
              }
              >
              <option value="READ">READ</option>
              <option value="EDIT">WRITE</option>
            </select>
            <Button
              type="primary"
              style={{ backgroundColor: "#5804d1" }}
              onClick={() => submitShareForm()}
              >
              Share
            </Button>
          </div>
          {emailError && <div className={style.errorText}>{emailError}</div>} {/* Display email error message */}
          {accessList?.created_user ? (
            <ul className={style.shareUserList}>
              <li>
                <div>
                  <div>
                    <strong>{accessList?.created_user?.name}</strong>
                  </div>
                  <div>({accessList?.created_user?.email})</div>
                </div>
                <div readOnly>ADMIN</div>
              </li>
              {accessList?.shared_users?.map((access) => (
                <li key={access.email}>
                  <div>
                    <div>
                      <strong>{access?.name}</strong>
                    </div>
                    <div>({access?.email})</div>
                  </div>
                  <select
                    value={access?.access_type}
                    onChange={(e) => updateAccess(access, e.target.value)}
                  >
                    <option value="READ">READ</option>
                    <option value="EDIT">WRITE</option>
                  </select>
                </li>
              ))}
            </ul>
          ) : (
            <div>Loading</div>
          )}
        </div>
      </div>
    </>
  );
};

export default SharePopup;
