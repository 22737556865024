import React from "react";
import { LoginSocialGithub } from "reactjs-social-login";
import { GithubOutlined } from "@ant-design/icons";
import { githubClientId, githubClientSecret } from "../../../config";
import { logout, socialLogin } from "../../../redux/actions/authentication";
import { useDispatch } from "react-redux";
import "./index.scss";

function GithubLogin() {
  let redirectUrl =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "http://localhost:3000/dashboard"
      : "https://workflowsim.com/dashboard";

  const dispatch = useDispatch();

  const handleRedirect = async (provider, data) => {
    if (provider) {
      if (data) {
        dispatch(
          socialLogin(
            data.id,
            data.login,
            data.login,
            data.avatar_url,
            provider
          )
        );
      }
    }
  };

  const logOut = () => {
    dispatch(logout());
    window.localStorage.clear();
  };

  return (
    <LoginSocialGithub
      client_id={githubClientId}
      client_secret={githubClientSecret}
      redirect_uri={redirectUrl}
      onLogoutSuccess={logOut}
      onResolve={({ provider, data }) => handleRedirect(provider, data)}
      onReject={(err) => {
        console.log(err);
      }}
    >
      <span className="github-login social-login">
        <GithubOutlined style={{ fontSize: 22 }} />
      </span>
    </LoginSocialGithub>
  );
}

export default GithubLogin;
