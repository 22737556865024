import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTemplates } from "../../../redux/actions/dashboard";
// import store from '../../../redux/store/index';
import { Typography, Space, Empty, Row, Col, Card, Input } from "antd";
import "./index.scss";
import DLayout from "../../../components/Dashboard/Layout";
import FlowchartCard from "../../../components/Dashboard/FlowchartCard";
import { addUpdateChart } from "../../../redux/actions/flowchart";
import { useNavigate } from "react-router-dom";
// import { SearchOutlined } from "@ant-design/icons";
const { Title } = Typography;
const { Search } = Input;

function Templates() {
  const navigateTo = useNavigate();

  const { templates } = useSelector((state) => state?.chart);
  const [filteredTemp, setFilteredTemp] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [activePage, setActivePage] = useState("TEMPLATES");
  const [chartOpen, setChartOpen] = useState(null);
  let nullName = "Blank Diagram";
  const dispatch = useDispatch();

  useEffect(() => {
    // if (!templates) {
    dispatch(getTemplates());
    // }
  }, []);

  useEffect(() => {
    if (templates) {
      if (searchData !== "") {
        let filtered = templates?.filter((_) => {
          return (
            nullName?.toLowerCase().includes(searchData?.toLowerCase()) ||
            _?.chart_name?.toLowerCase().includes(searchData?.toLowerCase())
          );
        });
        setFilteredTemp(filtered);
      } else {
        setFilteredTemp(templates);
      }
    }
  }, [templates, searchData]);

  const onSearch = (e) => {
    setSearchData(e.target.value);
  };

  const useTemplate = () => {
    dispatch(
      addUpdateChart({ ...chartOpen, chart_id: null, pages_id: null })
    ).then((chartUpdated) => {
      navigateTo("/canvas/" + chartUpdated?.payload?.data?.chart_id);
    });
  };

  return (
    <div>
      {chartOpen ? (
        <>
          <div className="popup-bg" onClick={() => setChartOpen(null)}></div>
          <div className="popup">
            <div className="popup-body">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h3 style={{ margin: 0 }}>
                  {chartOpen?.chart_name ?? "Blank Diagram"}
                </h3>
                <button
                  type="button"
                  className="closeBtn"
                  onClick={() => setChartOpen(null)}
                >
                  &times;
                </button>
              </div>
            </div>
            <img
              src={`${chartOpen?.image}?v=${new Date().toISOString()}`}
              alt={chartOpen.chart_name}
              style={{
                width: "100%",
                background: "#ebebeb",
              }}
            />
            <button type="button" onClick={useTemplate}>
              USE TEMPLATE
            </button>
          </div>
        </>
      ) : null}
      <div className="grow h-full" style={{ overflow: "auto" }}>
        <div className="templateContent">
          <Row gutter={16}>
            <Col span={24}>
              <Card bordered={false} className="templateOptCard">
                <Row>
                  <Col span={24} className="template-header">
                    Find the best template for your project
                  </Col>
                  <Col span={24} className="searchInput">
                    <Search
                      placeholder="Search..."
                      onSearch={onSearch}
                      style={{
                        width: 550,
                      }}
                    />
                  </Col>
                  <Col span={24} className="template-content">
                    Can't find what you're looking for?{" "}
                    <button className="aigenerate-btn">
                      Generate it with Autodesigner{" "}
                      <span className="beta-tag">Beta</span>
                    </button>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Card
            bordered={false}
            className="chartCard"
            style={{ minHeight: "350px", maxHeight: "350px" }}
          >
            <Title level={3}>Templates</Title>
            {filteredTemp && filteredTemp?.length > 0 ? (
              <div className="space-align-container">
                {filteredTemp?.map((cData, index) => {
                  return (
                    <div key={index} className="space-align-block">
                      <Space align="center">
                        <FlowchartCard
                          cData={cData}
                          title={cData?.chart_name || "Blank Diagram"}
                          imagesrc={cData?.image}
                          productType={cData?.product}
                          username={cData?.name}
                          dateModified={cData?.date_modified}
                          id={cData?.chart_id}
                          isStarred={cData?.is_starred}
                          isTemplate={true}
                          setChartOpen={setChartOpen}
                        />
                      </Space>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: "75vw",
                  maxWidth: "75vw",
                }}
              >
                <Space align="center">
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </Space>
              </div>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Templates;
