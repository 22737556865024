import { Layout, Space } from "antd";
import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import HeroImage1 from "../../assets/hero-banner1.png";
import flowchartImg from "../../assets/flowchart.png";
import RahulImg from "../../assets/rahul.png";
import AnthonyImg from "../../assets/anthony.png";
import Logo from "../../assets/logo.png";
import { ImLinkedin } from "react-icons/im";
import { PiInstagramLogoFill } from "react-icons/pi";
import "./index.scss";
import CustomButton from "../../shared/Components/Button";
import { useSelector } from "react-redux";
import AiImage from "../../assets/aiImage.png";
import HeroImage2 from "../../assets/hero-banner2.png";
import HeroImage3 from "../../assets/hero-banner3.png";
import HeroImage4 from "../../assets/hero-banner4.png";
import RevealUp from "./RevealUpAnimation";
import RevealLeft from "./RevealLeftAnimation";
import RevealRight from "./RevealRightAnimation";
import TypingAnimation from "./TypingAnimation";

function HeroSection() {
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.auth);

  const handleSignupRedirect = () => {
    navigate("/register");
  };

  const handleLoginRedirect = () => {
    navigate("/dashboard");
  };

  if (isLoggedIn) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
      }}
      size={[0, 48]}
    >
      <Layout style={{ backgroundColor: "#fff" }}>
        <nav className="nav flex flex-wrap items-center justify-between px-4 max-h-[90px] bg-white w-full fixed top-0 z-50">
          <div className="flex flex-no-shrink items-center mr-6 py-3 text-grey-darkest">
            <RevealUp>
              <img
                src={Logo}
                className="h-[3rem] w-[20rem] xl:ml-5"
                alt="Workflowsim"
              />
            </RevealUp>
          </div>
          <input className="menu-btn hidden" type="checkbox" id="menu-btn" />
          <label
            className="menu-icon block cursor-pointer md:hidden px-2 py-4 relative select-none"
            htmlFor="menu-btn"
          >
            <span className="navicon bg-grey-darkest flex items-center relative"></span>
          </label>
          <ul className="menu flex justify-end list-reset m-0 w-full md:w-auto">
            <RevealUp>
              <li className="border-t md:border-none">
                <button
                  className="login-button btn xl:mb-0 rounded-md px-10 h-10 mr-3 mt-6 xl:mt-3"
                  onClick={handleLoginRedirect}
                >
                  Login
                </button>
              </li>
            </RevealUp>
            <RevealUp>
              <li className="xl:mr-5 mt-6 xl:mt-3">
                <CustomButton
                  buttonText={"Signup"}
                  onClick={handleSignupRedirect}
                />
              </li>
            </RevealUp>
          </ul>
        </nav>
        <section className="lg:h-[700px] py-12 mt-[5rem] lg:mt-0 h-auto">
          <div className="container mx-auto h-full relative">
            <div className="flex flex-col xl:flex-row items-center h-full md:py-24">
              <div className="text-center xl:text-left xl:absolute">
                <RevealRight>
                  <h1 className="h1 xl:max-w-[700px] mb-6">
                    Design stunning <br />
                    <TypingAnimation
                      dataText={["Charts", "Workflows", "Whiteboards"]}
                    />
                    <br /> in minutes
                  </h1>
                </RevealRight>
                <RevealRight>
                  <p className="lead xl:max-w-[380px] mb-6">
                    Workflow Sim makes it easy to create <br /> professional
                    flowcharts and share <br /> them
                  </p>
                </RevealRight>
                <RevealRight>
                  <CustomButton
                    isPrimary
                    buttonText={"Sign up for free"}
                    onClick={handleSignupRedirect}
                  />
                </RevealRight>
              </div>
              <div className="xl:absolute xl:right-[4rem] xl:bottom-16">
                <RevealLeft>
                  <img
                    className="h-[30rem] mt-[8rem] w-[35rem]"
                    alt="Hero 1"
                    src={HeroImage1}
                  />
                </RevealLeft>
              </div>
            </div>
          </div>
        </section>
        <section className="mb-12 lg:h-[300px]">
          <div className="container mx-auto">
            <div className="text-center">
              <RevealUp>
                <h2 className="h2 mb-12" style={{ fontWeight: "bold" }}>
                  A perfect fit for everyone
                </h2>
              </RevealUp>
            </div>
            <div className="grid grid-cols-1 gap-[50px] xl:grid-cols-2">
              <RevealRight>
                <div className="w-full bg-accent-secondary cursor-pointer rounded-[30px] max-w-[430px] xl:h-[300px] h-[400px] relative flex flex-col justify-center xl:flex-row mx-auto">
                  <div className="text-center py-6">
                    <RevealUp>
                      <h3 className="mb-6" style={{ fontWeight: "bold" }}>
                        Free
                      </h3>
                      <p className="lead px-12 mb-10">
                        For anyone wanting to design anything, on your own or
                        with others. No design experience required, and it's
                        free forever.
                      </p>
                      <CustomButton
                        isPrimary
                        buttonText={"Get Workflow Sim for free"}
                        onClick={handleSignupRedirect}
                      />
                    </RevealUp>
                  </div>
                </div>
              </RevealRight>
              <RevealLeft>
                <div className="w-full bg-accent-secondary cursor-pointer rounded-[30px] max-w-[430px] xl:h-[300px] h-[400px] relative flex flex-col justify-center xl:flex-row mx-auto">
                  <div className="text-center py-6">
                    <RevealUp>
                      <h3 className="mb-6" style={{ fontWeight: "bold" }}>
                        Pro
                      </h3>
                      <p className="lead px-12 mb-10">
                        For individuals wanting unlimited access to premium
                        content, with special tools to grow your brand or
                        passion project.
                      </p>
                      <CustomButton
                        isPrimary
                        buttonText={"Start your free trial"}
                        onClick={handleSignupRedirect}
                      />
                    </RevealUp>
                  </div>
                </div>
              </RevealLeft>
            </div>
          </div>
        </section>
        <section className="lg:h-[600px] py-12 lg:mt-0">
          <div className="container mx-auto h-full relative">
            <div className="flex flex-col xl:flex-row items-center h-full">
              <div className="text-center xl:text-left xl:absolute mt-5">
                <RevealLeft>
                  <img
                    className="h-[30rem] mt-[8rem] w-[35rem]"
                    src={HeroImage2}
                    alt="Hero 2"
                  />
                </RevealLeft>
              </div>
              <div className="xl:absolute xl:right-[4rem] xl:bottom-16">
                <RevealRight>
                  <h1 className="h1 xl:max-w-[700px] mb-3">
                    Ready-made designs
                  </h1>
                </RevealRight>
                <RevealRight>
                  <h3 className="h3 xl:max-w-[480px] mb-6">
                    Benefit from thousands of professional templates, images,
                    and quality content to choose from.
                  </h3>
                </RevealRight>
                <RevealRight>
                  <CustomButton
                    isPrimary
                    buttonText={"Create a design"}
                    onClick={handleSignupRedirect}
                  />
                </RevealRight>
              </div>
            </div>
          </div>
        </section>
        <section className="lg:h-[400px] py-12 lg:mt-0">
          <div className="container mx-auto h-full relative">
            <div className="flex flex-col xl:flex-row items-center h-full">
              <div className="text-center xl:text-left xl:ml-10">
                <RevealLeft>
                  <h1 className="h1 xl:max-w-[700px] mb-3">
                    Built for teamwork
                  </h1>
                </RevealLeft>
                <RevealLeft>
                  <h3 className="h3 xl:max-w-[480px] mb-6">
                    Invite people to edit with you or set your whole team up to
                    manage brand assets, brainstorm on whiteboard, get
                    approvals, and scale content.
                  </h3>
                </RevealLeft>
                <RevealLeft>
                  <CustomButton
                    isPrimary
                    buttonText={"Work together"}
                    onClick={handleSignupRedirect}
                  />
                </RevealLeft>
              </div>
              <div className="xl:right-[4rem] xl:mt-[-10rem]">
                <RevealRight>
                  <img
                    className="h-[35rem] mt-[8rem] w-[35rem]"
                    src={HeroImage3}
                    alt="Hero 3"
                  />
                </RevealRight>
              </div>
            </div>
          </div>
        </section>

        <section className="lg:h-[400px] py-12 lg:mt-0">
          <div className="container mx-auto h-full relative">
            <div className="flex flex-col xl:flex-row items-center h-full">
              <div className="text-center xl:text-left xl:absolute mt-[-5rem]">
                <RevealLeft>
                  <img
                    className="h-[20rem] mt-[8rem] w-[25rem]"
                    src={HeroImage4}
                    alt="Hero 4"
                  />
                </RevealLeft>
              </div>
              <div className="xl:absolute xl:right-[1rem] xl:bottom-16">
                <RevealRight>
                  <h1 className="h1 xl:max-w-[700px] mb-3">
                    Collaborate in real time
                  </h1>
                </RevealRight>
                <RevealRight>
                  <h3 className="h3 xl:max-w-[480px] mb-6">
                    Work in real time alongside your team and invite
                    stakeholders to review and feedback on your design ideas.
                  </h3>
                </RevealRight>
                <RevealRight>
                  <CustomButton
                    isPrimary
                    buttonText={"Start designing"}
                    onClick={handleSignupRedirect}
                  />
                </RevealRight>
              </div>
            </div>
          </div>
        </section>
        <section className="my-[20px] xl:mt-[100px]">
          <div className="container mx-auto">
            <div className="bg-accent-secondary rounded-[50px] min-h-[300px] pb-12 flex flex-col text-center xl:flex-row xl:items-center xl:text-left xl:gap-x-[50px] xl:pb-0">
              <div className="flex-1 xl:pl-12">
                <RevealUp>
                  <h2 className="h2 mb-6">
                    Create flowcharts <br /> effortlessly with AI <br /> powered
                    chart generator
                  </h2>
                </RevealUp>
                <RevealUp>
                  <p className="max-w-[474px] mx-auto xl:mx-0 mb-8">
                    Try our app for free and start designing <br /> professional
                    flowcharts today.
                  </p>
                </RevealUp>
                <RevealUp>
                  <CustomButton
                    isPrimary
                    buttonText={"Sign up for free"}
                    onClick={handleSignupRedirect}
                  />
                </RevealUp>
              </div>
              <div className="flex-1 xl:pr-12">
                <RevealLeft>
                  <img className="aiImage" src={AiImage} alt="AI" />
                </RevealLeft>
              </div>
            </div>
          </div>
        </section>
        <section className="my-[70px] xl:my-[70px]">
          <div className="container mx-auto">
            <div className="text-center">
              <RevealRight>
                <h2 className="h2 mb-3" style={{ fontWeight: "bold" }}>
                  Templates for absolutely anything
                </h2>
              </RevealRight>
              <RevealLeft>
                <p className="lead mx-w-[585px] mx-auto mb-10">
                  Customize an office template, or design something more
                  personal
                </p>
              </RevealLeft>
            </div>
            <div className="mb-12">
              <RevealUp>
                <img
                  src={flowchartImg}
                  className="flowchartImg"
                  alt="Flowchart"
                />
              </RevealUp>
            </div>
            <div className="text-center">
              <RevealUp>
                <CustomButton
                  isPrimary
                  buttonText={"Browse All Templates"}
                  onClick={handleSignupRedirect}
                />
              </RevealUp>
            </div>
          </div>
        </section>
        <section className="mb-12">
          <div className="container mx-auto">
            <div className="text-center">
              <RevealUp>
                <h2 className="h2 mb-3" style={{ fontWeight: "bold" }}>
                  Meet our team
                </h2>
              </RevealUp>
              <RevealUp>
                <p className="lead mx-w-[585px] mx-auto mb-10">
                  We are talented group of individuals dedicated to create
                  amazing softwares
                </p>
              </RevealUp>
            </div>
            <div className="grid grid-cols-1 gap-[50px] xl:grid-cols-2">
              <div className="w-full bg-accent-secondary cursor-pointer rounded-[20px] max-w-[530px] xl:h-[320px] h-[400px] relative flex flex-col items-center justify-center xl:flex-row xl:justify-start mx-auto">
                <div className="flex-1 xl:pl-12">
                  <RevealUp>
                    <img className="teamImg" src={RahulImg} alt="Team" />
                  </RevealUp>
                </div>
                <div className="flex-1 xl:pr-12">
                  <RevealUp>
                    <h2 className="teamTitle">Rahul Anandeshi</h2>
                  </RevealUp>
                  <RevealUp>
                    <p className="lead">CTO</p>
                  </RevealUp>
                  <RevealUp>
                    <p className="teamDesc">
                      Rahul is a technical expert <br /> who ensures the app
                      runs <br /> smoothly and efficiently.{" "}
                    </p>
                  </RevealUp>
                  <div className="flex flex-row">
                    <RevealUp>
                      <a>
                        <ImLinkedin
                          style={{
                            backgroundColor: "#fff",
                            color: "#5a03d5",
                            fontSize: 22,
                          }}
                        />
                      </a>
                    </RevealUp>
                    <RevealUp>
                      <a>
                        <PiInstagramLogoFill
                          style={{
                            backgroundColor: "#fff",
                            color: "#5a03d5",
                            fontSize: 22,
                            marginLeft: 10,
                          }}
                        />
                      </a>
                    </RevealUp>
                  </div>
                </div>
              </div>
              <div className="w-full bg-accent-secondary rounded-[20px] cursor-pointer max-w-[530px] xl:h-[320px] h-[450px] relative flex flex-col items-center justify-center xl:flex-row xl:justify-start mx-auto">
                <div className="flex-1 xl:pl-12">
                  <RevealUp>
                    <img className="teamImg" src={AnthonyImg} alt="Team" />
                  </RevealUp>
                </div>
                <div className="flex-1 xl:pr-12">
                  <RevealUp>
                    <h2 className="teamTitle">Anthony Brooks</h2>
                  </RevealUp>
                  <RevealUp>
                    <p className="lead">CEO</p>
                  </RevealUp>
                  <RevealUp>
                    <p className="teamDesc">
                      Tony is a visionary leader <br /> with a passion for{" "}
                      <br /> simplifying complex <br /> process.
                    </p>
                  </RevealUp>
                  <div className="flex flex-row">
                    <RevealUp>
                      <a>
                        <ImLinkedin
                          style={{
                            backgroundColor: "#fff",
                            color: "#5a03d5",
                            fontSize: 22,
                          }}
                        />
                      </a>
                    </RevealUp>
                    <RevealUp>
                      <a>
                        <PiInstagramLogoFill
                          style={{
                            backgroundColor: "#fff",
                            color: "#5a03d5",
                            fontSize: 22,
                            marginLeft: 10,
                          }}
                        />
                      </a>
                    </RevealUp>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="text-center py-4"
          style={{
            color: "#c9c9c9",
          }}
        >
          Version: 1.0.1
        </section>
      </Layout>
    </Space>
  );
}

export default HeroSection;
