import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CanvasLayout from "../../components/Canvas/CanvasLayout";
import { getChartDetails } from "../../redux/actions/flowchart";
import SvgComponent from "../../components/Canvas/SvgComponents";
import "./index.scss";
import { useParams } from "react-router-dom";

function Canvas() {
  const chart = useSelector((state) => state?.flowchart?.chartDetails);

  const [zoomLevel, setZoomLevel] = useState(75);

  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    if (!params?.id) return;
    dispatch(getChartDetails(params.id));
  }, [params, dispatch]);

  const onChangeRange = (newValue) => {
    setZoomLevel(newValue);
  };

  const handleZoomIn = () => {
    if (zoomLevel <= 90) {
      setZoomLevel(zoomLevel + 5);
    }
  };

  const handleZoomOut = () => {
    if (zoomLevel >= 60) {
      setZoomLevel(zoomLevel - 5);
    }
  };

  return (
    <CanvasLayout
      handleZoomIn={handleZoomIn}
      handleZoomOut={handleZoomOut}
      inputValue={zoomLevel}
      onChangeRange={onChangeRange}
      chart={chart}
    >
      {/* <div id="pointers">
        {JSON.stringify(clients)}
        {clients?.map((u, i) => (
          <div className="pointer" key={i}>
            <div>
              <svg x="0px" y="0px" width="16" height="16" viewBox="0 0 512 512">
                <g>
                  <path
                    className="st0"
                    fill={u?.color}
                    d="M106.66,8.16c-25.2-19.61-61.93-1.64-61.93,30.29v435.11c0,36.38,45.91,52.34,68.47,23.8l107.21-135.59
                c8.01-10.13,20.2-16.03,33.11-16.03h175.29c36.54,0,52.4-46.23,23.56-68.66L106.66,8.16z"
                  />
                </g>
              </svg>
            </div>
            <div
              style={{
                backgroundColor: u?.color,
                left: u?.x,
                top: u?.y,
              }}
              className="mouse-label"
            >
              {u?.user}
            </div>
          </div>
        ))}
      </div> */}
      {chart ? (
        <SvgComponent
          // nodesChanged={nodesChanged}
          chart={chart}
        />
      ) : null}
    </CanvasLayout>
  );
}

export default Canvas;
