import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useReactFlow } from "reactflow";
import { io } from "socket.io-client";

const useSocket = () => {
  const [clients, setClients] = useState([]);
  const [socket, setSocket] = useState(null);
  const reactFlowInstance = useReactFlow();

  const colorArr = [
    "#d35400",
    "#1abc9c",
    "#3498db",
    "#9b59b6",
    "#34495e",
    "#16a085",
    "#27ae60",
    "#2980b9",
    "#8e44ad",
    "#2c3e50",
    "#f1c40f",
    "#e67e22",
    "#e74c3c",
    "#f39c12",
    "#d35400",
    "#c0392b",
  ];

  const chart = useSelector((state) => state?.flowchart?.chartDetails);
  let user = JSON.parse(window.localStorage.getItem("user"));

  useEffect(() => {
    setSocket(io());
  }, []);

  useEffect(() => {
    // if (!chart?.chart_id || !user || !socket) return;

    if (chart?.chart_id && user && socket) {
      let color = colorArr[Math.floor(Math.random() * colorArr.length)];
      socket.emit("client_connect", { user, chart, color });

      socket.on(
        "client_enter",
        function ({ chart: resChart, clients: resClients }) {
          if (parseInt(chart?.chart_id) === parseInt(resChart.chart_id))
            setClients(
              resClients?.filter(
                (u) => parseInt(u.user_id) !== parseInt(user?.id)
              )
            );
        }
      );

      socket.on("moving", function (data) {
        setClients((prev) =>
          prev.map((u) => {
            if (
              parseInt(u?.user_id) === parseInt(data?.user_id) &&
              parseInt(u?.chart_id) === parseInt(chart?.chart_id)
            ) {
              u.x = data?.x;
              u.y = data?.y;
            }
            return u;
          })
        );
      });

      socket.on("node_changed", function ({ pages_id, nodes, edges }) {
        console.log("nodes changes: ", pages_id);
        if (parseInt(pages_id) === parseInt(chart?.pages_id)) {
          reactFlowInstance.setNodes(nodes);
          reactFlowInstance.setEdges(edges);
        }
      });

      socket.on("clientdisconnect", function (data) {
        setClients((prev) =>
          prev.filter(
            (user) => parseInt(user?.user_id) !== parseInt(data?.user?.id)
          )
        );
      });
    }

    if (socket) {
      return () => {
        socket.emit("disconnect_client", { user, chart });
        socket.disconnect();
      };
    }
  }, [chart?.chart_id, socket]);

  // on Chart Update
  useEffect(() => {
    if (!chart?.chart || !socket || !clients.length) return;

    if (clients.length && socket && chart?.chart) {
      let newChart = { ...chart?.chart };
      newChart.chart_id = chart;
      newChart.pages_id = chart?.pages_id;
      socket.emit("state_changed", newChart);
    }
  }, [chart?.chart, socket]);

  // for mouse move
  useEffect(() => {
    function mosueMove(e) {
      if (clients?.length && socket) {
        socket.emit("mousemove", {
          x: e.pageX,
          y: e.pageY,
          user_id: user.id,
          chart_id: chart?.chart_id,
        });
      }
    }

    if (clients?.length && socket) {
      document.body.addEventListener("mousemove", mosueMove);

      return () => {
        document.body.removeEventListener("mousemove", mosueMove);
      };
    }
  }, [clients?.length, socket]);

  return {
    clients,
  };
};

export default useSocket;
