import {
  SET_ARROW_TYPE,
  SET_BG_COLOR,
  SET_BORDER_COLOR,
  SET_BORDER_STYLE,
  SET_BORDER_WIDTH,
  SET_TEXT_EDITOR,
} from "../constants/node";

const initialState = {
  lineColor: null,
  fillColor: null,
  borderWidth: null,
  borderStyle: null,
  arrowType: null,
  tagName: null,
  fontSize: null,
};

const nodeReducers = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_BG_COLOR:
      return {
        ...state,
        fillColor: payload,
      };

    case SET_BORDER_COLOR:
      return {
        ...state,
        lineColor: payload,
      };

    case SET_BORDER_WIDTH:
      return {
        ...state,
        borderWidth: payload,
      };

    case SET_BORDER_STYLE:
      return {
        ...state,
        borderStyle: payload,
      };

    case SET_ARROW_TYPE:
      return {
        ...state,
        arrowType: payload,
      };

    case SET_TEXT_EDITOR:
      return {
        ...state,
        fontSize: payload?.fontSize,
        tagName: payload?.tagName,
      };

    default:
      return state;
  }
};

export default nodeReducers;
