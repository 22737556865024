import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { CgClose } from "react-icons/cg";
import ApiExecute from "../../../../redux/services";
import { Spin, message } from "antd";
import CustomButton from "../../../../shared/Components/Button";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { getShapes } from "../../../../redux/actions/flowchart";

const UploadNewShape = ({ setUploadShape }) => {
  const dispatch = useDispatch();
  const [shapeName, setShapeName] = useState("");
  const [shapeImage, setShapeImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!shapeImage) return;

    const objectUrl = URL.createObjectURL(shapeImage);
    setPreviewUrl(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [shapeImage]);

  const handleImage = (e) => {
    let [file] = e.target.files;
    console.log("file by file", file.name);
    setShapeImage(file);
  };

  const submitShape = async (e) => {
    e.preventDefault();
    if (!shapeName.trim().length) {
      message.warning("Please enter shape name.");
    } else if (!shapeImage) {
      message.warning("Please choose shape image.");
    } else {
      try {
        setLoading(true);

        let data = new FormData();
        data.append("shape_name", shapeName);
        data.append("shape_description", shapeName);
        data.append("category_id", 1002);
        data.append("is_active", true);
        data.append("image", shapeImage);
        await ApiExecute("shape", "POST", {
          data,
          files: true,
        });
        setLoading(false);
        setUploadShape(false);
        dispatch(getShapes());
        message.success("New shape uploaded successfully.");
      } catch (error) {
        setLoading(false);
        message.error(error);
      }
    }
  };

  return (
    <div className={style.shapePopupContainer}>
      <div
        className={style.sPopupBg}
        onClick={() => setUploadShape(false)}
      ></div>
      <div className={style.sPopup}>
        <div className={style.sPopupHeader}>
          Upload Shape
          <button type="button" onClick={() => setUploadShape(false)}>
            <CgClose />
          </button>
        </div>
        <div className={style.sPopupBody}>
          <form
            method="post"
            encType="mulipart/form-data"
            onSubmit={submitShape}
          >
            <div className={style.sFormGroup}>
              <label htmlFor="shapeName" className={style.sFormLabel}>
                Shape Name
              </label>
              <input
                type="text"
                className={style.sFormControl}
                id="shapeName"
                value={shapeName}
                onInput={(e) => setShapeName(e.target.value)}
              />
            </div>
            <div className={style.sFormGroup}>
              <label htmlFor="shapeImage" className={style.sFormLabel}>
                Choose Image
              </label>
              <label htmlFor="shapeImage" className={style.sImageUpload}>
                <input
                  type="file"
                  name="image"
                  id="shapeImage"
                  accept="image/*"
                  onChange={handleImage}
                />
                {previewUrl ? (
                  <img
                    src={previewUrl}
                    alt="Preview Image"
                    className={style.previewImage}
                  />
                ) : null}
                <div className={style.placeholder}>
                  Click here to {previewUrl ? "change image" : "choose image"}
                </div>
              </label>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <CustomButton
                buttonText={"Upload Image"}
                isPrimary={true}
                disabled={loading}
              />
              {loading ? (
                <div>
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 24,
                        }}
                        spin
                      />
                    }
                  />
                  Loading...
                </div>
              ) : null}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UploadNewShape;
