export const nodeDataMappings = {
  ellipse: {
    id: 1052,
    label: "Terminal",
    src: "https://ik.imagekit.io/klemoeilu/Terminal_uk9xINcgWZ.svg",
  },
  circle: {
    id: 1052,
    label: "Terminal",
    src: "https://ik.imagekit.io/klemoeilu/Terminal_uk9xINcgWZ.svg",
  },
  rectangle: {
    id: 1050,
    label: "Process",
    src: "https://ik.imagekit.io/klemoeilu/Process_eCSE_-xHn.svg",
  },
  rhombus: {
    id: 1040,
    label: "Decision",
    src: "https://ik.imagekit.io/klemoeilu/Decision_xAqPM7nNH.svg",
  },
  diamond: {
    id: 1040,
    label: "Decision",
    src: "https://ik.imagekit.io/klemoeilu/Decision_xAqPM7nNH.svg",
  },
};
