import React, { useEffect, useRef, useState } from "react";
import { Layout, message } from "antd";

import {
  PlusCircleOutlined,
  CloseCircleOutlined,
  CheckOutlined,
} from "@ant-design/icons";

import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  addUpdateChart,
  clearRedo,
  clearUndo,
  getChartDetails,
} from "../../../../redux/actions/flowchart";
import { GET_CHART_DETAILS_SUCCESS } from "../../../../redux/constants/flowchart";

function CanvasFooter() {
  const dispatch = useDispatch();
  const chartDetails = useSelector((state) => state?.flowchart?.chartDetails);

  const [width, setWidth] = useState(0);
  const [pageName, setPageName] = useState("");
  const [pages, setPages] = useState([]);
  const span = useRef();

  let pageCount = chartDetails?.pages?.length + 1;

  useEffect(() => {
    if (chartDetails) {
      setPageName(chartDetails?.page_name);

      if (chartDetails?.pages?.length) {
        let pagesData = [...chartDetails?.pages];

        pagesData.sort((a, b) => a.page_num - b.page_num);

        setPages(pagesData);
      }
    }
  }, [chartDetails]);

  useEffect(() => {
    setWidth(parseInt(span.current?.offsetWidth) + 20);
  }, [pageName]);

  const onAddNewPage = () => {
    dispatch(
      addUpdateChart({
        chart_id: chartDetails.chart_id,
        product: "FC",
        is_template: false,
        is_starred: false,
        pages_id: null,
        chart: {},
        page_name: `Page ${pageCount}`,
      })
    ).then(() => {
      dispatch(getChartDetails(chartDetails.chart_id, pageCount));
      dispatch(clearUndo());
      dispatch(clearRedo());
    });
  };

  const setActivePage = (pageNum) => {
    let chartId = chartDetails?.chart_id;
    dispatch(getChartDetails(chartId, pageNum));
    dispatch(clearUndo());
    dispatch(clearRedo());
  };

  const savePageName = () => {
    let newChart = { ...chartDetails };

    newChart.page_name = pageName;

    dispatch(addUpdateChart(newChart));

    dispatch({
      type: GET_CHART_DETAILS_SUCCESS,
      payload: newChart,
    });

    message.success("Success! Page name changed");
  };

  const removePage = (pages_id) => {
    if (window.confirm("Are you sure to delete page?")) {
      let newChart = { ...chartDetails };

      newChart.pages_id = pages_id;
      newChart.pages_is_active = false;

      dispatch(addUpdateChart(newChart));

      dispatch({
        type: GET_CHART_DETAILS_SUCCESS,
        payload: newChart,
      });

      message.success("Page deleted");
    }
  };

  return (
    <footer
      style={{
        width: "100%",
        backgroundColor: "#d9d9d9",
        borderTop: "1px solid #ececec",
        padding: 0,
        // marginLeft: "235px",
      }}
    >
      <ul className="pages">
        {pages?.map((page, i) => (
          <li
            className={
              chartDetails.page_num === page.page_num ? "active" : null
            }
            style={{ whiteSpace: "nowrap" }}
            key={i}
          >
            {chartDetails.page_num === page.page_num ? (
              <>
                <span
                  ref={span}
                  style={{
                    opacity: 0,
                    pointerEvents: "none",
                    position: "absolute",
                  }}
                >
                  {pageName ?? "Unnamed"}
                </span>
                <input
                  value={pageName}
                  className="span"
                  style={{ width }}
                  onInput={(event) => setPageName(event.target.value)}
                />
                {chartDetails?.page_name !== pageName?.trim() ? (
                  <CheckOutlined
                    className="savePageName"
                    onClick={savePageName}
                  />
                ) : null}
              </>
            ) : (
              <span onClick={() => setActivePage(page?.page_num)}>
                {page?.page_name}
              </span>
            )}
            <CloseCircleOutlined
              className="removeBtn"
              onClick={() => removePage(page?.pages_id)}
            />
          </li>
        ))}
        <li className="addBtn">
          <button className="footer-addBtn" onClick={onAddNewPage}>
            <PlusCircleOutlined style={{ fontSize: 18 }} />
          </button>
        </li>
      </ul>
    </footer>
  );
}

export default CanvasFooter;
