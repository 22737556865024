import React from "react";

function ForYou() {
  return (
    <div>
      <div>ForYou</div>
    </div>
  );
}

export default ForYou;
