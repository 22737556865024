import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
import { useEffect } from "react";
import { getShapes } from "../../../../redux/actions/flowchart";

const ConnectorShapePopup = ({ pos, addShapeWithEdge }) => {
  let actualPos = { ...pos };
  const dispatch = useDispatch();
  let shapes = useSelector((state) => state?.flowchart?.shapes?.data);

  useEffect(() => {
    if (!shapes) {
      dispatch(getShapes());
    }
  }, [shapes]);

  let styles = {
    top: pos.y,
    left: pos.x - 116,
  };

  if (pos.y > 240) {
    styles = {
      bottom: 0,
      left: pos.x - 116,
    };
  }

  if (pos.x + 116 < 332) {
    styles = {
      ...styles,
      left: 0,
    };
  }

  return (
    <div className="shape-popup-container" style={styles}>
      <h4>Shapes</h4>
      <div className="shape-popup-row">
        {shapes &&
          shapes["Flow Chart"] &&
          shapes["Flow Chart"]?.map((shape, idx) => (
            <div className="shape-popup">
              <img
                src={shape?.shape_src}
                alt={shape?.shape_name || "Shape"}
                key={idx}
                onClick={() => addShapeWithEdge(shape, actualPos)}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default ConnectorShapePopup;
