import React from "react";
import moment from "moment";

// TODO change this to false manually until package is used
// const development: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

const isProd =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? false
    : true;

// if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
//     // dev code
// } else {
//     // production code
// }

// const isProd = false;

export const API_URL = isProd ? "/api/" : "/";

export const renderImage = (rawHtml) => {
  return React.createElement("div", {
    dangerouslySetInnerHTML: { __html: rawHtml },
  });
};

export const convertedDate = (date) => {
  return moment(date).format("MMMM D, Y");
};

export const getDateStatus = (date) => {
  moment.locale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s",
      s: "1s",
      ss: "%ss",
      m: "1m",
      mm: "%dm",
      h: "1h",
      hh: "%dh",
      d: "1d",
      dd: "%dd",
      M: "1M",
      MM: "%dM",
      y: "1Y",
      yy: "%dY",
    },
  });

  return moment(date).fromNow();
};

export const ValidateEmail = (mail) => {
  let validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return validRegex.test(mail);
};

export const getMousePointer = (event) => {
  let { clientX: x, clientY: y } = event;
  let container = document.querySelector("#canvas-area");
  x -= container.offsetLeft;
  y -= container.offsetTop;

  return {
    x,
    y,
  };
};

export const MarkerEnd = () => {
  return (
    <defs>
      <marker
        id="arrow"
        markerWidth="10"
        markerHeight="10"
        refX="0"
        refY="3"
        orient="auto"
        markerUnits="strokeWidth"
      >
        <path d="M0,0 L0,6 L9,3 z" fill="#f00" />
      </marker>
      <marker
        id="squareArrow"
        markerUnits="strokeWidth"
        viewBox="0 0 12 12"
        refX="5"
        refY="6"
        markerWidth="8"
        markerHeight="8"
        orient="auto"
      >
        <rect
          x="1"
          y="1"
          width="10"
          height="10"
          fill="white"
          stroke="#000"
          strokeWidth="1"
        />
      </marker>
      <marker
        id="squareFillArrow"
        markerUnits="strokeWidth"
        viewBox="0 0 12 12"
        refX="5"
        refY="6"
        markerWidth="8"
        markerHeight="8"
        orient="auto"
      >
        <rect
          x="1"
          y="1"
          width="10"
          height="10"
          fill="#000"
          stroke="#000"
          strokeWidth="1"
        />
      </marker>
      <marker
        id="circleArrow"
        markerUnits="strokeWidth"
        viewBox="0 0 12 12"
        refX="5"
        refY="6"
        markerWidth="8"
        markerHeight="8"
        orient="auto"
      >
        <circle
          cx="6"
          cy="6"
          r="5"
          width="10"
          height="10"
          fill="white"
          stroke="#000"
          strokeWidth="1"
        />
      </marker>
      <marker
        id="circleFillArrow"
        markerUnits="strokeWidth"
        viewBox="0 0 12 12"
        refX="5"
        refY="6"
        markerWidth="8"
        markerHeight="8"
        orient="auto"
      >
        <circle
          cx="6"
          cy="6"
          r="5"
          width="10"
          height="10"
          fill="#000"
          stroke="#000"
          strokeWidth="1"
        />
      </marker>
    </defs>
  );
};

export const getNumbersFromGivenRange = (range) => {
  let rangeArr = [];
  for (let i = 1; i <= range; i++) {
    rangeArr.push(i);
  }
  return rangeArr;
};

export const calculateVisibleCountBasedOnScreenSize = () => {
  if (window.matchMedia("(max-width: 576px)").matches) {
    return 1;
  } else if (window.matchMedia("(max-width: 768px)").matches) {
    return 1;
  } else if (window.matchMedia("(max-width: 992px)").matches) {
    return 2;
  } else if (window.matchMedia("(max-width: 1200px)").matches) {
    return 3;
  } else if (window.matchMedia("(max-width: 1400px)").matches) {
    return 3;
  } else if (window.matchMedia("(max-width: 1600px)").matches) {
    return 4;
  } else {
    return 8;
  }
};
