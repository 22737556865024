import {
  ADD_SHAPES_TO_CHART_SUCCESS,
  ADD_UPDATE_CHART_ACCESS_FAILURE,
  ADD_UPDATE_CHART_ACCESS_SUCCESS,
  ADD_UPDATE_CHART_FAILURE,
  ADD_UPDATE_CHART_SUCCESS,
  GET_ALL_SHAPES_FAILURE,
  GET_ALL_SHAPES_SUCCESS,
  GET_CHART_DETAILS_FAILURE,
  GET_CHART_DETAILS_SUCCESS,
  REDO_CLEAR,
  REDO_SUCCESS,
  RESET_CHART_UPDATED,
  SET_ACTIVE_SHAPES_SUCCESS,
  SET_COPYING,
  SET_REDO_ITEMS,
  SET_SAVING,
  SET_SHAPE_GROUP_SAVE,
  SET_UNDO_ITEMS,
  TOGGLE_SHAPE_POPUP,
  UNDO_CLEAR,
  UNDO_SUCCESS,
} from "../constants/flowchart";
import {
  GET_CHART_ACCESS_FAILURE,
  GET_CHART_ACCESS_SUCCESS,
} from "../constants/workflow";

const initialState = {
  shapes: [],
  chartDetails: null,
  activeShape: [],
  updatedData: [],
  popupShown: null,
  undoItems: [],
  redoItems: [],
  chartAccessData: [],
  chartAccessList: [],
  saving: false,
  copying: false,
  shape_group_save: false,
  addUpdateFailed: false,
};
export default function flowchartReducers(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_SHAPES_SUCCESS:
      return {
        ...state,
        shapes: payload,
      };
    case GET_ALL_SHAPES_FAILURE:
      return {
        ...state,
        shapes: payload,
      };
    case GET_CHART_DETAILS_SUCCESS:
      return {
        ...state,
        chartDetails: payload,
      };
    case GET_CHART_DETAILS_FAILURE:
      return {
        ...state,
      };
    case SET_SAVING:
      return {
        ...state,
        saving: payload,
      };
    case SET_COPYING:
      return {
        ...state,
        copying: payload,
      };
    case SET_SHAPE_GROUP_SAVE:
      return {
        ...state,
        shape_group_save: payload,
      };
    case ADD_UPDATE_CHART_SUCCESS:
      // console.log("payload for updated Data:", payload);
      return {
        ...state,
        updatedData: payload,
        chartDetails:
          state?.chartDetails?.chart_id === payload?.data?.chart_id
            ? payload?.data
            : state?.chartDetails,
        saving: false,
        addUpdateFailed: false,
      };
    case RESET_CHART_UPDATED:
      return {
        ...state,
        updatedData: null,
      };
    case ADD_UPDATE_CHART_FAILURE:
      return {
        ...state,
        updatedData: payload,
        saving: false,
        addUpdateFailed: true,
      };
    case ADD_SHAPES_TO_CHART_SUCCESS:
      if (!state.flowchart.shapes.data) state.flowchart.shapes.data = [];
      state.flowchart.shapes.data.push(payload);
      return {
        ...state,
      };
    case SET_ACTIVE_SHAPES_SUCCESS:
      return {
        ...state,
        activeShape: payload,
      };

    case TOGGLE_SHAPE_POPUP:
      return {
        ...state,
        popupShown: payload,
      };

    case SET_UNDO_ITEMS:
      return {
        ...state,
        undoItems: state.undoItems.concat(payload),
      };

    case SET_REDO_ITEMS:
      return {
        ...state,
        redoItems: state.redoItems.concat(payload),
      };

    case UNDO_SUCCESS:
      let newUndoItems = [...state.undoItems];

      newUndoItems.splice(-1);

      return {
        ...state,
        undoItems: newUndoItems,
      };

    case REDO_SUCCESS:
      let newRedoItems = [...state.redoItems];

      newRedoItems.splice(-1);

      return {
        ...state,
        redoItems: newRedoItems,
      };

    case REDO_CLEAR:
      return {
        ...state,
        redoItems: [],
      };

    case UNDO_CLEAR:
      return {
        ...state,
        undoItems: [],
      };

    case ADD_UPDATE_CHART_ACCESS_SUCCESS:
      return {
        ...state,
        chartAccessData: payload,
      };
    case ADD_UPDATE_CHART_ACCESS_FAILURE:
      return {
        ...state,
        chartAccessData: payload,
      };

    case GET_CHART_ACCESS_SUCCESS:
      return {
        ...state,
        chartAccessList: payload,
      };

    case GET_CHART_ACCESS_FAILURE:
      return {
        ...state,
        chartAccessList: payload,
      };

    default:
      return state;
  }
}
