import { Card } from "antd";
import React from "react";
import { BiPlusMedical } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import {
  openComment,
  setActiveComment,
} from "../../../../../redux/actions/comment";
import styles from "./style.module.scss";
import SingleComment from "../../../SvgComponents/SingleComment";

const CommentList = () => {
  const dispatch = useDispatch();
  const comments = useSelector((state) => state?.comment?.comments);

  const activeComment = () => {
    dispatch(setActiveComment(true));
  };

  return (
    <div
      className="flex flex-col gap-2 h-full p-3"
      style={{
        gap: 10,
        justifyContent: "space-between",
        padding: 10,
      }}
    >
      <div className="grid">
        <button
          className="shape-button btn rounded-md h-10"
          onClick={() => activeComment()}
        >
          <BiPlusMedical /> Add Comment
        </button>
      </div>
      <div
        className={`grow h-full`}
        style={{
          overflow: "auto",
        }}
      >
        <div className={styles.commentWrapper}>
          {comments?.length > 0 &&
            comments?.map((comment) => {
              return (
                <Card
                  style={{
                    backgroundColor: "#d1d1d1",
                    border: "none",
                    marginBottom: 10,
                  }}
                  hoverable
                  onClick={() => {
                    dispatch(openComment(comment));
                  }}
                >
                  <SingleComment comment={comment} />
                </Card>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default CommentList;
