import { Avatar, Col, Dropdown, Row, Space, Typography } from "antd";
import React from "react";
import {
  CaretDownOutlined,
  SettingOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/actions/authentication";

const HeaderProfile = () => {
  const { Text } = Typography;
  let user = JSON.parse(window.localStorage.getItem("user"));
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(logout());
  };

  const logoutContent = (
    <div className="logoutBtn" onClick={logOut}>
      <ExportOutlined style={{ marginLeft: -5, marginRight: 8 }} />
      Logout
    </div>
  );
  const items = [
    {
      label: (
        <>
          <Row>
            <Col span={4}>
              <Avatar
                style={{
                  backgroundColor: "#843dff",
                  marginTop: 10,
                  marginLeft: -5,
                }}
                size="small"
                // icon={<UserOutlined />}
              >
                {user?.name[0].toUpperCase()}
              </Avatar>
            </Col>
            <Col span={20}>
              <Row>
                <Col span={24}>
                  <Text strong style={{ color: "#843dff" }}>
                    Hi, {user?.name}
                  </Text>
                </Col>
                <Col span={24}>
                  <Text italic>{user?.email}</Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      ),
      key: "user",
    },
    {
      type: "divider",
    },
    {
      label: (
        <a>
          <SettingOutlined style={{ marginRight: 8 }} /> Account Settings
        </a>
      ),
      key: "accSetting",
    },
    {
      type: "divider",
    },
    {
      label: logoutContent,
      key: "logout",
    },
  ];
  return (
    <Dropdown
      menu={{
        items,
      }}
      trigger={["click"]}
      placement="bottomLeft"
      className="pr-3 mt-[-5px]"
    >
      <a href="/" onClick={(e) => e.preventDefault()}>
        <Space>
          <Avatar
            style={{
              backgroundColor: "#5a03d5",
              fontSize: 20,
            }}
            size={32}
            // icon={<UserOutlined />}
          >
            {user?.name[0]?.toUpperCase()}
          </Avatar>
          <span className="username">{user?.name}</span>
          <CaretDownOutlined style={{ color: "#5a03d5", verticalAlign: 0 }} />
        </Space>
      </a>
    </Dropdown>
  );
};

export default HeaderProfile;
