import { GET_CHART_FAILURE, GET_CHART_SUCCESS, GET_TEMPLATE_FAILURE, GET_TEMPLATE_SUCCESS, STAR_UNCHART_FAILURE, STAR_UNCHART_SUCCESS } from "../constants/dashboard";


const initialState = { charts: [], templates: [] };
export default function dashboardReducers(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_CHART_SUCCESS:
            return {
                ...state,
                charts: payload,
            };
        case GET_CHART_FAILURE:
            return {
                ...state,
                charts: payload,
            };
        case STAR_UNCHART_SUCCESS:
            return {
                ...state,
                starredData: payload
            }
        case STAR_UNCHART_FAILURE:
            return {
                ...state,
                starredData: payload
            }
        case GET_TEMPLATE_SUCCESS:
            return {
                ...state,
                templates: payload,
            };
        case GET_TEMPLATE_FAILURE:
            return {
                ...state,
                templates: payload,
            };
        default:
            return state;
    }
}
