import React from "react";
import "./index.scss";

function CustomButton({
  isPrimary,
  hasIcon,
  buttonText,
  onClick,
  width,
  height,
  disabled,
  customStyle,
}) {
  const buttonClassName = isPrimary ? "primary-btn" : "secondary-btn";
  return (
    <button
      disabled={disabled}
      className={`btn btn-primary mb-8 xl:mb-0 rounded-md px-10 h-10 ${buttonClassName}`}
      style={
        customStyle
          ? customStyle
          : {
              minWidth: width,
              maxWidth: width,
              minHeight: height,
              maxHeight: height,
            }
      }
      onClick={onClick}
    >
      {hasIcon && <span className="button-icon">{hasIcon}</span>}
      {buttonText}
    </button>
  );
}

export default CustomButton;
