import { useDispatch } from "react-redux";
import "./index.scss";
import { SET_SHAPE_GROUP_SAVE } from "../../../../../redux/constants/flowchart";

const ShapeMenu = ({
  shapeData,
  copyShape,
  deleteShape,
  pasteShape,
  type,
  // saveAsShapeGroup,
  saveAsImage,
  copyNode,
}) => {
  const dispatch = useDispatch();

  const openShapeGroupShape = () => {
    dispatch({
      type: SET_SHAPE_GROUP_SAVE,
      payload: true,
    });
  };

  return (
    <ul
      className="contextMenu"
      style={{
        left: shapeData?.pos?.x,
        top: shapeData?.pos?.y,
      }}
    >
      {type === "node" ? (
        <>
          <li onClick={() => copyShape(shapeData?.node)}>Copy</li>
          <li onClick={() => deleteShape(shapeData?.node)}>Delete</li>
          <li onClick={() => openShapeGroupShape()}>Save as Shape Group</li>
          <li onClick={() => saveAsImage(shapeData?.node)}>Copy as Image</li>
        </>
      ) : (
        <>
          <li
            onClick={(event) => {
              if (copyNode) pasteShape(event);
              else return false;
            }}
            className={!copyNode ? "disabled" : null}
          >
            Paste
          </li>
        </>
      )}
    </ul>
  );
};

export default ShapeMenu;
