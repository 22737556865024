import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ApiExecute from "../../redux/services";
import FlowchartCard from "../../components/Dashboard/FlowchartCard";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const SearchChart = (search) => {
  const query = useQuery();
  const [charts, setCharts] = useState([]);

  async function fetchSearchData(search) {
    try {
      let response = await ApiExecute(`search-charts?search_text=${search}`);
      setCharts(response);
    } catch (error) {}
  }

  useEffect(() => {
    let search = query.get("search");
    if (!search) return;

    fetchSearchData(search);
  }, [query]);

  return (
    <div>
      <div className="row-container">
        <h2>Search Charts</h2>
        <div>
          {charts?.length ? (
            <div className="chart-row">
              {charts?.map((cData, index) => (
                <div className="chart-col" key={index}>
                  <FlowchartCard
                    cData={cData}
                    title={cData?.chart_name || "Blank Diagram"}
                    imagesrc={cData?.image}
                    productType={cData?.product}
                    username={cData?.name}
                    dateModified={cData?.date_modified}
                    id={cData?.chart_id}
                    isStarred={cData?.is_starred}
                  />
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SearchChart;
