import React, { useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import ApiExecute from "../../redux/services";
import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";

const ChartSearchInput = () => {
  const navigate = useNavigate();
  const [DATA, setDATA] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState(null);

  const formRef = useRef(null);

  function onKeyDown(event) {
    if (event.keyCode === 38) {
    }
  }

  function handleSearch(event) {
    event.preventDefault();

    navigate(`/search-chart/?search=${search}`);
  }

  async function fetchAutocomplete() {
    try {
      let apiResponse = await ApiExecute("search-charts-autocomplete");
      setDATA(apiResponse);
    } catch (error) {
      console.warn("Error:", error);
    }
  }
  useEffect(() => {
    fetchAutocomplete();
  }, []);

  useEffect(() => {
    if (!isOpen && search.length > 2) setIsOpen(true);
    else if (search.length <= 2 && isOpen) setIsOpen(false);
  }, [search]);

  useEffect(() => {
    if (!isOpen && search.length > 2)
      navigate(`/search-chart/?search=${search}`);
  }, [isOpen, search]);

  useEffect(() => {
    setFilteredData(
      DATA.filter((ch) =>
        ch.chart_name?.toLowerCase()?.includes(search?.trim()?.toLowerCase())
      )
    );
  }, [search]);

  return (
    <form
      method="POST"
      ref={formRef}
      className={`pseudo-search ${styles.autoCompleteContainer}`}
      onSubmit={handleSearch}
    >
      <input
        type="text"
        placeholder="Search Charts"
        // onChange={onSearch}
        // value={searchData}
        onInput={(event) => setSearch(event.target.value)}
        value={search}
        onKeyDown={onKeyDown}
        autoFocus
        required
      />
      <button>
        <SearchOutlined
          style={{ color: "#5A03D5", fontWeight: 700, fontSize: 22 }}
        />
      </button>

      {isOpen ? (
        <ul className={styles.suggestionLists}>
          {filteredData?.map((row, index) => (
            <li
              key={index}
              onClick={() => {
                setSearch(row.chart_name);
                setTimeout(() => {
                  setIsOpen(false);
                }, 500);
              }}
            >
              {row.chart_name}
            </li>
          ))}
        </ul>
      ) : null}
    </form>
  );
};

export default ChartSearchInput;
