import React, { useEffect, useState } from 'react';
import './index.scss';

const TYPING_SPEED = 150;
const DELETING_SPEED = 30;

function TypingAnimation({ dataText }) {

    const [text, setText] = useState("");
    const [isDeleting, setIsDeleting] = useState(false);
    const [loopNum, setLoopNum] = useState(0);
    const [typingSpeed, setTypingSpeed] = useState(TYPING_SPEED);

    useEffect(() => {
        const handleType = () => {
            const i = loopNum % dataText.length;
            const fullText = dataText[i];

            setText((prevText) => {
                if (isDeleting) {
                    return fullText.substring(0, prevText.length - 1);
                } else {
                    return fullText.substring(0, prevText.length + 1);
                }
            });

            setTypingSpeed(isDeleting ? DELETING_SPEED : TYPING_SPEED);

            if (!isDeleting && text === fullText) {
                setTimeout(() => setIsDeleting(true), 500);
            } else if (isDeleting && text === "") {
                setIsDeleting(false);
                setLoopNum(loopNum + 1);
            }
        };

        const typingTimer = setTimeout(handleType, typingSpeed);

        return () => clearTimeout(typingTimer);
    }, [dataText, text, isDeleting, loopNum, typingSpeed]);

    return (
        <React.Fragment>
            <span>{text}</span>
            <span id='cursor'></span>
        </React.Fragment>
    )
}

export default TypingAnimation