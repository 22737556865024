import { memo, useEffect, useRef, useState } from "react";
import "./index.scss";

const SvgText = (props) => {
  let { id, data, selected, updateLabel } = props;
  const [selectedFull, setSelectedFull] = useState(false);
  const ref = useRef();

  useEffect(() => {
    if (ref?.current?.innerHTML) {
      updateLabel(id, ref.current.innerHTML);
    }
  }, [ref?.current?.textContent, id, updateLabel]);

  function selectText(element) {
    if (!selectedFull) {
      setTimeout(() => {
        var sel, range;

        if (window.getSelection && document.createRange) {
          range = document.createRange();
          range.selectNodeContents(element);
          sel = window.getSelection();
          sel.removeAllRanges();
          sel.addRange(range);
        } else if (document.body.createTextRange) {
          range = document.body.createTextRange();
          range.moveToElementText(element);
          range.select();
        }

        setSelectedFull(true);
      }, 1);
    }
  }

  return (
    <div
      style={{
        minWidth: 200,
        minHeight: 100,
      }}
      className="svg-text"
    >
      <div
        contentEditable={true}
        style={{
          fontSize: 48,
          whiteSpace: "pre-wrap",
        }}
        ref={ref}
        onFocus={(e) => selectText(e.target)}
        className={selected ? "nodrag" : ""}
        dangerouslySetInnerHTML={{ __html: data?.label }}
      />
    </div>
  );
};

export default memo(SvgText);
