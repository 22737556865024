import {
  AI_GENERATOR_FAILURE,
  AI_GENERATOR_SUCCESS,
} from "../constants/autodesigner";
import autodesignerService from "../services/autodesigner";
import { logout } from "./authentication";

export const aiGenerator = (data) => async (dispatch) => {
  try {
    let payload = await autodesignerService.aiGeneratorPrompt(data);
    return dispatch({
      type: AI_GENERATOR_SUCCESS,
      payload,
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return dispatch(logout());
    }
    return dispatch({
      type: AI_GENERATOR_FAILURE,
      payload: error,
    });
  }
};
