import { useNavigate } from "react-router-dom";
import style from "./style.module.scss";
import {
  addUpdateChart,
  resetChartUpdated,
} from "../../../../redux/actions/flowchart";
import { useDispatch } from "react-redux";

const NewBoardPopup = ({ closePopup }) => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();

  const createNew = (type) => {
    let route = type === "FC" ? "/canvas/" : "/whiteboard/";
    dispatch(
      addUpdateChart({
        chart_id: null,
        product: type,
        is_template: false,
        is_starred: false,
        pages_id: null,
        chart: {},
        page_name: "Page 1",
        image: null,
        // navigateTo,
      })
    ).then(({ payload }) => {
      if (payload) {
        navigateTo(route + payload?.data?.chart_id);
        dispatch(resetChartUpdated());
      }
      // console.log("new chart updated: ", payload);
    });
  };

  return (
    <div>
      <div className={style.boardPopupBg}>
        <div className={style.boardPopup}>
          <button type="button" onClick={closePopup} className={style.close}>
            &times;
          </button>
          <h2>Create New</h2>
          <p>Choose from one of below</p>
          <div className={style.boardPopupBody}>
            <div>
              <button type="button" onClick={() => createNew("FC")}>
                <img
                  alt="Flowchart"
                  src="https://cdn-icons-png.flaticon.com/512/4411/4411911.png"
                />
                <h3>Flowchart</h3>
              </button>
            </div>
            <div>
              <button type="button" onClick={() => createNew("WB")}>
                <img
                  alt="Whiteboard"
                  src="https://cdn-icons-png.flaticon.com/512/3253/3253220.png"
                />
                <h3>Whitebaord</h3>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewBoardPopup;
