import React, { useState } from "react";
import { Row, Col, Form, Input, Divider, Spin } from "antd";
import regLogo from "../../../assets/register.json";
import Lottie from "react-lottie";
import "./index.scss";
import { useDispatch } from "react-redux";
import { register } from "../../../redux/actions/authentication";
import GoogleLogin from "../../../components/SocialAuthentication/Google";
import GithubLogin from "../../../components/SocialAuthentication/Github";
import logo from "../../../assets/logo.png";
import { ValidateEmail } from "../../../shared/functions";
import CustomButton from "../../../shared/Components/Button";

function Register() {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  // const [isSuccessful, setIsSuccessful] = useState(false);
  const [response, setResponse] = useState("");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: regLogo,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    // setIsSuccessful(false);
    if (password === confirmPassword) {
      if (name != "" && email != "" && password != "") {
        await dispatch(register(name, email, password))
          .then(() => {
            // setIsSuccessful(true);
          })
          .catch((err) => {
            setLoading(false);
            // setIsSuccessful(false);
            setResponse(err);
            console.log(err, "err");
          });
      } else {
        setLoading(false);
      }
    } else {
      setResponse("Password and confirm password should be same");
      setLoading(false);
    }
  };

  const notActionable =
    name === "" ||
    email === "" ||
    password === "" ||
    password !== confirmPassword;

  

  return (
    <div className="area">
      <ul className="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <Row
        style={{ width: "100%", height: "100vh" }}
        justify="space-around"
        align="middle"
      >
        <Col span={4}>
          <Lottie
            style={{
              paddingRight: 200,
              paddingTop: 80,
            }}
            options={defaultOptions}
            height={600}
            width={700}
          />
        </Col>
        <Col
          span={8}
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            textAlign: "center",
          }}
        >
          {
            response ?? null
          }
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
          >
            <div className="flex justify-center">
              <img alt="" className="logo" src={logo} />
            </div>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your Name!",
                },
              ]}
            >
              <Input
                placeholder="Name"
                size="large"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="email"
              hasFeedback
              validateStatus={
                !ValidateEmail(email) && email !== "" ? "error" : ""
              }
              help={
                !ValidateEmail(email) && email !== ""
                  ? "Please provide a valid email"
                  : ""
              }
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input
                placeholder="Email"
                size="large"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input
                type="password"
                placeholder="Password"
                size="large"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="confirmP"
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
              ]}
            >
              <Input
                type="password"
                placeholder="Confirm Password"
                size="large"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Item>
            <Form.Item style={{ textAlign: "center", marginBottom: 10 }}>
              <CustomButton
                isPrimary
                buttonText={loading ? <Spin /> : "Register"}
                onClick={handleRegister}
                width={"29vw"}
                disabled={notActionable}
                height={"35px"}
              />
              {/* <button htmlType="submit" disabled={notActionable} onClick={handleRegister} className={notActionable ? 'login-form-button btn-disabled' : "login-form-button"}>
                                {loading ? <Spin /> : "Register"}
                            </button> */}
              <div>
                <span style={{ color: "#5a03d5", fontSize: 16 }}>
                  Already have an account?
                  <a
                    style={{ fontSize: 16, marginLeft: 5, color: "#5a03d5" }}
                    href="/dashboard"
                  >
                    Login here.
                  </a>
                </span>
              </div>
            </Form.Item>
            {/* <Divider style={{ margin: '10px 0px' }} /> */}
            <Divider>
              <span className="loginTitle" style={{ color: "#5a03d5" }}>
                Register With
              </span>
            </Divider>
            <Row>
              <Col md={24}>
                <GoogleLogin btnText={"Signup with Google"} />
              </Col>
              <Col md={24}>
                <GithubLogin btnText={"Signup with Github"} />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default Register;
