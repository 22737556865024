import React, { useEffect, useState } from "react";
import { Row, Col, message } from "antd";
import "./index.scss";
import { forgotPassword } from "../../../redux/actions/authentication";
import { useDispatch } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Link, useLocation } from "react-router-dom";
import AuthenticationService from "../../../redux/services/authentication";

function ResetPassword() {
  const query = new URLSearchParams(useLocation().search);
  const token = query.get("key");

  const dispatch = useDispatch();

  const ResetSchema = Yup.object().shape({
    token: Yup.string().required("Key for recover password is not found."),
    password: Yup.string().required("Please enter new password."),
    confirmpassword: Yup.string()
      .required("Please enter confirm password")
      .oneOf(
        [Yup.ref("password"), null],
        "New and Confirm password must be same"
      ),
  });

  return (
    <Row
      style={{ backgroundColor: "#000000", width: "100%", height: "100vh" }}
      justify="space-around"
      align="middle"
    >
      <Col span={8}>
        <Link to={"/dashboard"}>
          <img
            alt=""
            className="forgotLogo"
            src="https://workflowsim.com/logo.png"
          />
        </Link>

        <Formik
          initialValues={{
            token,
            password: "",
            confirmpassword: "",
          }}
          validationSchema={ResetSchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            // setTimeout(() => {
            //   alert(JSON.stringify(values));
            // }, 3000);

            try {
              await AuthenticationService.recoverPassword(values);
              message.success("Success! Password has been changed.");
              resetForm();
            } catch (error) {
              message.warning("Something went wrong, try again.");
            }

            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form className="login-form">
              <ErrorMessage
                name="token"
                component="div"
                className="error form-group"
              />
              <div className="form-group">
                <Field
                  name="password"
                  type="password"
                  className="input"
                  placeholder="New Password"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="error"
                />
              </div>
              <div className="form-group">
                <Field
                  name="confirmpassword"
                  type="password"
                  className="input"
                  placeholder="Confirm Password"
                />
                <ErrorMessage
                  name="confirmpassword"
                  component="div"
                  className="error"
                />
              </div>
              <button
                type="submit"
                className="submit-btn"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Progressing..." : "Submit"}
              </button>
            </Form>
          )}
        </Formik>
      </Col>
    </Row>
  );
}

export default ResetPassword;
