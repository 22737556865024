import React, { useEffect, useState } from "react";
// import { Layout } from "antd";
import CanvasFooter from "./Footer";
import CanvasHeader from "./Header";
import LeftSidebar from "./LeftSider";
import "./index.scss";
import { ReactFlowProvider } from "reactflow";
import { useSelector } from "react-redux";
import RightSidebar from "./RightSider";
// const { Content } = Layout;
import WorkflowDocuments from "../SvgComponents/WorkflowDocuments";
import CanvasCursors from "../../CanvasCursors";
import ReactJoyride, { STATUS } from "react-joyride";
import ApiExecute from "../../../redux/services";
// import useSocket from "../../../redux/utils/useSocket";

function CanvasLayout({
  children,
  handleZoomIn,
  handleZoomOut,
  onChangeRange,
  inputValue,
  chart,
  socket,
}) {
  const user = JSON.parse(localStorage.getItem("user"));

  const [run, setRun] = useState(false);
  const { activeFlow } = useSelector((state) => state?.workflow);

  useEffect(() => {
    if (!chart) return;

    if (!user?.tour_info?.flowchart) {
      setTimeout(() => {
        setRun(true);
      }, 2 * 1000);
    }
  }, [chart]);

  const handleJoyrideCallback = async (data) => {
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(data.status)) {
      try {
        let response = await ApiExecute("update-tour-info", "POST", {
          data: {
            flowchart: true,
          },
        });

        localStorage.setItem("user", JSON.stringify(response));
      } catch (error) {}

      setRun(false);
    }
  };

  return (
    <ReactFlowProvider>
      {chart ? (
        <div className="canvasContainer">
          <ReactJoyride
            callback={handleJoyrideCallback}
            run={run}
            continuous
            scrollToFirstStep
            showSkipButton
            showProgress
            hideCloseButton
            steps={[
              {
                target: ".Standard",
                content:
                  "Standard Shapes: This section contains all standard shapes that can be used for grouping of shapes used in flow diagram",
              },
              {
                target: ".FlowChart",
                content:
                  "Flow Chart Shapes: This section contains all shapes that are need to be build a flow chart.",
              },
              {
                target: ".ShapeGroups",
                content:
                  "Shapes Groups: This section contains some example of flow diagram.",
              },
              {
                target: ".addMoreShapes",
                content:
                  "Using this button you can add more custom shapes to your flow diagram",
              },
              {
                target: ".ShareBtnContainer",
                content:
                  "Using this button you can share your chart with anyone with their email.",
              },
              {
                target: ".WorflowBtn",
                content:
                  "You can create, view or update your workflows for chart page.",
              },
              {
                target: ".CommentBtn",
                content: "To add, view and update comments on chart page.",
              },
              {
                target: ".AutoDesignerBtn",
                content:
                  "By clicking it you can able to create flow diagram by putting prompt via AI.",
              },
            ]}
            styles={{
              options: {
                arrowColor: "#bea6ff",
                backgroundColor: "#bea6ff",
                primaryColor: "#5a03d5",
              },
            }}
          />
          <CanvasCursors />
          {!activeFlow ? (
            <CanvasHeader chart={chart} socket={socket} setRun={setRun} />
          ) : null}
          <div
            style={{
              display: "flex",
              flexGrow: 1,
              overflow: "auto",
            }}
            className="h-full"
          >
            {!activeFlow ? <LeftSidebar /> : null}
            <div
              style={{
                flexGrow: 1,
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
              className="h-full"
            >
              <div
                className="h-full"
                style={{
                  display: "flex",
                  gap: 10,
                  flexGrow: 1,
                  backgroundColor: "#d9d9d9",
                }}
              >
                <main style={{ flexGrow: 1, height: "100%" }}>{children}</main>
                {activeFlow ? <WorkflowDocuments /> : null}
                <RightSidebar />
              </div>
              {!activeFlow ? (
                <CanvasFooter
                  handleZoomIn={handleZoomIn}
                  handleZoomOut={handleZoomOut}
                  onChangeRange={onChangeRange}
                  inputValue={inputValue}
                />
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Loading Contents...
        </div>
      )}
    </ReactFlowProvider>
  );
}

export default CanvasLayout;
