import "./index.scss";
import "antd/dist/reset.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Templates from "./containers/Dashboard/Templates";
import ForYou from "./containers/Dashboard/ForYou";
import Canvas from "./containers/Canvas";
import Login from "./containers/Authentication/Login";
import Register from "./containers/Authentication/Register";
import { useSelector } from "react-redux";
import Upgrade from "./containers/Dashboard/Upgrade";
import ForgotPassword from "./containers/Authentication/ForgotPassword";
import Whiteboard from "./containers/Whiteboard";
import FlowCharts from "./containers/Dashboard/Flowcharts";
import HeroSection from "./containers/HeroSection";
import Autodesigner from "./containers/AutoDesigner";

import "./icomoon/style.css";
import ResetPassword from "./containers/Authentication/ResetPassword";
import SurveyInfo from "./containers/SurveyInfo";
import PageNotFound from "./containers/PageNotFound";
import Layout from "./containers/Layout";
import SearchChart from "./containers/SearchChart";

function App() {
  const { isLoggedIn, user } = useSelector((state) => state.auth);

  const ProtectedRoute = ({ component: Component }) => {
    return isLoggedIn ? <Component /> : <Navigate to={{ pathname: "/" }} />;
  };

  return (
    <Router>
      <Routes>
        {!isLoggedIn ? (
          <>
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/" element={<HeroSection />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route exact path="/reset-password" element={<ResetPassword />} />
            <Route path="*" element={<Login />} />
          </>
        ) : (
          <>
            {user?.job_title &&
            user?.app_usage &&
            user?.use_cases &&
            user?.industry &&
            user?.discovered_through ? (
              <>
                <Route path="/canvas/:id" element={<Canvas />} />
                <Route path="/whiteboard/:id" element={<Whiteboard />} />
                <Route path="/" element={<Layout />}>
                  <Route index element={<FlowCharts />} />
                  <Route exact path="/dashboard" element={<FlowCharts />} />
                  <Route exact path="/search-chart" element={<SearchChart />} />
                  <Route
                    exact
                    path="/charts/:category"
                    element={<FlowCharts />}
                  />
                  <Route
                    exact
                    path="/autodesigner"
                    element={<Autodesigner />}
                  />
                  <Route exact path="/for-you" element={<ForYou />} />
                  <Route exact path="/upgrade" element={<Upgrade />} />
                  <Route exact path="/templates" element={<Templates />} />
                  <Route path="*" element={<PageNotFound />} />
                </Route>
              </>
            ) : (
              <Route
                path="*"
                element={<ProtectedRoute component={SurveyInfo} />}
              />
            )}
          </>
        )}
      </Routes>
    </Router>
  );
}

export default App;
