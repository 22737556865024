import React, { useState } from "react";
import Sidebar from "../../components/Dashboard/Layout/Sidebar";
import { Badge, Card, Col, Row } from "antd";
import { PlusOutlined, ClusterOutlined } from "@ant-design/icons";
import { ImStack } from "react-icons/im";
import { Link, Outlet } from "react-router-dom";
import NewBoardPopup from "../Dashboard/Flowcharts/NewBoardPopup";
import Header from "../../components/Dashboard/Layout/Header";
import Footer from "../../components/Dashboard/Layout/Footer";

const Layout = () => {
  const [activePage, setActivePage] = useState("");

  return (
    <div
      className="flex flex-col"
      style={{
        height: "100vh",
      }}
    >
      <Header hasSearch={true} />
      <main
        className="grow h-full"
        style={{
          minHeight: 0,
        }}
      >
        <div className="flex h-full">
          <Sidebar
            colorBgContainer={"#fff"}
            activePage={activePage}
            setActivePage={setActivePage}
          />
          <div className="grow flex flex-col h-full">
            <Outlet />

            {/* <Footer /> */}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Layout;
