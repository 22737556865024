import React from "react";
import "./index.scss";
import logo from "../../../../assets/logo.png";
import Notification from "../../../Notification";
import HeaderProfile from "../../../HeaderProfile/HeaderProfile";
import { Link } from "react-router-dom";
import ChartSearchInput from "../../../ChartSearchInput";
// const { Header } = Layout;
// const { Search } = Input;

function Header({ hasSearch }) {
  return (
    <header className="header">
      <Link to="/">
        <img
          alt="Workflowsim"
          className="h-[3rem] w-[20rem] xl:ml-5"
          src={logo}
        />
      </Link>

      {hasSearch && <ChartSearchInput />}

      <Notification />
      <HeaderProfile />
    </header>
  );
}

export default Header;
