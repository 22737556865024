import { AiFillHome } from "react-icons/ai";
import { ClusterOutlined } from "@ant-design/icons";
import { CgTemplate } from "react-icons/cg";
import { FaDashcube, FaHistory } from "react-icons/fa";
import { BiShareAlt, BiStar, BiUser } from "react-icons/bi";

export const Links = [
  {
    label: "Charts",
    target: "/",
    icon: <AiFillHome className="font-bold" />,
    childrens: [
      {
        label: "Recent",
        target: "/",
        icon: <FaHistory className="font-bold" />,
      },
      {
        label: "Starred",
        target: "/charts/STARRED",
        icon: <BiStar className="font-bold" />,
      },
      {
        label: "My Charts",
        target: "/charts/MY CHARTS",
        icon: <BiUser className="font-bold" />,
      },
      {
        label: "Shared With Me",
        target: "/charts/SHARED",
        icon: <BiShareAlt className="font-bold" />,
      },
    ],
  },
  {
    label: "Auto Generate",
    target: "/autodesigner",
    icon: <ClusterOutlined className="font-bold" />,
  },
  {
    label: "Templates",
    target: "/templates",
    icon: <CgTemplate className="font-bold" />,
  },
];
