export const languages = [
  { code: "en", name: "English", codeWithCountry: "en" },
  { code: "ar", name: "Arabic", codeWithCountry: "ar-SA" },
  { code: "az", name: "Azerbaijani", codeWithCountry: "az-AZ" },
  { code: "ca", name: "Catalan", codeWithCountry: "ca-ES" },
  { code: "zh", name: "Chinese", codeWithCountry: "zh-CN" },
  { code: "cs", name: "Czech", codeWithCountry: "cs-CZ" },
  { code: "da", name: "Danish", codeWithCountry: "da-DK" },
  { code: "nl", name: "Dutch", codeWithCountry: "nl-NL" },
  { code: "eo", name: "Esperanto", codeWithCountry: "eo-EO" },
  { code: "fi", name: "Finnish", codeWithCountry: "fi-FI" },
  { code: "fr", name: "French", codeWithCountry: "fr-FR" },
  { code: "de", name: "German", codeWithCountry: "de-DE" },
  { code: "el", name: "Greek", codeWithCountry: "el-GR" },
  { code: "he", name: "Hebrew", codeWithCountry: "he-IL" },
  { code: "hi", name: "Hindi", codeWithCountry: "hi-IN" },
  { code: "hu", name: "Hungarian", codeWithCountry: "hu-HU" },
  { code: "id", name: "Indonesian", codeWithCountry: "id-ID" },
  { code: "ga", name: "Irish", codeWithCountry: "ga-IE" },
  { code: "it", name: "Italian", codeWithCountry: "it-IT" },
  { code: "ja", name: "Japanese", codeWithCountry: "ja-JP" },
  { code: "ko", name: "Korean", codeWithCountry: "ko-KR" },
  { code: "fa", name: "Persian", codeWithCountry: "fa-IR" },
  { code: "pl", name: "Polish", codeWithCountry: "pl-PL" },
  { code: "pt", name: "Portuguese", codeWithCountry: "pt-PT" },
  { code: "ru", name: "Russian", codeWithCountry: "ru-RU" },
  { code: "sk", name: "Slovak", codeWithCountry: "sk-SK" },
  { code: "es", name: "Spanish", codeWithCountry: "es-ES" },
  { code: "sv", name: "Swedish", codeWithCountry: "sv-SE" },
  { code: "th", name: "Thai", codeWithCountry: "th-TH" },
  { code: "tr", name: "Turkish", codeWithCountry: "tr-TR" },
  { code: "uk", name: "Ukranian", codeWithCountry: "uk-UA" },
];
