import { useEffect, useState } from "react";
import "./index.scss";
import { FaAngleDoubleRight } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useReactFlow } from "reactflow";

const WorkflowDocuments = () => {
  const ReactFlow = useReactFlow();

  const activeNodes = useSelector((state) => state?.workflow?.activeNodes);

  const [activeNode, setActiveNode] = useState(null);
  const nodes = ReactFlow.getNodes();

  useEffect(() => {
    if (activeNodes.length) {
      let Node = nodes.filter(
        (node) => node.id === activeNodes[activeNodes.length - 1]
      );

      setActiveNode(Node[0]);
    }
  }, [activeNodes, nodes]);

  // useEffect(() => {
  //   console.log("active node: ", activeNode);
  // }, [activeNode]);

  return (
    <div className="workflowDocuments">
      <h4>
        <FaAngleDoubleRight /> Assets
      </h4>

      {activeNode ? (
        <>
          <div className="wDocCard">
            <h4 className="wd-card-title">{activeNode?.data?.label}</h4>
            {activeNode?.data?.images?.length ? (
              <div
                style={{
                  maxHeight: 300,
                  overflow: "auto",
                }}
              >
                {activeNode?.data?.images?.map((img, index) => (
                  <div style={{ marginBottom: 10 }} key={index}>
                    <img src={img.Location} alt="Step Title" />
                  </div>
                ))}
              </div>
            ) : null}
          </div>

          {activeNode?.data?.pdf_file ? (
            <div className="wDocCard">
              <h4 className="wd-card-title">
                {activeNode?.data?.label}
                <a
                  href={activeNode?.data?.pdf_file?.Location}
                  target="_blank"
                  className="anchorBtn"
                >
                  Open
                </a>
              </h4>
              <object
                data={activeNode?.data?.pdf_file?.Location}
                type="application/pdf"
                width="100%"
                height="300"
              >
                <p>
                  <a
                    href={activeNode?.data?.pdf_file?.Location}
                    target="_blank"
                  >
                    Preview FILE
                  </a>
                </p>
              </object>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default WorkflowDocuments;
