import React from "react";
import styles from "./style.module.scss";

const CopyLoading = () => {
  return (
    <div className={styles.loadingContainer}>
      <div className={styles.logoSpinner}>
        <div className={styles.innerCircle}></div>
        <img src="/logo.png" />
      </div>
      <h3 className="text-center mt-6">Image is copying...</h3>
    </div>
  );
};

export default CopyLoading;
