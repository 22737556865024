import { Input, Modal, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";
import {
  addUpdateWorkflows,
  getWorkflowDetails,
  setActiveNodes,
} from "../../../../redux/actions/workflow";
import { useDispatch } from "react-redux";

const WorkflowSavePopup = ({
  modelVisible,
  setModelVisible,
  activeNodes,
  pages_id,
  setActiveFlow,
}) => {
  const [form, setForm] = useState({
    pages_id,
    workflow_name: "",
    workflow_description: "",
    workflow: activeNodes,
    is_active: true,
  });

  const dispatch = useDispatch();

  const handleSubmit = () => {
    // event.preventDefault();

    if (form?.workflow_name === "") {
      message.warning("Please enter workflow name");
    } else {
      dispatch(addUpdateWorkflows(form)).then(() => {
        dispatch(getWorkflowDetails(form?.pages_id));
        dispatch(setActiveNodes(0));
      });
      message.success("New workflow added.");
      setModelVisible(false);
      setActiveFlow(false);
    }
  };

  return (
    <Modal
      title="Save Workflow"
      centered
      open={modelVisible}
      onOk={handleSubmit}
      onCancel={() => setModelVisible(false)}
      okText="Save"
      okButtonProps={{
        className: "primary-btn",
      }}
    >
      <form action="" method="post" onSubmit={handleSubmit}>
        <div style={{ marginBottom: 15 }}>
          <Input
            type="text"
            placeholder="Enter Title"
            value={form.workflow_name}
            onChange={(event) =>
              setForm((form) => ({
                ...form,
                workflow_name: event.target.value,
              }))
            }
          />
        </div>
        <div style={{ marginBottom: 15 }}>
          <TextArea
            placeholder="Enter Description"
            rows={7}
            value={form.workflow_description}
            onInput={(event) =>
              setForm((form) => {
                return {
                  ...form,
                  workflow_description: event.target.value,
                };
              })
            }
          />
        </div>
      </form>
    </Modal>
  );
};

export default WorkflowSavePopup;
