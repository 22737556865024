import React, { useState } from "react";
import { Row, Col, Form, Input, Typography, Spin } from "antd";
import "./index.scss";
import { forgotPassword } from "../../../redux/actions/authentication";
import { useDispatch } from "react-redux";

function ForgotPassword() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setIsSuccess(false);
    setResponse("");
    if (email != "") {
      await dispatch(forgotPassword(email))
        .then((res) => {
          setIsSuccess(true);
          setResponse("Invitation Sent to mail");
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setIsSuccess(false);
          setResponse(error);
        });
    } else {
      setLoading(false);
      setResponse("");
    }
  };

  return (
    <Row
      style={{ backgroundColor: "#000000", width: "100%", height: "100vh" }}
      justify="space-around"
      align="middle"
    >
      <Col span={8}>
        <img
          alt=""
          className="forgotLogo"
          src="https://workflowsim.com/logo.png"
        />
        {response ? (
          <div className="login-form">
            <div
              className={
                isSuccess ? "alert alert-success" : "alert alert-danger"
              }
              role="alert"
            >
              {response}
            </div>
          </div>
        ) : (
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
          >
            <Typography.Text strong>
              Enter the email assosciated with your account and we'll send you a
              link to reset your password.
            </Typography.Text>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
              style={{
                marginTop: 10,
              }}
            >
              <Input
                placeholder="Email"
                size="large"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
            <Form.Item style={{ textAlign: "center", marginBottom: 10 }}>
              <button
                onClick={handleForgotPassword}
                htmlType="submit"
                className="login-form-button"
              >
                {loading ? <Spin /> : "Continue"}
              </button>
              <span style={{ color: "#007bff", fontSize: 16 }}>
                Don't have an account?
                <a style={{ fontSize: 16, marginLeft: 5 }} href="/register">
                  Register here.
                </a>
              </span>
            </Form.Item>
          </Form>
        )}
      </Col>
    </Row>
  );
}

export default ForgotPassword;
