import { message } from "antd";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  addUpdateComment,
  getComments,
} from "../../../../redux/actions/comment";

import "./style.scss";
import { BiSend } from "react-icons/bi";
import { FaRegSmile } from "react-icons/fa";
import EmojiPicker from "emoji-picker-react";

const CommentSavePopup = ({ modelVisible, setModelVisible, pages_id }) => {
  const [commentText, setCommentText] = useState(null);
  const [emojiOpen, setEmojiOpen] = useState(false);
  const commentRef = useRef();

  const [form, setForm] = useState({
    comment_id: null,
    pages_id,
    comment: "",
    parent_id: null,
    is_active: true,
    position: modelVisible?.position,
  });

  const dispatch = useDispatch();

  const handleSubmit = () => {
    // event.preventDefault();

    let data = {
      ...form,
      comment: commentText,
    };

    if (commentText === "") {
      message.warning("Please enter comment");
    } else {
      dispatch(addUpdateComment(data)).then(() => {
        dispatch(getComments(form?.pages_id));
      });
      message.success("New comment added.");
      setModelVisible(false);
    }
  };

  return (
    <div>
      {modelVisible ? (
        <div
          className="commentContainer"
          style={{
            left: modelVisible?.screen?.x,
            top: modelVisible?.screen?.y,
          }}
        >
          <div className="commentInputContainer bg-white flex">
            <div
              style={{
                marginLeft: 10,
              }}
              onClick={() => setEmojiOpen((open) => !open)}
            >
              <FaRegSmile />
            </div>
            <div className="commentInput grow">
              {!commentText?.length ? (
                <div className="commentPlaceholder">Please enter comment</div>
              ) : null}
              <div
                contentEditable={true}
                className="commentText"
                onInput={(event) => setCommentText(event.target.innerHTML)}
                // onKeyDownCapture={handleKeyPress}
                autoFocus={true}
                id="commentText"
                ref={commentRef}
              >
                {form.comment}
              </div>
            </div>
            <div style={{ padding: "0 10px" }}>
              <button onClick={() => handleSubmit()}>
                <BiSend />
              </button>
            </div>
          </div>
          {emojiOpen ? (
            <EmojiPicker
              onEmojiClick={(emojiData) => {
                commentRef.current.innerHTML += emojiData?.emoji;
                setCommentText(commentRef.current.innerHTML);
              }}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default CommentSavePopup;
