import { Button, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useState } from "react";
import { aiGenerator } from "../../../../../redux/actions/autodesigner";
import { useDispatch } from "react-redux";
import { useReactFlow } from "reactflow";
import { nodeDataMappings } from "../../../SvgComponents/adShapes";
import { v4 as uuidv4 } from "uuid";

import styles from "./style.module.scss";

const AutoDesigner = () => {
  const [prompt, setPrompt] = useState("");
  const [promptLoading, setPromptLoading] = useState(false);
  const dispatch = useDispatch();
  const reactflowInstance = useReactFlow();

  const nodes = reactflowInstance.getNodes();
  const edges = reactflowInstance.getEdges();

  function convertApiResponseToRequiredJson(apiResponse) {
    let id;
    let labelToId = {};
    let labelToIndex = {};

    var nodes = apiResponse?.nodes?.map((node, index) => {
      const shapeData = nodeDataMappings[node.shape_name?.toLowerCase()];

      id = uuidv4();

      labelToId[node.label] = { id, shape_id: shapeData?.id };
      labelToIndex[node.label] = index;

      return {
        id,
        data: {
          id: shapeData?.id,
          color: "#ffffff",
          label: node?.label,
          description: node?.description,
          width: 200,
          height: 100,
          imageSrc: shapeData?.src,
          shape_id: id, // To be determined
          strokeColor: "#ffffff",
          strokeWidth: 1,
          categoryName: "Flow Chart",
        },
        type: "Shapes",
        width: 300,
        height: 156,
        position: {
          x: node.position.x,
          y: node.position.y,
        },
      };
    });

    var edges = apiResponse?.edges?.map((edge) => {
      id = uuidv4();

      //   console.log("label to id:", labelToId);

      var fromNode = nodes[labelToIndex[edge.from]],
        toNode = nodes[labelToIndex[edge.to]];

      var pos = toNode?.position;

      if (edge.from_direction === "bottom" && edge.to_direction === "top") {
        pos = {
          x: fromNode.position.x,
          y: fromNode.position.y + 300,
        };
      }
      if (edge.from_direction === "top" && edge.to_direction === "bottom") {
        pos = {
          x: fromNode.position.x,
          y: fromNode.position.y - 300,
        };
      }
      if (edge.from_direction === "right" && edge.to_direction === "left") {
        pos = {
          x: fromNode.position.x + 300,
          y: fromNode.position.y,
        };
      }
      if (edge.from_direction === "left" && edge.to_direction === "right") {
        pos = {
          x: fromNode.position.x - 300,
          y: fromNode.position.y,
        };
      }
      nodes[labelToIndex[edge.to]].position = pos;

      return {
        id,
        type: "smoothstep",
        style: {
          stroke: "#000",
        },
        source: labelToId[edge.from]?.id,
        target: labelToId[edge.to]?.id,
        markerEnd: {
          type: "arrowclosed",
          color: "#000",
        },
        sourceHandle:
          labelToId[edge.from]?.shape_id + "-" + edge.from_direction,
        targetHandle: labelToId[edge.to]?.shape_id + "-" + edge.to_direction,
      };
    });

    const requiredJson = {
      nodes,
      edges,
    };

    return requiredJson;
  }

  const extractJSON = (str) => {
    var firstOpen, firstClose, candidate;
    firstOpen = str.indexOf("{", firstOpen + 1);
    do {
      firstClose = str.lastIndexOf("}");
      // console.log("firstOpen: " + firstOpen, "firstClose: " + firstClose);
      if (firstClose <= firstOpen) {
        return null;
      }
      do {
        candidate = str.substring(firstOpen, firstClose + 1);
        // console.log("candidate: " + candidate);
        try {
          var res = JSON.parse(candidate);
          console.log("...found");
          return [res, firstOpen, firstClose + 1];
        } catch (e) {
          console.log("...failed");
        }
        firstClose = str.substr(0, firstClose).lastIndexOf("}");
      } while (firstClose > firstOpen);
      firstOpen = str.indexOf("{", firstOpen + 1);
    } while (firstOpen !== -1);
  };

  const handleGenerateClick = (action) => {
    if (prompt === "") {
      message.warning("Please enter some text.");
    } else {
      setPromptLoading(true);
      let newPrompt = prompt;

      let isExistingChart = action === "existing" ? true : false;

      if (isExistingChart) {
        let prevNodes = nodes.map((nds) => ({
          shape_name: nds?.data?.label,
          label: nds?.data?.label,
          description: nds?.data?.description,
          position: nds?.position,
        }));
        let prevEdges = edges.map((edgs) => {
          let from_direction = edgs.sourceHandle.split("-")[1],
            to_direction = edgs.targetHandle.split("-")[1];
          return {
            from: nodes.filter((nds) => nds.id === edgs.source)[0]?.label,
            to: nodes.filter((nds) => nds.id === edgs.target)[0]?.label,
            from_direction,
            to_direction,
          };
        });
        newPrompt = `${JSON.stringify({
          nodes: prevNodes,
          edges: prevEdges,
        })} ${prompt}`;
      }

      dispatch(aiGenerator({ prompt: newPrompt, isExistingChart })).then(
        (aiResponse) => {
          //   dispatch(setPromptPopupOpen(false));

          setPromptLoading(false);

          let response = aiResponse?.payload;

          if (typeof response === "string") {
            response = extractJSON(response)[0];
            response = JSON.parse(response);
          }

          if (response?.nodes?.length) {
            const { nodes: aiNodes, edges: aiEdges } =
              convertApiResponseToRequiredJson(response);

            let newNodes = aiNodes.filter((node) => {
                node.selected = true;
                return node;
              }),
              newEdges = aiEdges.filter((edg) => {
                edg.selected = true;
                return edg;
              });

            if (action === "existing") {
              reactflowInstance.setNodes(newNodes);
              reactflowInstance.setEdges(newEdges);
            } else {
              reactflowInstance.setNodes((nds) => nds.concat(newNodes));
              reactflowInstance.setEdges((edg) => edg.concat(newEdges));
            }
          }
        }
      );
    }
  };

  return (
    <div className={styles.adContainer}>
      <h3>Create New Flowchart</h3>
      <div style={{ flexGrow: 1 }}>
        <TextArea
          placeholder="Write your text to generate flowchart"
          value={prompt}
          onInput={(event) => setPrompt(event.target.value)}
          style={{ height: "100%" }}
        />
      </div>
      {/* <h3 className="text-center">Generate Flowchart at</h3> */}
      {!promptLoading ? (
        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          <Button
            type="primary"
            style={{
              backgroundColor: "#5a03d5",
            }}
            onClick={() => handleGenerateClick("existing")}
          >
            Existing
          </Button>
          <div className="text-center">OR</div>
          <Button
            type="primary"
            style={{
              backgroundColor: "#4096ff",
            }}
            onClick={() => handleGenerateClick("new")}
          >
            New
          </Button>
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>Generating, please wait...</div>
      )}
    </div>
  );
};

export default AutoDesigner;
