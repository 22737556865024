export const GET_ALL_SHAPES_SUCCESS = "GET_ALL_SHAPES_SUCCESS";
export const GET_ALL_SHAPES_FAILURE = "GET_ALL_SHAPES_FAILURE";

export const GET_CHART_DETAILS_SUCCESS = "GET_CHART_DETAILS_SUCCESS";
export const GET_CHART_DETAILS_FAILURE = "GET_CHART_DETAILS_FAILURE";

export const SET_ACTIVE_SHAPES_SUCCESS = "SET_ACTIVE_SHAPES_SUCCESS";
export const SET_ACTIVE_SHAPES_FAILURE = "SET_ACTIVE_SHAPES_FAILURE";

export const ADD_SHAPES_TO_CHART_SUCCESS = "ADD_SHAPES_TO_CHART_SUCCESS";
export const ADD_SHAPES_TO_CHART_FAILURE = "ADD_SHAPES_TO_CHART_FAILURE";

export const ADD_UPDATE_CHART_SUCCESS = "ADD_UPDATE_CHART_SUCCESS";
export const ADD_UPDATE_CHART_FAILURE = "ADD_UPDATE_CHART_FAILURE";

export const ADD_UPDATE_CHART_ACCESS_SUCCESS =
  "ADD_UPDATE_CHART_ACCESS_SUCCESS";
export const ADD_UPDATE_CHART_ACCESS_FAILURE =
  "ADD_UPDATE_CHART_ACCESS_SUCCESS";

export const SET_SAVING = "SET_SAVING";

export const TOGGLE_SHAPE_POPUP = "TOGGLE_SHAPE_POPUP";

export const SET_UNDO_ITEMS = "SET_UNDO_ITEMS";
export const SET_REDO_ITEMS = "SET_REDO_ITEMS";

export const UNDO_SUCCESS = "UNDO_SUCCESS";
export const REDO_SUCCESS = "REDO_SUCCESS";

export const UNDO_CLEAR = "UNDO_CLEAR";
export const REDO_CLEAR = "REDO_CLEAR";

export const PROMPT_POPUP_OPEN = "PROMPT_POPUP_OPEN";
export const RESET_CHART_UPDATED = "RESET_CHART_UPDATED";

export const SET_COPYING = "SET_COPYING";

export const SET_SHAPE_GROUP_SAVE = "SET_SHAPE_GROUP_SAVE";
