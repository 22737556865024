import {
  AI_GENERATOR_FAILURE,
  AI_GENERATOR_SUCCESS,
} from "../constants/autodesigner";

const initialState = {
  response: {},
  error: null,
};

const AutodesignerReducers = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case AI_GENERATOR_SUCCESS:
      return {
        ...state,
        response: payload,
        error: null,
      };

    case AI_GENERATOR_FAILURE:
      return {
        ...state,
        response: null,
        error: payload,
      };

    default:
      return state;
  }
};

export default AutodesignerReducers;
