import React from "react";
import {  useSelector } from "react-redux";
import HeaderProfile from "../../components/HeaderProfile/HeaderProfile";
import { Navigate } from "react-router-dom";

const PageNotFound = () => {
  const { isLoggedIn } = useSelector((state) => state.auth);

  if (isLoggedIn) {
    return <Navigate to="/" />;
}

  return (
    <div>
      <HeaderProfile />
      <h1>Page Not Found</h1>
    </div>
  );
};

export default PageNotFound;
