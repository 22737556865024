import ApiExecute from ".";

const aiGeneratorPrompt = async (data) => {
  return await ApiExecute("ai-generator", "POST", { data, timeout: 200 });
};

const autodesignerService = {
  aiGeneratorPrompt,
};

export default autodesignerService;
