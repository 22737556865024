import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import { useReactFlow } from "reactflow";
import { useDispatch, useSelector } from "react-redux";

import styles from "./styles.module.scss";
import { message } from "antd";
import { getCharts } from "../../../../redux/actions/dashboard";
import {
  SET_COPYING,
  SET_SHAPE_GROUP_SAVE,
} from "../../../../redux/constants/flowchart";
import { toJpeg } from "html-to-image";
import { addUpdateChart } from "../../../../redux/actions/flowchart";
import { useRef } from "react";

const sgScheme = Yup.object().shape({
  name: Yup.string().required("Please enter name."),
});

export default function ShapeGroupSavePopup({
  selectedNodes,
  selectedEdges,
  oldNodes,
  oldEdges,
}) {
  const reactFlowInstance = useReactFlow();
  const dispatch = useDispatch();

  const popupRef = useRef();

  const shape_group_save = useSelector(
    (state) => state?.flowchart?.shape_group_save
  );

  const saveAsShapeGroup = (chartName, setSubmitting) => {
    reactFlowInstance.setNodes(selectedNodes);
    reactFlowInstance.setEdges(selectedEdges);

    dispatch({
      type: SET_COPYING,
      payload: true,
    });

    dispatch({
      type: SET_SHAPE_GROUP_SAVE,
      payload: false,
    });

    let viewport;

    setTimeout(async () => {
      viewport = reactFlowInstance.getViewport();

      reactFlowInstance.fitView();

      let image = await toJpeg(document.querySelector(".react-flow"), {
        filter: (node) => {
          // we don't want to add the minimap and the controls to the image
          if (
            node?.classList?.contains("react-flow__minimap") ||
            node?.classList?.contains("react-flow__controls") ||
            node?.classList?.contains("react-flow__panel") ||
            node?.classList?.contains("react-flow__background") ||
            node?.classList?.contains("react-flow__nodesselection")
          ) {
            return false;
          }

          return true;
        },
        canvasWidth: 150,
        canvasHeight: 100,
        quality: 0.6,
        backgroundColor: "#ebebeb",
      });

      dispatch(
        addUpdateChart({
          chart_id: null,
          product: "FC",
          is_template: true,
          is_starred: false,
          pages_id: null,
          chart_name: chartName,
          chart: {
            nodes: selectedNodes,
            edges: selectedEdges,
          },
          page_name: "Page 1",
          image: image,
        })
      ).then(() => {
        message.success("New Shape Group has been created.");

        reactFlowInstance.setNodes(oldNodes);
        reactFlowInstance.setEdges(oldEdges);

        setTimeout(() => {
          // reactFlowInstance.fitView();
          reactFlowInstance.setViewport(viewport);

          dispatch({
            type: SET_COPYING,
            payload: false,
          });
        }, 100);

        setSubmitting(false);

        dispatch(getCharts());
      });
    }, 100);
  };

  const closeSvgPopup = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      dispatch({
        type: SET_SHAPE_GROUP_SAVE,
        payload: false,
      });
    }
  };

  if (!shape_group_save) return false;

  return (
    <div className={styles.sgSavePopupContainer} onClick={closeSvgPopup}>
      <div className={styles.sgPopupBody} ref={popupRef}>
        <Formik
          initialValues={{
            name: "",
          }}
          validationSchema={sgScheme}
          onSubmit={async (values, { setSubmitting }) => {
            saveAsShapeGroup(values.name, setSubmitting);
          }}
        >
          {({ handleBlur, handleChange, values, isSubmitting }) => (
            <Form>
              <div className={styles.sgForm}>
                <input
                  type="text"
                  name="name"
                  value={values?.name}
                  placeholder="Enter Shape Group Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage name="name" component="div" className="error" />
                {isSubmitting ? (
                  <div className="my-2 text-center">Saving, please wait...</div>
                ) : (
                  <button type="submit" class={`btn primary-btn ${styles.btn}`}>
                    Save
                  </button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
