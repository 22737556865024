import ApiExecute from ".";

const getAllShapes = async () => {
  return await ApiExecute("all-shapes");
};

const addUpdateChart = async (data) => {
  return await ApiExecute("add-update-chart", "POST", { data });
};

const getChartDetails = async (id, pageNum = 1) => {
  return await ApiExecute(`get-chart-details/${id}/${pageNum}`);
};

const updateChartImage = async (data) => {
  return await ApiExecute("update-chart-image", "POST", { data });
};

const uploadFile = async (file) => {
  let data = new FormData();
  data.append("recfile", file);
  return await ApiExecute("upload-images", "POST", { data, files: true });
};

const addUpdateChartAccess = async (data) => {
  return await ApiExecute("add-update-chart-access", "POST", { data });
};

const getChartAccess = async (chartId) => {
  return await ApiExecute(`get-access-details/${chartId}`);
};

const flowchartService = {
  getAllShapes,
  addUpdateChart,
  getChartDetails,
  uploadFile,
  addUpdateChartAccess,
  getChartAccess,
  updateChartImage,
};

export default flowchartService;
