import React, { useEffect, useRef, useState } from "react";
import {
  Handle,
  NodeResizer,
  Position,
  useReactFlow,
  useUpdateNodeInternals,
} from "reactflow";
import useFlowchart from "../../../../redux/utils/useFlowchart";
import { select } from "d3-selection";
import { drag } from "d3-drag";

const SvgImage = (props) => {
  const { id, data, selected } = props;
  const { addUpdate } = useFlowchart();

  const reactFlowInstance = useReactFlow();

  const [rotation, setRotation] = useState(data?.rotate ?? 0);
  const updateNodeInternals = useUpdateNodeInternals();

  const [hovered, setHovered] = useState(false);
  const rotateControlRef = useRef(null);

  const circleSize = 35;

  useEffect(() => {
    if (!rotateControlRef.current) {
      return;
    } else {
      const selection = select(rotateControlRef.current);
      const dragHandler = drag().on("drag", (evt) => {
        const dx = evt.x - 100;
        const dy = evt.y - 100;
        const rad = Math.atan2(dx, dy);
        const deg = rad * (180 / Math.PI);
        setRotation(180 - deg);
        updateNodeInternals(id);
      });

      selection.call(dragHandler);
    }
  }, [id, updateNodeInternals]);

  const updateRotation = (id, rotate) => {
    reactFlowInstance.setNodes((nds) =>
      nds.map((node) => {
        if (node.id === id) {
          node.data = {
            ...node.data,
            rotate,
          };
        }
        return node;
      })
    );

    setTimeout(() => {
      addUpdate();
    }, 1000);
  };

  useEffect(() => {
    if (rotation) updateRotation(id, rotation);
  }, [rotation]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        transform: `rotate(${rotation}deg)`,
      }}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <NodeResizer
        nodeId={data?.id}
        isVisible={selected}
        handleStyle={{
          width: 20,
          height: 20,
          background: "#fff",
          borderColor: "blue",
          borderWidth: 2,
          borderRadius: 5,
        }}
        onResize={(e, res) => console.log("resize: ", res)}
        onResizeEnd={() => addUpdate()}
      />
      <div
        ref={rotateControlRef}
        className={`nodrag rotateHandle ${!selected ? "hidden" : ""}`}
      />
      <>
        <div style={{ opacity: hovered ? 1 : 0 }}>
          <Handle
            id={`${data.id}-top`}
            position={Position.Top}
            type="source"
            style={{
              width: circleSize,
              height: circleSize,
              backgroundColor: "#fff",
              borderColor: "#000",
              marginTop: `-${(circleSize - 20) / 2}px`,
            }}
          />
          <Handle
            id={`${data.id}-bottom`}
            position={Position.Bottom}
            type="source"
            style={{
              width: circleSize,
              height: circleSize,
              backgroundColor: "#fff",
              borderColor: "#000",
              marginBottom: `-${(circleSize - 20) / 2}px`,
            }}
          />
          <Handle
            id={`${data.id}-left`}
            position={Position.Left}
            type="source"
            style={{
              width: circleSize,
              height: circleSize,
              backgroundColor: "#fff",
              borderColor: "#000",

              marginLeft: `-${(circleSize - 20) / 2}px`,
            }}
          />
          <Handle
            id={`${data.id}-right`}
            position={Position.Right}
            type="source"
            style={{
              width: circleSize,
              height: circleSize,
              backgroundColor: "#fff",
              borderColor: "#000",
              marginRight: `-${(circleSize - 20) / 2}px`,
            }}
          />
        </div>
      </>
      <img
        src={data?.imageSrc}
        style={{
          width: "100%",
          height: "100%",
        }}
      />
    </div>
  );
};

export default SvgImage;
