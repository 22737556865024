import { message } from "antd";
import {
  GET_CHART_FAILURE,
  GET_CHART_SUCCESS,
  GET_TEMPLATE_FAILURE,
  GET_TEMPLATE_SUCCESS,
  STAR_UNCHART_FAILURE,
  STAR_UNCHART_SUCCESS,
} from "../constants/dashboard";
import dashboardService from "../services/dashboard";
import { logout } from "./authentication";

export const getCharts = () => async (dispatch) => {
  try {
    let data = await dashboardService.getCharts();
    return dispatch({
      type: GET_CHART_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    // console.log("error dashboard: ", error.response.status);

    if (error?.response?.status === 401) {
      return dispatch(logout());
    }

    return dispatch({
      type: GET_CHART_FAILURE,
    });
  }
};

export const getTemplates = () => async (dispatch) => {
  try {
    let payload = await dashboardService.getTemplates();
    return dispatch({
      type: GET_TEMPLATE_SUCCESS,
      payload,
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return dispatch(logout());
    }

    dispatch({
      type: GET_TEMPLATE_FAILURE,
    });
    return Promise.reject();
  }
};

export const starUnstarChart = (payload) => async (dispatch) => {
  try {
    let data = await dashboardService.starUnstarChart(payload);
    message.success(
      !payload?.is_starred
        ? "Unstarred Chart successfully!!"
        : "Starred Chart successfully!!"
    );
    return dispatch({
      type: STAR_UNCHART_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return dispatch(logout());
    }
    return dispatch({
      type: STAR_UNCHART_FAILURE,
      payload: error,
    });
  }
};
