import ApiExecute from ".";

const addUpdateWorkflows = async (data) => {
  return await ApiExecute("add-update-workflow", "POST", { data });
};

const getWorkflowDetails = async (pageId) => {
  return await ApiExecute(`get-workflows/${pageId}`);
};

const workflowServices = {
  addUpdateWorkflows,
  getWorkflowDetails,
};

export default workflowServices;
