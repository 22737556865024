import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import CanvasLayout from "../../components/Canvas/CanvasLayout";
// import { v4 as uuidv4 } from "uuid";
// import { GET_CHART_DETAILS_SUCCESS } from "../../redux/constants/flowchart";
// import { addUpdateChart, getChartDetails } from "../../redux/actions/flowchart";
import style from "./style.module.scss";
// import { useParams } from "react-router-dom";
import { fabric } from "fabric";
import WhiteboardLeftSidebar from "./WhiteboardLeftSidebar";

function Whiteboard() {
  const [mode, setMode] = useState("pencil");
  const [penColor, setPenColor] = useState("#000");
  const [size, setSize] = useState(1);
  const [cursor, setCursor] = useState(null);
  const [whiteboardCanvas, setWhiteboardCanvas] = useState(null);

  const { innerWidth: width, innerHeight: height } = window;

  const getMousePos = (canvas, e) => {
    var pos = canvas.getPointer(e);
    return pos;
  };

  // const mouseEventHandler = () => {

  // };

  useEffect(() => {
    if (width && height) {
      var whiteboardCanvas = new fabric.Canvas("whiteboardCanvas", {
        selection: false,
        isDrawingMode: true,
        width,
        height,
      });

      setWhiteboardCanvas(whiteboardCanvas);
    }
  }, [width, height]);

  useEffect(() => {
    fabric.Object.prototype.selectable = false;
    fabric.Object.prototype.erasable = true;

    // Mouse Event Handlers
    if (whiteboardCanvas) {
      var isDown = false;

      let initial = {
        x: null,
        y: null,
      };
      var rect, ellipse;
      var WIDTH;
      var HEIGHT;

      whiteboardCanvas.on("mouse:down", (o) => {
        isDown = true;
        let { x, y } = getMousePos(whiteboardCanvas, o.e);

        initial = { x, y };

        console.log("mode: ", mode);

        if (mode === "pencil" || mode === "eraser") {
          whiteboardCanvas.isDrawingMode = 1;
          whiteboardCanvas.freeDrawingBrush.color =
            mode === "eraser" ? "#fff" : penColor;
          whiteboardCanvas.freeDrawingBrush.width = mode === "eraser" ? 20 : 10;
        } else {
          whiteboardCanvas.isDrawingMode = 0;
        }

        if (mode === "rectangle") {
          rect = new fabric.Rect({
            left: x,
            top: y,
            originX: "left",
            originY: "top",
            width: 0,
            height: 0,
            angle: 0,
            fill: "rgba(255,255,255,0)",
            stroke: penColor,
            strokeWidth: 10,
            transparentCorners: false,
            hasRotatingPoint: false,
          });

          whiteboardCanvas.add(rect);
        }

        if (mode === "circle") {
          ellipse = new fabric.Ellipse({
            left: x,
            top: y,
            originX: "left",
            originY: "top",
            rx: 0,
            ry: 0,
            angle: 0,
            fill: "rgba(255,255,255,0)",
            stroke: penColor,
            strokeWidth: 10,
            transparentCorners: false,
            hasRotatingPoint: false,
          });

          whiteboardCanvas.add(ellipse);
        }
      });

      whiteboardCanvas.on("mouse:move", (o) => {
        let { x, y } = getMousePos(whiteboardCanvas, o.e);
        // console.log('cursor: ', cursor);

        setTimeout(function () {
          setCursor({ left: x, top: y });
        }, 10);
        if (!isDown) return;

        WIDTH = Math.abs(initial.x - x);
        HEIGHT = Math.abs(initial.y - y);

        if (mode === "rectangle") {
          if (initial.x > x) {
            rect.set({ left: Math.abs(x) });
          }

          if (initial.y > y) {
            rect.set({ top: Math.abs(y) });
          }

          rect.set({
            width: WIDTH,
            height: HEIGHT,
          });
        }

        if (mode === "circle") {
          ellipse.set({
            rx: WIDTH / 2,
            ry: HEIGHT / 2,
          });
        }

        whiteboardCanvas.renderAll();
      });

      whiteboardCanvas.on("mouse:up", () => {
        isDown = false;
        initial = null;

        // this.updateChartData();
      });
    }
  }, [whiteboardCanvas, mode, size, penColor]);

  return (
    <>
      <div className="canvasArea">
        <WhiteboardLeftSidebar
          penColor={penColor}
          setPenColor={setPenColor}
          size={size}
          setSize={setSize}
          mode={mode}
          setMode={setMode}
        />
        <canvas id="whiteboardCanvas">
          Sorry, your browser does not support HTML5 canvas technology.
        </canvas>

        {mode === "pencil" || mode === "eraser" ? (
          <div
            className={style.customCursor}
            style={{
              position: "absolute",
              width: size,
              height: size,
              left: cursor?.left,
              top: cursor?.top,
              marginLeft: -size / 2,
              marginTop: -size / 2,
              borderRadius: mode === "eraser" ? 0 : "50%",
              backgroundColor: mode === "eraser" ? "#ebebeb" : penColor,
            }}
          ></div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default Whiteboard;
