import { PROMPT_POPUP_OPEN } from "../constants/flowchart";
import {
  ADD_UPDATE_WORKFLOWS_FAILURE,
  ADD_UPDATE_WORKFLOWS_SUCCESS,
  GET_WORKFLOWS_DETAILS_FAILURE,
  GET_WORKFLOWS_DETAILS_SUCCESS,
  REMOVE_LAST_ACTIVE_NODE,
  SET_ACTIVE_FLOW_SUCCESS,
  SET_ACTIVE_NODES,
  SET_CURRENT_WORKFLOW,
  SET_LANG_CONVERT,
  SET_SPEECH,
  SET_SPEECH_OPTIONS,
} from "../constants/workflow";
import workflowServices from "../services/workflow";
import { logout } from "./authentication";

export const workflowStatusUpdate = (payload) => (dispatch) => {
  // console.log("status payload: ", payload);
  return dispatch({
    type: SET_ACTIVE_FLOW_SUCCESS,
    payload,
  });
};

export const addUpdateWorkflows = (data) => async (dispatch) => {
  try {
    let payload = await workflowServices.addUpdateWorkflows(data);
    return dispatch({
      type: ADD_UPDATE_WORKFLOWS_SUCCESS,
      payload,
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return dispatch(logout());
    }
    return dispatch({
      type: ADD_UPDATE_WORKFLOWS_FAILURE,
      payload: error,
    });
  }
};

export const setPromptPopupOpen = (payload) => (dispatch) => {
  return dispatch({
    type: PROMPT_POPUP_OPEN,
    payload,
  });
};

export const getWorkflowDetails = (pageId) => async (dispatch) => {
  try {
    let payload = await workflowServices.getWorkflowDetails(pageId);
    return dispatch({
      type: GET_WORKFLOWS_DETAILS_SUCCESS,
      payload,
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return dispatch(logout());
    }
    dispatch({
      type: GET_WORKFLOWS_DETAILS_FAILURE,
    });
    return Promise.reject();
  }
};

export const setSpeech = (payload) => (dispatch) => {
  return dispatch({
    type: SET_SPEECH,
    payload,
  });
};

export const setCurrentWorkflow = (payload) => (dispatch) => {
  return dispatch({
    type: SET_CURRENT_WORKFLOW,
    payload,
  });
};

export const setSpeechOptions = (payload) => (dispatch) => {
  return dispatch({
    type: SET_SPEECH_OPTIONS,
    payload,
  });
};

export const setLangConvert = (payload) => (dispatch) => {
  return dispatch({
    type: SET_LANG_CONVERT,
    payload,
  });
};

export const setActiveNodes = (payload) => (dispatch) => {
  return dispatch({
    type: SET_ACTIVE_NODES,
    payload,
  });
};

export const removeLastActiveNode = () => (dispatch) => {
  return dispatch({
    type: REMOVE_LAST_ACTIVE_NODE,
    payload: 0,
  });
};
