import {
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_FAILURE,
  REGISTER_SUCCESS,
  SOCIAL_LOGIN_FAILURE,
  SOCIAL_LOGIN_SUCCESS,
} from "../constants/authentication";
import AuthenticationService from "../services/authentication";

export const register = (name, email, password) => async (dispatch) => {
  try {
    let user = await AuthenticationService.register(name, email, password)

    console.log('user: ', user);

    if (user?.data) {
      localStorage.setItem("user", JSON.stringify(user?.data));
      localStorage.setItem("token", user?.accessToken);
    }

    dispatch({
      type: REGISTER_SUCCESS,
      payload: { user },
    });

    return Promise.resolve();

  } catch (error) {
    dispatch({
      type: REGISTER_FAILURE,
    });
    return Promise.reject();
  }
};

export const login = (login, password) => async (dispatch) => {
  try {
    let user = await AuthenticationService.login(login, password);

    if (user?.data) {
      localStorage.setItem("user", JSON.stringify(user?.data));
      localStorage.setItem("token", user?.accessToken);
    }

    dispatch({
      type: LOGIN_SUCCESS,
      payload: { user },
    });

    return Promise.resolve();
  } catch (error) {
    dispatch({
      type: LOGIN_FAILURE,
    });
    return Promise.reject(error);
  }
};

export const socialLogin =
  (id, email, name, image, type) => async (dispatch) => {
    try {
      let user = await AuthenticationService.socialLogin(
        id,
        email,
        name,
        image,
        type
      );

      if (user?.data) {
        localStorage.setItem("user", JSON.stringify(user?.data));
        localStorage.setItem("token", user?.accessToken);
      }

      dispatch({
        type: SOCIAL_LOGIN_SUCCESS,
        payload: {
          user,
        },
      });

      return Promise.resolve();
    } catch (error) {
      dispatch({
        type: SOCIAL_LOGIN_FAILURE,
      });
      return Promise.reject(error);
    }
  };

export const forgotPassword = (email) => (dispatch) => {
  return AuthenticationService.forgotPassword(email).then(
    () => {
      dispatch({
        type: FORGOT_PASSWORD_SUCCESS,
      });

      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: FORGOT_PASSWORD_FAILURE,
      });
      return Promise.reject();
    }
  );
};

export const logout = () => (dispatch) => {
  AuthenticationService.logout();

  dispatch({
    type: LOGOUT,
  });
};
