


// workflowsim1@gmail.com
export const googleClientId =
  "572072742865-dbp8ghccbkuhh21v8vsam0tg02o31hfl.apps.googleusercontent.com";
export const googleClientSecret = "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";


// git workflowsim1@gmail.com
export const githubClientId = "ab0ad6570bdefbcf7000";
export const githubClientSecret = "0bf5e8ecd402d69b34b3ca8095ca5c6d6d85a5be";
