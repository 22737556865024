export const SET_ACTIVE_FLOW_SUCCESS = "SET_ACTIVE_FLOW_SUCCESS";
export const SET_ACTIVE_NODES_SUCCESS = "SET_ACTIVE_NODES_SUCCESS";

export const GET_WORKFLOWS_DETAILS_SUCCESS = "GET_WORKFLOWS_DETAILS_SUCCESS";
export const GET_WORKFLOWS_DETAILS_FAILURE = "GET_WORKFLOWS_DETAILS_FAILURE";

export const ADD_UPDATE_WORKFLOWS_SUCCESS = "ADD_UPDATE_WORKFLOWS_SUCCESS";
export const ADD_UPDATE_WORKFLOWS_FAILURE = "ADD_UPDATE_WORKFLOWS_FAILURE";

export const GET_CHART_ACCESS_SUCCESS = "GET_CHART_ACCESS_SUCCESS";
export const GET_CHART_ACCESS_FAILURE = "GET_CHART_ACCESS_FAILURE";

export const SET_SPEECH = "SET_SPEECH";
export const SET_CURRENT_WORKFLOW = "SET_CURRENT_WORKFLOW";

export const SET_SPEECH_OPTIONS = "SET_SPEECH_OPTIONS";
export const SET_LANG_CONVERT = "SET_LANG_CONVERT";
export const SET_ACTIVE_NODES = "SET_ACTIVE_NODES";
export const REMOVE_LAST_ACTIVE_NODE = "REMOVE_LAST_ACTIVE_NODE";
