import ApiExecute from ".";

const getNotifications = async () => {
  return await ApiExecute("notification");
};

const notificationServices = {
  getNotifications,
};

export default notificationServices;
