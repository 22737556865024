import {
  ADD_UPDATE_COMMENT_FAILURE,
  ADD_UPDATE_COMMENT_SUCCESS,
  GET_COMMENT_DETAILS_FAILURE,
  GET_COMMENT_DETAILS_SUCCESS,
  SET_ACTIVE_COMMENT_SUCCESS,
  SET_OPEN_COMMENT,
} from "../constants/comment";

const initialState = {
  activeComment: false,
  comments: [],
  updatedComments: {},
  openComment: null,
};

const CommentReducers = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_UPDATE_COMMENT_SUCCESS:
      return {
        ...state,
        updatedComments: payload,
      };

    case ADD_UPDATE_COMMENT_FAILURE:
      return {
        ...state,
        updatedComments: payload,
      };

    case SET_ACTIVE_COMMENT_SUCCESS:
      return {
        ...state,
        activeComment: payload,
      };

    case GET_COMMENT_DETAILS_SUCCESS:
      return {
        ...state,
        comments: payload,
      };

    case GET_COMMENT_DETAILS_FAILURE:
      return {
        ...state,
        updatedComments: payload,
      };

    case SET_OPEN_COMMENT:
      return {
        ...state,
        openComment: payload,
      };

    default:
      return state;
  }
};

export default CommentReducers;
