import { message } from "antd";
import { API_URL } from "../../shared/functions";
import axios from "axios";

const ApiExecute = async (url, method = "GET", params = {}) => {
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  if (params?.files) {
    headers["Content-Type"] = "multipart/form-data";
  }

  if (!params.is_guest) {
    let token = window.localStorage.getItem("token");

    if (token) {
      headers["x-access-token"] = token;
    }
  }

  let instance = axios.create({
    baseURL: API_URL,
    timeout: params?.timeout ? params.timeout * 1000 : 25 * 1000,
    headers,
  });

  let data = params.data ?? null;

  return instance
    .request({
      url,
      method,
      data,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      let error = err?.response?.data ?? err?.message;

      if (err?.code === "ERR_BAD_REQUEST") {
        error = err?.message;
      }

      message.config({
        top: 100,
      });

      message.error(error);

      return Promise.reject(err);
    });
};

export default ApiExecute;
