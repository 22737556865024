import React, { useEffect, useState } from "react";
import { Card, Input, Button, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { aiGenerator } from "../../redux/actions/autodesigner";
import { v4 as uuidv4 } from "uuid";
import { addUpdateChart } from "../../redux/actions/flowchart";
import { useNavigate } from "react-router-dom";
import styles from "./style.module.scss";
import "./style.scss";

const Autodesigner = () => {
  const [inputText, setInputText] = useState("");
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState("FOR_YOU");

  const dispatch = useDispatch();
  const navigateTo = useNavigate();

  const { error } = useSelector((state) => state?.autodesigner);

  useEffect(() => {
    if (!error) return;

    setLoading(false);
  }, [error]);

  function convertApiResponseToRequiredJson(apiResponse) {
    // console.log("convert started");
    const nodeDataMappings = {
      ellipse: {
        id: 1052,
        label: "Terminal",
        src: "https://ik.imagekit.io/klemoeilu/Terminal_uk9xINcgWZ.svg",
      },
      circle: {
        id: 1052,
        label: "Terminal",
        src: "https://ik.imagekit.io/klemoeilu/Terminal_uk9xINcgWZ.svg",
      },
      rectangle: {
        id: 1050,
        label: "Process",
        src: "https://ik.imagekit.io/klemoeilu/Process_eCSE_-xHn.svg",
      },
      rhombus: {
        id: 1040,
        label: "Decision",
        src: "https://ik.imagekit.io/klemoeilu/Decision_xAqPM7nNH.svg",
      },
      diamond: {
        id: 1040,
        label: "Decision",
        src: "https://ik.imagekit.io/klemoeilu/Decision_xAqPM7nNH.svg",
      },
    };

    let id;
    let labelToId = {};
    let labelToIndex = {};

    var nodes = apiResponse?.nodes?.map((node, index) => {
      const shapeData = nodeDataMappings[node.shape_name.toLowerCase()];

      id = uuidv4();

      labelToId[node.label] = { id, shape_id: shapeData?.id };
      labelToIndex[node.label] = index;

      return {
        id,
        data: {
          id: shapeData?.id,
          color: "#ffffff",
          label: node?.label,
          description: node?.description,
          width: 200,
          height: 100,
          imageSrc: shapeData?.src,
          shape_id: id, // To be determined
          strokeColor: "#ffffff",
          strokeWidth: 1,
          categoryName: "Flow Chart",
        },
        type: "Shapes",
        width: 300,
        height: 156,
        position: {
          x: node.position.x,
          y: node.position.y,
        },
      };
    });

    var edges = apiResponse?.edges?.map((edge) => {
      id = uuidv4();

      console.log("label to id:", labelToId);

      var fromNode = nodes[labelToIndex[edge.from]],
        toNode = nodes[labelToIndex[edge.to]];

      var pos = toNode.position;

      if (edge.from_direction === "bottom" && edge.to_direction === "top") {
        pos = {
          x: fromNode.position.x,
          y: fromNode.position.y + 300,
        };
      }
      if (edge.from_direction === "top" && edge.to_direction === "bottom") {
        pos = {
          x: fromNode.position.x,
          y: fromNode.position.y - 300,
        };
      }
      if (edge.from_direction === "right" && edge.to_direction === "left") {
        pos = {
          x: fromNode.position.x + 300,
          y: fromNode.position.y,
        };
      }
      if (edge.from_direction === "left" && edge.to_direction === "right") {
        pos = {
          x: fromNode.position.x - 300,
          y: fromNode.position.y,
        };
      }
      nodes[labelToIndex[edge.to]].position = pos;

      return {
        id,
        type: "smoothstep",
        style: {
          stroke: "#000",
        },
        source: labelToId[edge.from]?.id,
        target: labelToId[edge.to]?.id,
        markerEnd: {
          type: "arrowclosed",
          color: "#000",
        },
        sourceHandle:
          labelToId[edge.from]?.shape_id + "-" + edge.from_direction,
        targetHandle: labelToId[edge.to]?.shape_id + "-" + edge.to_direction,
      };
    });

    const requiredJson = {
      nodes,
      edges,
    };

    return requiredJson;
  }

  const extractJSON = (str) => {
    var firstOpen, firstClose, candidate;
    firstOpen = str.indexOf("{", firstOpen + 1);
    do {
      firstClose = str.lastIndexOf("}");
      console.log("firstOpen: " + firstOpen, "firstClose: " + firstClose);
      if (firstClose <= firstOpen) {
        return null;
      }
      do {
        candidate = str.substring(firstOpen, firstClose + 1);
        // console.log("candidate: " + candidate);
        try {
          var res = JSON.parse(candidate);
          console.log("...found");
          return [res, firstOpen, firstClose + 1];
        } catch (e) {
          console.log("...failed");
        }
        firstClose = str.substr(0, firstClose).lastIndexOf("}");
      } while (firstClose > firstOpen);
      firstOpen = str.indexOf("{", firstOpen + 1);
    } while (firstOpen !== -1);
  };

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleGenerateClick = () => {
    if (inputText === "") {
      message.warning("Please enter some text.");
    } else {
      setLoading(true);
      dispatch(aiGenerator({ prompt: inputText })).then((aiResponse) => {
        let response = aiResponse?.payload;

        // console.log("type of response", typeof response, response);
        if (typeof response === "string") {
          response = extractJSON(response)[0];
          response = JSON.parse(response);
        }
        // console.log("response: ", response, response?.nodes?.length);

        if (response?.nodes?.length) {
          const { nodes, edges } = convertApiResponseToRequiredJson(response);

          // console.log("required json", nodes, edges);
          dispatch(
            addUpdateChart({
              chart_id: null,
              product: "FC",
              is_template: false,
              is_starred: false,
              pages_id: null,
              chart: {
                nodes,
                edges,
              },
              page_name: `Page 1`,
            })
          ).then((chartUpdated) => {
            // console.log("chart response:", chartUpdated);
            navigateTo("/canvas/" + chartUpdated?.payload?.data?.chart_id);
          });
        }
      });
    }
  };

  return (
    <div className={styles.formContainer}>
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            color: "#fff",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div class="adLoader"></div>
          Crafting Flow Diagram...
        </div>
      ) : (
        <Card title="Autodesigner" style={{ width: 500 }}>
          <Input.TextArea
            rows={5}
            placeholder="Enter text"
            value={inputText}
            onChange={handleInputChange}
          />
          <div style={{ display: "grid" }}>
            <Button
              type="primary"
              style={{ marginTop: 10, backgroundColor: "#5a03d5" }}
              onClick={handleGenerateClick}
              disabled={loading}
            >
              {!loading
                ? error
                  ? "Error Occurs! Generate Again."
                  : "Generate with AutoDesigner"
                : "Generating, please wait..."}
            </Button>
          </div>
        </Card>
      )}
    </div>
  );
};

export default Autodesigner;
